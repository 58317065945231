import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import { ColorSystemProps } from "../../../constants";
import { B_2TypeCTA } from "./CTA";
import { BTypePriceSummary } from "./PriceSummary";
import { CTypeFacility } from "./Facility";
import { colors } from "../../../styles";

export function FTypeCompareParlor({
  firstParlor,
  secondParlor,
  mainColor,
  event,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <>
      <Stack
        padding="20px"
        position="relative"
        justifyContent="center"
        gap="10px"
      >
        <Stack
          gap="6px"
          padding="40px 20px 20px"
          style={{
            background: "white",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Stack padding="0 20px">
            <Box>
              <h2 className="subtitle_21_b">
                {firstParlor.companyname}
                <span className="body_14_m">과</span>
              </h2>
            </Box>
            <Box>
              <h2 className="subtitle_21_b">
                {secondParlor.companyname}
                <span className="body_14_m">비용 및 장례식장 유형</span>
              </h2>
            </Box>
            <Box className="body_14_m">
              을 비교하고 관련 정보를 알려드립니다.
            </Box>
          </Stack>
          <Box
            width="100%"
            height="1px"
            style={{ background: colors.gray300 }}
            margin="10px 0"
          />
          <Box className="caption_12_m gray500" padding="0 20px">
            이 글에선 장례식장 2개의 정보를 5분만에 간결하게 비교해드립니다.
          </Box>
        </Stack>

        <Stack
          padding="20px 12px 0"
          className="body_16_m gray900"
          style={{
            background: "white",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          <BTypePriceSummary
            firstParlor={firstParlor}
            secondParlor={secondParlor}
            mainColor={mainColor}
          />
        </Stack>
        <Stack padding="20px 0 0" className="body_16_m gray900">
          <B_2TypeCTA mainColor={mainColor} event={event} />
        </Stack>

        <Stack
          padding="20px"
          className="body_16_m gray900"
          style={{ background: "white" }}
        >
          <CTypeFacility
            firstParlor={firstParlor}
            secondParlor={secondParlor}
            mainColor={mainColor}
          />
        </Stack>
        <Stack padding="20px 0 0" className="body_16_m gray900">
          <B_2TypeCTA mainColor={mainColor} />
        </Stack>
      </Stack>
    </>
  );
}
