import React from "react";
import { colors } from "../../../styles";
import { Box, Stack } from "@mui/material";

export default function ATypeRegionPriceSummaryItem({
  text,
  price,
}: {
  text: string;
  price: number;
}) {
  return (
    <Stack
      className="subtitle_14_b"
      flexDirection="row"
      color="black"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box flexShrink={0} padding="0 10px 0 0">
        {text}
      </Box>
      <Box height="0.8px" width="100%" overflow="hidden">
        <Box
          sx={{
            width: "100%",
            borderTopWidth: "3px",
            borderStyle: "dashed",
            borderColor: colors.gray600,
          }}
        />
      </Box>
      <Stack alignItems="center" flexShrink={0} padding="0 0 0 10px">
        <span>{price}원</span>
      </Stack>
    </Stack>
  );
}
