import { Box, Stack } from "@mui/material";
import React from "react";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { colors } from "../../../styles";
import { ColorSystemProps } from "../../../constants";
import { ATypeBeneficiaryCTA } from "./ATypeBeneficiaryCTA";
import { ClickEventType } from "../../../types";

export function ATypeBeneficiaryHead({
  titleSido,
  mainColor,
  event,
}: {
  titleSido: string;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <Stack padding="0 20px 40px">
      <Stack gap="20px" marginBottom="40px">
        <h1 className="subtitle_21_sb">
          <span className="subtitle_18_b" style={{ color: mainColor.c300 }}>
            {titleSido}
          </span>
          <br />
          기초생활수급자 장례헬프센터
        </h1>

        <FullWidthButton
          text="바로 상담하기"
          href="tel:1666-9784"
          backgroundColor={mainColor.c400}
          event={event}
        />
      </Stack>

      <ATypeBeneficiaryCTA mainColor={mainColor} event={event} />
    </Stack>
  );
}
