import React from "react";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthTwoLineButton } from "../../common/Buttons/FullWidthTwoLineButton";
import { ColorIcon } from "../../common/Icons";
import { ClickEventType } from "../../../types";

export function ATypeCTA({
  mainColor,
  sidoName,
  sigunguName,
  event,
}: {
  mainColor: ColorSystemProps;
  sidoName?: string;
  sigunguName?: string;
  event?: ClickEventType;
}) {
  return (
    <Stack gap="12px" padding="0 0 20px">
      <Box className="subtitle_16_b">
        선불제 상조보다 더 합리적으로 장례를 치를 수 있어요.
      </Box>

      <FullWidthTwoLineButton
        text="후불제로 비용 줄이는 법 알아보기"
        description="선불제 상조에선 1000만원 이상"
        href={GOI_WEB_URL + "/match/request/"}
        backgroundColor={mainColor.c400}
        event={event}
      />

      {sidoName && (
        <Stack flexDirection="row" gap="6px" alignItems="center">
          <ColorIcon name="icon-check-fill-mono" size={14} color="" />
          <Box className="caption_12_m">{`${sidoName}${
            sigunguName ? " " + sigunguName : ""
          } 지역 포함해서 비교`}</Box>
        </Stack>
      )}
    </Stack>
  );
}
