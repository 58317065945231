import { ServiceDetails } from "./details";

export const 무빈소장: ServiceDetails = {
  type: "무빈소장",
  description: `조문객을 초대하지 않는 장례에 맞는 상품입니다.`,
  recommendationText: `<b>고이 무빈소장은,</b> 
  빈소와 조문 없이 가족만 모여, 
  간소한 절차와 합리적 비용의 
  장례를 희망하는 분께 추천드립니다.`,
  정상가: 160,
  가격: 150,
  조문객: "없음",
  빈소: "없음",
  접객도우미: "없음",
  "장례 용품": "기본형",
  "장의 차량": 1,
  details: [
    {
      key: "인력",
      contents: [
        {
          src: "platform/service/person1.png",
          title: "장례지도사",
          descriptions: "1명 (3일)",
          price: 300_000,
        },
        {
          src: "platform/service/person2.png",
          title: "입관상례사",
          descriptions: "1명 (1일)",
          price: 100_000,
        },
      ],
    },
    {
      key: "용품",
      contents: [
        {
          src: "platform/service/3.png",
          title: "관",
          descriptions: `오동나무 1단관
          (화장용, 두께 1.0 이하)`,
          price: 250_000,
        },
        {
          src: "platform/service/sui2.png",
          title: "수의",
          descriptions: `화장용 수의
          (면 50%, 폴리 50%)
          `,
          price: 200_000,
          discountPrice: 100_000,
        },
        {
          src: "platform/service/ugol0.png",
          title: "유골함",
          descriptions: "오동나무 목함",
          price: 0,
        },
        {
          src: "platform/service/6.png",
          title: "관 장식용 생화",
          descriptions: "계절별 생화 및 국화, 안개",
          price: 100_000,
        },
        {
          src: "platform/service/5.png",
          title: "입관 수시 용품",
          descriptions: "염포, 관보, 명정, 예단 등 10종",
          price: 150_000,
        },
      ],
    },
    {
      key: "차량",
      contents: [
        {
          src: "platform/service/ambulance.png",
          title: "1일차 이송 차량",
          descriptions: `앰뷸런스
          (관내 기준 요금, 관외 및 거리
          추가 시 km당 2,000원 추가)`,
          price: 100_000,
        },
        {
          src: "platform/service/bus.png",
          title: "3일차 장의 차량",
          descriptions: `45인승 버스
          (200km 초과시
          km 당 2,000원 추가)`,
          price: 400_000,
        },
      ],
    },
  ],
  steps: [
    [
      {
        src: "images/service/first_1.png",
        title: "고인 이송",
        description: "임종 후, 병원이나 장례식장으로 이송합니다.",
      },
      {
        src: "images/service/first_2.png",
        title: "고인 안치",
        description: "고인의 옷과 몸을 바르게 하는 수시 후, 고인을 장례식장에 안치합니다.",
      },
      {
        src: "images/service/first_4.png",
        title: "화장장 예약",
        description: "2일차에 화장이 가능하도록 장례지도사님께서 미리 화장장 예약을 진행합니다.",
      },
      {
        src: "images/service/first_5.png",
        title: "부고 문자 발송",
        description: "꼭 필요한 정보가 누락되지 않도록 부고문자를 작성해드립니다.",
      },
    ],
    [
      {
        src: "images/service/second_1.png",
        title: "염습",
        description: "고인의 몸을 깨끗이 한 후, 수의를 입힙니다. 입관전에 거치는 절차입니다.",
      },
      {
        src: "images/service/second_2.png",
        title: "입관식",
        description:
          "염습 후 고인의 마지막 모습을 보면서 애도할 수 있는 입관식을 진행합니다.\n" +
          "(고인의 종교에 따라 종교지도사 참여 가능합니다.)",
      },
      {
        src: "images/service/second_3.png",
        title: "성복",
        description:
          "정식으로 상복을 입는 절차입니다. 요즘에는 1일차에 바로 상복을 입지만, 입관 후 정식으로 완장/머리핀을 착용하게 됩니다.",
      },
    ],
    [
      {
        src: "images/service/third_1.png",
        title: "발인",
        description: "관이 장례식장을 떠나는 절차이며, 관을 버스나 리무진까지 옮깁니다.",
      },
      {
        src: "images/service/third_2.png",
        title: "운구",
        description: "고인을 장지까지 옮기는 절차이며, 버스나 리무진으로 이송합니다.",
      },
      {
        src: "images/service/third_3.png",
        title: "화장 / 유골 안치",
        description: "예약한 시간에 화장 후 안치시설로 옮깁니다.",
      },
      {
        src: "images/service/third_4.png",
        title: "사후 행정",
        description: "사망신고, 유족연금, 상속신고 등 법적, 행정적 안내를 도와드립니다.",
      },
    ],
  ],
  deductions: [
    "패키지에 포함되어 있는 품목 미사용시 금액을 100% 공제해 드립니다.",
    "용품/차량이 아닌 인력 서비스(장례지도사, 염습상례사, 접객 도우미)는 장례식 절차에 빠질 수 없는 요소이기 때문에 공제가 불가능합니다.",
  ],
  extra: [
    "4일장, 5일장 등 3일장에서 일정이 길어질 경우 장례지도사 서비스 비용이 1일당 15만원 추가됩니다.",
    "정해진 거리를 초과하여 장의 차량 및 앰뷸런스를 이용할 경우 차량 서비스 비용이 1km당 2,000원 추가됩니다",
    "관외 이동시 도로교통비가 발생할 경우 해당 비용을 청구합니다.",
    "사고사의 경우 염습에 특수처리가 필요하여 20만원의 비용이 추가됩니다.",
    "매장의 경우 매장 전용 관, 수의, 횡대 등의 물품이 필요하여 추가 비용이 발생합니다.",
  ],
};
