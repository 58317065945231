import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import { ColorSystemProps, FUNERAL_SIDO } from "../../../constants";
import { A_1TypeCTA, A_2TypeCTA } from "./CTA";
import { ATypePriceSummary } from "./PriceSummary";
import { ATypeFacility } from "./Facility";
import { ATypeLocation } from "./Location";

type managedTypeProps = "전문" | "공설" | "대학병원";

export function ATypeCompareParlor({
  firstParlor,
  secondParlor,
  mainColor,
  event,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event: ClickEventType;
}) {
  const managedTypeDescription = {
    전문: "많은 비용 부담은 들지 않으면서 어느 정도 시설이 나쁘지 않은 수준의 장례식장",
    대학병원:
      "비용 부담이 조금 있더라도 쾌적하고 고급스러운 장례식장을 원하시는 분들이 많이 찾는 장례식장",
    공설: "다른 전문 장례식장이나 대학병원장례식장에 비해 비용이 매우 저렴하나, 그 수가 적은 장례식장",
  };

  const firstParlorType = firstParlor.managed_type;
  const secondParlorType = secondParlor.managed_type;

  return (
    <>
      <Stack
        padding="20px"
        height="220px"
        justifyContent="center"
        style={{ background: "#7d7d7d" }}
        className="white"
      >
        <Box className="body_14_m">장례식장 비교</Box>
        <h1 className="subtitle_21_sb" style={{ padding: "4px 0 16px" }}>
          {firstParlor.companyname}
          <br />
          vs {secondParlor.companyname}
        </h1>
        <p className="caption_12_m">2023.09.14 14:30 </p>
      </Stack>

      <Stack gap="20px" padding="20px" className="body_16_m gray900">
        <Box>
          {firstParlor.companyname}과 {secondParlor.companyname}은{" "}
          {FUNERAL_SIDO.get(Number(firstParlor.sido))}에 위치한 장례식장으로,
          각각{" "}
          <b style={{ color: mainColor.c400 }}>
            {firstParlorType} 장례식장, {secondParlorType} 장례식장
          </b>
          으로 분류됩니다.
        </Box>
        <Box>
          <b style={{ color: mainColor.c400 }}>{firstParlorType} 장례식장</b>은{" "}
          {managedTypeDescription[firstParlorType as managedTypeProps]}
          이며{" "}
          <b style={{ color: mainColor.c400 }}>{secondParlorType} 장례식장</b>의
          경우 {managedTypeDescription[secondParlorType as managedTypeProps]}
          입니다.
        </Box>
        <Box>
          아래에서 {firstParlor.companyname}과 {secondParlor.companyname}을 한번
          비교해보겠습니다.
        </Box>
      </Stack>

      <A_1TypeCTA
        firstParlor={firstParlor}
        secondParlor={secondParlor}
        mainColor={mainColor}
        event={event}
      />

      <ATypePriceSummary
        firstParlor={firstParlor}
        secondParlor={secondParlor}
        mainColor={mainColor}
      />

      <ATypeFacility
        firstParlor={firstParlor}
        secondParlor={secondParlor}
        mainColor={mainColor}
      />

      <ATypeLocation firstParlor={firstParlor} secondParlor={secondParlor} />

      <A_2TypeCTA mainColor={mainColor} event={event} />
    </>
  );
}
