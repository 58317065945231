import React from "react";
import { Box, Stack } from "@mui/material";
import { ParlorDetailProps } from "../../../types";
import { GOI_WEB_URL } from "../../../constants";
export function ATypeFAQ({ defaultParlor }: { defaultParlor: ParlorDetailProps }) {
  return (
    <Stack gap="10px" padding="40px 20px">
      <Box className="subtitle_16_b">자주 묻는 질문</Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Box className="subtitle_14_b gray900">
            {defaultParlor.companyname}의 경우 영업 시간이 언제인가요?
          </Box>
          <Box className="body_14_m gray700">
            {defaultParlor.companyname}의 영업시간은 00:00 ~ 24:00입니다.
          </Box>
        </Stack>
        <Stack gap="4px">
          <Box className="subtitle_14_b gray900">
            {defaultParlor.companyname} 이용 시 비용은 얼마인가요?
          </Box>
          <Box className="body_14_m gray700">
            {defaultParlor.companyname} 빈소 비용은 최소{" "}
            {Math.round(defaultParlor.binsos[0].charge / 10000)}
            만원부터 시작합니다. 자세한 내용은{" "}
            <a
              href={GOI_WEB_URL + "/match/request/"}
              className="subtitle_14_b"
              style={{ textDecoration: "underline" }}
            >
              여기(클릭)
            </a>
            를 통해 확인할 수 있습니다.
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
