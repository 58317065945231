import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import React from "react";
import { ColorIcon } from "../../common/Icons";
import { colors } from "../../../styles";
import { GOI_WEB_URL } from "../../../constants";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";

interface BreadCrumbProps {
  href: string;
  text: string;
  event?: ClickEventType;
}

export function BTypeBreadCrumb({
  firstItem,
  secondItem,
  thirdItem,
  goiHomeEvent,
}: {
  firstItem: BreadCrumbProps;
  secondItem?: BreadCrumbProps;
  thirdItem?: BreadCrumbProps;
  goiHomeEvent?: ClickEventType;
}) {
  const onClickEvent = (event: ClickEventType) => {
    customEvent(event);
  };

  const onClickGoiHomeEvent = () => {
    if (goiHomeEvent) {
      customEvent(goiHomeEvent);
    }
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      style={{ margin: "12px 20px" }}
    >
      <Link
        underline="hover"
        color="inherit"
        href={GOI_WEB_URL}
        onClick={onClickGoiHomeEvent}
      >
        <ColorIcon name="home-fill" size={26} color={colors.gray600} />
      </Link>
      {firstItem && (
        <Link
          underline="hover"
          color="inherit"
          href={firstItem.href}
          onClick={() => onClickEvent(firstItem?.event as ClickEventType)}
        >
          <Typography className="body_14_m gray600">
            {firstItem.text}{" "}
          </Typography>
        </Link>
      )}
      {secondItem && (
        <Link
          underline="hover"
          color="inherit"
          href={secondItem.href}
          onClick={() => onClickEvent(secondItem?.event as ClickEventType)}
        >
          <Typography className="body_14_m gray600">
            {secondItem.text}
          </Typography>
        </Link>
      )}
      {thirdItem && (
        <Link
          underline="hover"
          color="inherit"
          href={thirdItem.href}
          onClick={() => onClickEvent(thirdItem?.event as ClickEventType)}
        >
          <Typography className="body_14_m gray600">
            {thirdItem.text}
          </Typography>
        </Link>
      )}
    </Breadcrumbs>
  );
}
