const faqData = [
  {
    question: "국가유공자는 장례 시 어떤 혜택을 받을 수 있나요?",
    answer:
      "국가 유공자는 전국 화장시설의 화장 비용이 무료이며 공설 장지에 우선 안치됩니다. 또한, 장례식장 별로 혜택을 받을 수 있습니다.",
  },
  {
    question: "국가유공자가 장례 혜택을 받기 위해선 어떤 게 필요한가요??",
    answer:
      "국가유공자가 장례 혜택을 받기 위해선 국가유공자임을 증명하는 국가유공자증이 필요합니다. 국가유공자증은 주소지 관할 보훈청 보상과에서 발급 가능합니다.",
  },
];

const faqList = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity:
    faqData.map((item) => {
      return {
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.answer,
        },
      };
    }) || [],
};

export const getSitenameRichSearch = ({
  name,
  url,
}: {
  name: string;
  url: string;
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name,
    url,
  };

  // alternateName: ["BT", "B-T", "Burnt Toast Shop"],
};

export const nationalMeritRichSearch = { faqList };
