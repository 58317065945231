import React from "react";
import { Box, Link } from "@mui/material";
import { customEvent } from "../../../utils";
import { ClickEventType } from "../../../types";

export function NewFitWidthButton({
  backgroundColor,
  text,
  href,
  event,
  size,
  onClick,
}: {
  backgroundColor: string;
  text: string;
  href?: string;
  event?: ClickEventType;
  size: "small" | "large" | "medium";
  onClick?: () => void;
}) {
  const onClickLink = () => {
    if (event) {
      customEvent(event);
    }
    if (onClick) {
      onClick();
    }
  };

  /** 중복된 코드가 너무 많음 */
  const styleCSS = {
    small: {
      padding: "30px 50px",
    },
    medium: {
      padding: "45px 75px",
    },
    large: {
      padding: "60px 100px",
    },
  };

  const style = {
    background: backgroundColor,
    color: "white",
    borderRadius: "8px",
    textDecoration: "none",
    width: "fit-content",
    padding: styleCSS[size].padding,
  };

  return (
    <Link
      href={href}
      // style={{
      //   background: backgroundColor,
      //   padding: "30px 50px",
      //   color: "white",
      //   borderRadius: "8px",
      //   textDecoration: "none",
      //   width: "fit-content",
      // }}
      style={style}
      onClick={onClickLink}
    >
      <Box className="subtitle_16_b">{text}</Box>
    </Link>
  );
}

// const style = {
//     background: backgroundColor,
//     color: "white",
//     borderRadius: "8px",
//     textDecoration: "none",
//     width: "fit-content",
//     padding: styleCSS[size].padding
// }
