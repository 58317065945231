import React from "react";
import { Box, Link } from "@mui/material";

export function FitWidthButton({
  backgroundColor,
  text,
  href,
}: {
  backgroundColor?: string;
  text: string;
  href: string;
}) {
  return (
    <Link
      href={href}
      style={{
        background: backgroundColor || "",
        border: backgroundColor ? "" : "1px solid #8f8f8f",
        padding: "12px 24px",
        color: backgroundColor ? "white" : "#252525",
        borderRadius: "8px",
        textDecoration: "none",
        width: "fit-content",
      }}
    >
      <Box className="button_14_sb">{text}</Box>
    </Link>
  );
}
