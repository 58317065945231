import React from "react";
import {
  ClickEventType,
  EventTriggerAndTargetType,
  ParlorDetailProps,
} from "../../../../types";
import { ColorSystemProps, GOI_WEB_URL } from "../../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthButton } from "../../../common/Buttons/FullWidthButton";

export function A_1TypeCTA({
  firstParlor,
  secondParlor,
  mainColor,
  event,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      padding="40px 0 46px"
      style={{ background: mainColor.c100 }}
      gap="24px"
    >
      <Box textAlign="center" className="subtitle_16_b">
        두 장례식장을 진지하게 고려하고 있다면, <br />
        아래 링크에서 <br />더 자세히 확인할 수 있습니다.
      </Box>
      <Stack width="100%" padding="0 34px" gap="12px">
        <FullWidthButton
          text={firstParlor.companyname}
          href={`${GOI_WEB_URL}/parlor/${firstParlor.id}/`}
          backgroundColor={mainColor.c300}
          event={
            {
              ...event,
              trigger_and_target: "uv_first_parlor",
            } as ClickEventType
          }
        />
        <FullWidthButton
          text={secondParlor.companyname}
          href={`${GOI_WEB_URL}/parlor/${secondParlor.id}/`}
          backgroundColor={mainColor.c300}
          event={
            {
              ...event,
              trigger_and_target: "uv_second_parlor",
            } as ClickEventType
          }
        />
      </Stack>
    </Stack>
  );
}
