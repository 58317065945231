import { Box, Stack } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

import parlors from "../../../constants/parlors_detail.json";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";

export function DTypeParlorList({
  title,
  binsoAveragePrice,
  sido,
  sigungu,
  CardViewType,
  event,
}: {
  sido?: string;
  sigungu?: string;
  title: string;
  binsoAveragePrice?: boolean;
  CardViewType: (res: any) => ReactNode;
  event?: ClickEventType;
}) {
  const parlorCardItems = sigungu
    ? parlors.filter((p) => String(p.sigungu) === String(sigungu))
    : parlors.filter((p) => String(p.sido) === String(sido));

  const [averagePrice, setaveragePrice] = useState(0);

  useEffect(() => {
    let price = 0;
    let binsoFullLength = 0;
    parlorCardItems.map((res) => {
      for (let i = 0; i < res.binsos.length - 1; i++) {
        price = price + Number(res.binsos[i].charge);
        binsoFullLength = binsoFullLength + 1;
      }
    });
    setaveragePrice(price / binsoFullLength);
  }, []);

  const onClickEvent = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <>
      <Box component="h4" mb="4px" className="subtitle_18_sb">
        {title}
      </Box>
      {binsoAveragePrice && (
        <Box component="p" className="caption_12_m" mb="4px">
          전체 장례식장 기준 빈소이용료(1일) 평균 가격 :{" "}
          {Math.round(averagePrice).toLocaleString()}원
        </Box>
      )}
      <Box marginTop="6px" />
      <Stack component="ul" gap="16px" onClick={onClickEvent}>
        {parlorCardItems.map((res) => CardViewType(res))}
      </Stack>
    </>
  );
}
