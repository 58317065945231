import { Box, Stack } from "@mui/material";
import { Image, Spacing, colors, getCdnImageSrc } from "goi_common";
import React from "react";

interface ATypeParlorCompareProps {
  firstParlor: any;
  secondParlor: any;
  index?: number;
}

export const ATypeParlorCompare = ({
  firstParlor,
  secondParlor,
  index = -1,
}: ATypeParlorCompareProps) => {
  return (
    <Stack>
      <Stack>장례식장 비교 {index > -1 ? index + 1 : ""}</Stack>
      <Spacing gap={20} />

      <Stack flexDirection="row" gap="10px" justifyContent="space-around">
        <Stack width="45%" gap="4px">
          <Box className="body_14_m gray600">
            {firstParlor.managed_type} 장례식장
          </Box>

          <h2 className="subtitle_16_sb" style={{ color: colors.black }}>
            {firstParlor.companyname}
          </h2>
          <Box height="120px" width="100%" overflow="hidden" borderRadius="5px">
            <Image
              src={
                firstParlor.images[0].url ||
                getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
              }
              width="100%"
              height="100%"
              alt={firstParlor.companyname}
            />
          </Box>
          {/* <Box className="body_14_m gray600">
          {firstParlor.managed_type} 장례식장
        </Box> */}
          {/* <Box className="body_14_m gray600">
          빈소 유형: {firstParlor.binsos.length}개
        </Box> */}
          {/* <Box className="body_14_m gray600">주소: {firstParlor.address}</Box> */}
          {/* <Box className="body_14_m gray600">
          조문객 50명 기준 비용:{" "}
          {firstParlor.expected_charge_sum.가족장.toLocaleString()}원
        </Box> */}
        </Stack>

        <Stack
          width="10%"
          justifyContent="center"
          textAlign="center"
          color={colors.black}
        >
          VS
        </Stack>

        <Stack width="45%" gap="4px">
          <Box className="body_14_m gray600">
            {secondParlor.managed_type} 장례식장
          </Box>
          <h2 className="subtitle_16_sb" style={{ color: colors.black }}>
            {secondParlor.companyname}
          </h2>
          <Box height="120px" width="100%" overflow="hidden" borderRadius="5px">
            <Image
              src={
                secondParlor.images[0].url ||
                getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
              }
              width="100%"
              height="100%"
              alt={secondParlor.companyname}
            />
          </Box>
          {/* <Box className="body_14_m gray600">
          {secondParlor.managed_type} 장례식장
        </Box> */}
          {/* <Box className="body_14_m gray600">
          빈소 유형: {secondParlor.binsos.length}개
        </Box> */}
          {/* <Box className="body_14_m gray600">주소: {secondParlor.address}</Box> */}
          {/* <Box className="body_14_m gray600">
          조문객 50명 기준 비용:{" "}
          {secondParlor.expected_charge_sum.가족장.toLocaleString()}원
        </Box> */}
        </Stack>
      </Stack>
    </Stack>
  );
};
