import { Box, Stack } from "@mui/material";
import React, { useMemo } from "react";
import {
  ColorSystemProps,
  FUNERAL_SIDO,
  FUNERAL_SIGUNGU,
} from "../../../constants";
import { customEvent, getCdnImageSrc } from "../../../utils";
import { ColorIcon } from "../../common";
import { ClickEventType } from "../../../types";

export function CTypeCardView({
  imgSrc,
  href,
  name,
  sido,
  sigungu,
  mainColor,
  arrowText = "자세히 보기",
  descriptions,
  event,
}: {
  imgSrc: string;
  href: string;
  sido: string;
  sigungu: string;
  name: string;
  mainColor: ColorSystemProps;
  arrowText?: string;
  descriptions?: Array<string>;
  event?: ClickEventType;
}) {
  const onClickEvent = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Stack
      component="a"
      href={href}
      flexDirection="row"
      gap="5px"
      height="160px"
      onClick={onClickEvent}
    >
      <Box
        position="relative"
        sx={{
          width: "92px",
          height: "124px",
          marginBottom: "20px",
          overflow: "hidden",
          flexShrink: 0,
          borderRadius: "5px",
        }}
      >
        <img
          src={
            imgSrc
              ? imgSrc
              : getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
          }
          style={{ position: "absolute", height: "100%" }}
          alt={name}
        />
      </Box>
      <Stack
        padding="16px"
        gap="2px"
        width="100%"
        alignItems="flex-end"
        marginTop="20px"
        style={{ background: mainColor.c500 }}
        borderRadius="5px"
        marginLeft="-70px"
      >
        <Box component="h3" className="subtitle_16_b white" marginLeft="60px">
          {name}
        </Box>

        <Box component="p" className="body_14_m gray100">
          {FUNERAL_SIDO.get(Number(sido))}{" "}
          {FUNERAL_SIGUNGU.get(Number(sido))?.get(Number(sigungu))}
        </Box>
        {descriptions &&
          descriptions.map((description: string) => {
            return (
              <Box component="p" className="body_14_m gray100" mb="5px">
                {description}
              </Box>
            );
          })}

        <Stack
          flexDirection="row"
          gap="3px"
          alignItems="flex-end"
          color={mainColor.c300}
          height="100%"
        >
          <div className="button_16_sb white">{arrowText}</div>
          <ColorIcon name="arrow_right_small" size={16} color="white" />
        </Stack>
      </Stack>
    </Stack>
  );
}
