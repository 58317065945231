import React from "react";
import { colors } from "../../../styles";
import { Box, Stack } from "@mui/material";

export default function BTypeRegionPriceSummaryItem({
  text,
  price,
}: {
  text: string;
  price: number;
}) {
  return (
    <Stack
      className="subtitle_14_b"
      flexDirection="row"
      color="black"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box component="h4" flexShrink={0} className="gray800" padding="0 10px 0 0">
        {text}
      </Box>

      <Stack alignItems="center" flexShrink={0}>
        <span style={{ borderBottom: "1px solid black", lineHeight: "12px" }}>{price}원</span>
      </Stack>
    </Stack>
  );
}
