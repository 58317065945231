import React from "react";
import { Box, Link } from "@mui/material";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";

export function FullWidthButton({
  backgroundColor,
  text,
  href,
  event,
  onClick,
}: {
  backgroundColor?: string;
  text: string;
  href?: string;
  event?: ClickEventType;
  onClick?: () => void;
}) {
  const onClickLink = () => {
    if (event) {
      customEvent(event);
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Link
      href={href}
      style={{
        background: backgroundColor || "",
        border: backgroundColor ? "" : "1px solid #8f8f8f",
        padding: "16px",
        color: backgroundColor ? "white" : "#252525",
        borderRadius: "8px",
        textDecoration: "none",
        textAlign: "center",
      }}
      onClick={onClickLink}
    >
      <Box className="subtitle_16_b">{text}</Box>
    </Link>
  );
}
