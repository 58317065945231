import React, { useMemo } from "react";
import { ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";

import { ColorSystemProps, FUNERAL_SIDO } from "../../../constants";

export function DTypeMainHead({
  title,
  sidoName,
  sigunguName,
  managed_type,
  binsosLength,
  defaultParlor,
  mainColor,
  isRegion,
  isHwajang,
  isJangji,
}: {
  title: string;
  sidoName?: string;
  sigunguName?: string;
  binsosLength?: number;
  managed_type?: string;
  defaultParlor: ParlorDetailProps;
  isRegion?: boolean;
  isHwajang?: boolean;
  isJangji?: boolean;
  mainColor: ColorSystemProps;
}) {
  const description = useMemo(() => {
    if (isRegion) {
      return `${sidoName} ${sigunguName ? sigunguName + " " : ""} 장례식장`;
    } else if (isHwajang) {
      return `${sidoName} ${sigunguName ? sigunguName + " " : ""} 화장장`;
    } else if (isJangji) {
      return `${sidoName} ${sigunguName ? sigunguName + " " : ""} 공설장지`;
    } else {
      return `${defaultParlor.managed_type}장례식장`;
    }
  }, [isRegion, isHwajang, isJangji]);
  return (
    <Stack position="relative" padding="20px">
      <Stack className="subtitle_21_b" flexDirection="row" gap="3px">
        <Box>[{sidoName}]</Box>
        <h1 className="subtitle_21_b ">{title}</h1>
      </Stack>

      <Stack flexDirection="row" className="gray600 caption_12_sb" gap="3px">
        <Box>{defaultParlor.address.split(" ")[0]}</Box>
        <Box>·</Box>
        {<Box>{description}</Box>}
      </Stack>
    </Stack>
  );
}
