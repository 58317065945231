export interface ColorSystemProps {
  c100: string;
  c200: string;
  c300: string;
  c400: string;
  c500: string;
}

const blue = {
  c100: "#E6F6FB",
  c200: "#66C6E4",
  c300: "#00A0D2",
  c400: "#0085BA",
  c500: "#0072A8",
};

const red = {
  c100: "#FCEBEB",
  c200: "#EA8484",
  c300: "#DC3232",
  c400: "#B02828",
  c500: "#841E1E",
};

const purple = {
  c100: "#F3F1F8",
  c200: "#B4A8D2",
  c300: "#826EB4",
  c400: "#4E426C",
  c500: "#342C48",
};

const green = {
  c100: "#EDF8EE",
  c200: "#90D296",
  c300: "#46B450",
  c400: "#328540",
  c500: "#25612F",
};

const yellow = {
  c100: "#FFF8E6",
  c200: "#FFD566",
  c300: "#FFB900",
  c400: "#EE8E0D",
  c500: "#DD6318",
};

const orange = {
  c100: "#FEF1EB",
  c200: "#FAA87F",
  c300: "#F56E29",
  c400: "#CA4B21",
  c500: "#AA3F1A",
};

const lightgray = {
  c100: "#FBFBFD",
  c200: "#EEEFF1",
  c300: "#E3E4E8",
  c400: "#CCCFD4",
  c500: "#A2ABB2",
};

const darkgray = {
  c100: "#9099A2",
  c200: "#6D7781",
  c300: "#555E67",
  c400: "#32373B",
  c500: "#191E22",
};

export const colorSystem = {
  blue,
  red,
  purple,
  green,
  yellow,
  orange,
  lightgray,
  darkgray,
};
