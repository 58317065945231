import { Link, Stack } from "@mui/material";
import React from "react";
import { colors } from "../../../styles";
import { ColorIcon } from "../Icons";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";

export function FullWidthRadiusButton({
  backgroundColor,
  text,
  href,
  event,
}: {
  backgroundColor?: string;
  text: string;
  href: string;
  event?: ClickEventType;
}) {
  const onClickLink = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Link
      href={href}
      style={{
        border: `1px solid ${colors.gray300}`,
        overflow: "hidden",
        color: colors.gray900,
        borderRadius: "50px",
        textDecoration: "none",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      onClick={onClickLink}
    >
      <Stack
        className="subtitle_16_b"
        padding="16px 24px"
        justifyContent="center"
      >
        {text}
      </Stack>
      <Stack
        width="80px"
        alignItems="center"
        height="100%"
        padding="16px"
        style={{ background: backgroundColor || "" }}
      >
        <ColorIcon name="arrow_right_small" size={30} color="white" />
      </Stack>
    </Link>
  );
}
