import React, { useMemo } from "react";
import { ClickEventType, DefaultFacility } from "../../../types";
import { Box, Stack } from "@mui/material";
import { ColorIcon, Link, Image } from "../../common";

// export const BreadCrumbName = ["recm", "sido", "sigungu"];

interface BTypeFacilityMainHeadProps {
  title: string;
  sidoName?: string;
  sigunguName?: string;
  binsosLength?: number;
  defaultFacility: DefaultFacility;
  isRegion?: boolean;
  type: "hwajang" | "jangji";
  // event?: ClickEventType;
}

export function BTypeFacilityMainHead({
  title,
  sidoName,
  sigunguName,
  defaultFacility,
  isRegion,
  type,
}: // event,
BTypeFacilityMainHeadProps) {
  const parlorAddressWords = defaultFacility.address.split(" ");
  const sidoNameParseFullAddress = (sidoName: string | undefined) => {
    if (sidoName === "부산") return "부산광역시";
    if (sidoName === "울산") return "울산광역시";
    if (sidoName === "경남") return "경상남도";
    if (sidoName === "강원") return "강원도";
  };

  const facilityParseName = useMemo(() => {
    if (type === "hwajang") {
      return { name: "화장장", href: "/support/free-crema" };
    }

    if (type === "jangji") {
      return { name: "공설장지", href: "/support/free-jangji" };
    }
  }, [type]);

  // const onClickEvent = (name: string) => {
  //   if (event) {
  //     customEvent({
  //       ...event,
  //       trigger_and_target: event.trigger_and_target + "_" + name,
  //     } as ClickEventType);
  //   }
  // };

  return (
    <Stack position="relative" height="182px">
      <Stack
        position="relative"
        width="100%"
        height="100%"
        zIndex={3}
        padding="50px 20px 10px"
      >
        <Stack flexDirection="row" justifyContent="space-between">
          <h1 className="subtitle_21_b white">{title}</h1>
          <Stack flexDirection="row" gap="3px">
            <ColorIcon name="rating_star" color="#FAA87F" size={15} />
            <div className="white">5.0</div>
          </Stack>
        </Stack>

        {/* <Stack
          flexDirection="row"
          className="white caption_12_m"
          alignItems="center"
          gap="6px"
        >
          <a href={undefined} onClick={() => onClickEvent(BreadCrumbName[0])}>
            {facilityParseName.name} 추천
          </a>
          <ColorIcon name="arrow_right_small" color="white" size={14} />
          <a href={undefined} onClick={() => onClickEvent(BreadCrumbName[1])}>
            {sidoName} {facilityParseName.name}
          </a>
          {sigunguName && (
            <>
              <ColorIcon name="arrow_right_small" color="white" size={14} />
              <a
                href={undefined}
                onClick={() => onClickEvent(BreadCrumbName[2])}
              >
                {sigunguName} {facilityParseName.name}
              </a>
            </>
          )}
        </Stack> */}

        <Stack className="subtitle_14_sb" alignItems="center" marginTop="auto">
          <Box
            style={{ background: "black", color: "white" }}
            padding="4px 10px"
          >
            {isRegion
              ? `${sidoNameParseFullAddress(sidoName)}${
                  sigunguName ? " " + sigunguName : ""
                }`
              : parlorAddressWords[0] + " " + parlorAddressWords[1]}
          </Box>
        </Stack>
      </Stack>
      <Stack
        width="100%"
        overflow="hidden"
        position="absolute"
        height="182px"
        zIndex={2}
        style={{
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%)",
        }}
      ></Stack>
      <Stack
        width="100%"
        height="182px"
        overflow="hidden"
        position="absolute"
        zIndex={1}
      >
        <Image
          src={defaultFacility.images[0].url || ""}
          style={{ scale: "110%" }}
        />
      </Stack>
    </Stack>
  );
}
