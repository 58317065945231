import React from "react";
import { colors } from "../../../styles";
import { Link, Spacing } from "../../";
import {
  ClickEventType,
  EventTriggerAndTargetType,
  ParlorDetailProps,
} from "../../../types";
import { customEvent } from "../../../utils";
import { HStack } from "@chakra-ui/react";
import { Button, Stack } from "@mui/material";
import { GOI_WEB_URL } from "../../../constants";

const TAB_NAME_DATAS = {
  flower: "화환 보내기",
  service: "상조 서비스",
};

interface ATypeButtonLinksProps {
  defaultParlor: ParlorDetailProps;
  event: ClickEventType;
}

export const ATypeButtonLinks = ({
  defaultParlor,
  event,
}: ATypeButtonLinksProps) => {
  const buttonStyle = {
    width: "150px",
    height: "44px",
    border: `1px solid ${colors.gray500}`,
    color: colors.gray600,
  };

  const onClickLink = (name: string) => {
    if (event) {
      customEvent({
        ...event,
        trigger_and_target:
          `${event.trigger_and_target}-${name}` as EventTriggerAndTargetType,
      });
    }
  };

  const buttonLinkEntries = Object.entries(TAB_NAME_DATAS);

  return (
    <Stack>
      <HStack justifyContent="center">
        {buttonLinkEntries.map(([key, text]) => {

          const href = key === 'flower' ? 
          `${GOI_WEB_URL}/parlor/${defaultParlor.id}/?showFlowerModal=true` : 
          `/funeral-place/${defaultParlor.companyname}/${key}/`

          return (
            <Link
              key={text}
              href = {href}
            >
              <Button
                className="button_12_sb"
                variant="outlined"
                style={buttonStyle}
                onClick={() => onClickLink(key)}
              >
                {text}
              </Button>
            </Link>
          );
        })}
      </HStack>
      <Spacing gap={30} />
    </Stack>
  );
};
