import React, { useMemo } from "react";

import { ClickEventType, DefaultFacility } from "../../../types";
import { Box, Stack } from "@mui/material";
import {
  ColorSystemProps,
  FUNERAL_SIDO,
  FUNERAL_SIGUNGU,
  GOI_WEB_URL,
} from "../../../constants";

import { Image } from "../../common/Image";
import { FitWidthSmallButton } from "../../common";

interface ETypeFacilityMainHeadProps {
  status: "hwajang" | "jangji";
  defaultFacility: DefaultFacility;
  mainColor: ColorSystemProps;
  title?: string;
  event: ClickEventType;
}

export function ETypeFacilityMainHead({
  title,
  status,
  defaultFacility,
  mainColor,
  event,
}: ETypeFacilityMainHeadProps) {
  const facilityName = useMemo(() => {
    if (status === "jangji") return "장지";
    if (status === "hwajang") return "화장장";

    return "";
  }, [status]);

  const { id, companyname, sido, sigungu, images } = defaultFacility;

  const sidoText = FUNERAL_SIDO.get(Number(sido));
  const sigunguText = FUNERAL_SIGUNGU.get(Number(sido))?.get(Number(sigungu));

  const sidoSigunguText = `${sidoText} ${sigunguText}에 위치한 ${facilityName}`;

  return (
    <Stack alignItems="center">
      <Box width="100%" height="180px" overflow="hidden">
        {images[0]?.url && (
          <Image
            src={images[0].url}
            width="100%"
            style={{ marginTop: "-50px" }}
          />
        )}
      </Box>

      <Stack gap="6px" padding="16px 0 23px" alignItems="center" width="100%">
        <h1 className="subtitle_21_b" style={{ padding: "0 20px" }}>
          {title ? title : companyname}
        </h1>
        <Box component="p">{sidoSigunguText}</Box>

        <Stack
          flexDirection="column"
          justifyContent="space-between"
          padding="16px 20px"
          gap="15px"
          width="100%"
          alignItems="center"
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box className="subtitle_14_sb" style={{ color: mainColor.c300 }}>
              관내 거주
            </Box>
            <FitWidthSmallButton
              backgroundColor={mainColor.c300}
              text={`${companyname} 비용 알아보기`}
              href={`${GOI_WEB_URL}/match/facility-requests/${id}/`}
              event={event}
            />
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box className="subtitle_14_sb" style={{ color: mainColor.c300 }}>
              관외 거주
            </Box>
            <FitWidthSmallButton
              backgroundColor={mainColor.c300}
              text={`${companyname} 비용 알아보기`}
              href={`${GOI_WEB_URL}/match/facility-requests/${id}/`}
              event={event}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
