import { EventDomainType } from "../../types";

export const DOMAINS_KR: Record<EventDomainType, string> = {
  dggb: "대구경북",
  honam: "호남",
  gyeongsang: "경상",
  gangwon: "강원",
  metro: "수도권",
  ch: "충청",
  common: "",
  heysangjo: "헤이상조",
};

export const WEBSITE_DOMAINS_DATAS: Record<
  EventDomainType,
  { name: string; url: string }
> = {
  dggb: {
    name: "대구경북 장례정보포털",
    url: "https://dggb-portal.funeralblog.co.kr",
  },
  honam: {
    name: "전라광주지역 장례추천포털",
    url: "https://www.honam-funeralcenter.co.kr",
  },
  gyeongsang: {
    name: "부산울산경남 장례안내포털",
    url: "https://www.gyeongsang-portal.co.kr",
  },
  gangwon: {
    name: "강원 장례정보공개포털",
    url: "https://www.gangwon-portal.co.kr",
  },
  metro: {
    name: "서울인천경기 장례정보안내포털",
    url: "https://www.metropolitan-funeral.co.kr",
  },
  ch: {
    name: "충청대전세종 장례소개포털",
    url: "https://www.ch-funeralportal.co.kr",
  },
  common: {
    name: "",
    url: "",
  },
  heysangjo: {
    name: "",
    url: "",
  },
};
