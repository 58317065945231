import React from "react";
import {
  ClickEventType,
  EventTriggerAndTargetType,
  ParlorDetailProps,
} from "../../../types";
import { Box, Stack } from "@mui/material";
import { colors } from "../../../styles";
import { ColorIcon } from "../../common/Icons";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { SERVICE_DETAILS } from "./standard/details";

const services = [
  "무빈소장" as const,
  "가족장" as const,
  "일반장" as const,
  "VIP장" as const,
];
const items = [
  "가격" as const,
  "접객도우미" as const,
  "장례 용품" as const,
  "장의 차량" as const,
];
const suffix: Partial<Record<(typeof items)[number], string>> = {
  가격: "만 원",
  "장의 차량": "대",
};
export function ATypeSangjoService({
  defaultParlor,
  mainColor,
  event,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const service = SERVICE_DETAILS;
  return (
    <>
      <Stack style={{ background: colors.gray50 }} padding="40px 20px">
        <Box className="subtitle_18_b">{defaultParlor.companyname}</Box>
        <Box height="1px" style={{ background: "black" }} />
        <Box margin="16px 0 12px" className="body_14_m">
          {defaultParlor.companyname}에서 이용 가능한 상조 서비스,
          <br />
          임종 및 장례 준비가 필요할 때 고이 상조 서비스를 추천 드립니다.
        </Box>
        <Stack flexDirection="row" gap="6px">
          <ColorIcon
            name="icon-location-fill"
            size={20}
            color={colors.gray500}
          />
          <Box className="body_14_m">{defaultParlor.address}</Box>
        </Stack>
        <Box
          margin="14px 0 24px"
          padding="16px 0"
          textAlign="center"
          style={{ background: colors.gray900, color: "white" }}
          className="body_16_r"
        >
          상조 서비스 이용 시<br />
          제휴 할인이 가능한 장례식장입니다.
        </Box>

        <Box marginBottom="4px" className="body_14_m">
          꼭 이용하지 않으셔도 좋습니다.
          <br />
          상담 받으시어 예상치 못한 일을 막는 것만으로도 충분합니다.
        </Box>
        <FullWidthButton
          text="상담 전화 1666-9784"
          backgroundColor={mainColor.c400}
          href="tel:1666-9784"
          event={
            {
              ...event,
              trigger_and_target: "goi_call",
            } as ClickEventType
          }
        />
      </Stack>
      <Stack
        flexDirection="row"
        padding="45px 20px 60px"
        justifyContent="center"
        alignItems="flex-end"
        gap="30px"
      >
        <Stack alignItems="center">
          <Box marginBottom="20px" className="subtitle_16_b">
            상조 이용 시
          </Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: mainColor.c100,
              border: `1px solid ${mainColor.c400}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m"
          >
            인력 용품 차량
            <br />
            합리적으로
          </Stack>
          <Box className="subtitle_16_b">+</Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: mainColor.c100,
              border: `1px solid ${mainColor.c400}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m"
          >
            장례식장 이용료
            <br />
            제휴 할인
          </Stack>
        </Stack>
        <Box
          height="320px"
          width="1px"
          style={{ background: colors.gray300 }}
        />
        <Stack alignItems="center">
          <Box marginBottom="20px" className="subtitle_16_b">
            장례식장 이용 시
          </Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: colors.gray900,
              border: `1px solid ${colors.gray900}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m gray300"
          >
            인력 용품 차량
            <br />
            비싸게
          </Stack>
          <Box className="subtitle_16_b">+</Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: colors.gray900,
              border: `1px solid ${colors.gray900}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m gray300"
          >
            장례식장 이용료
            <br />
            가격 그대로
          </Stack>
        </Stack>
      </Stack>
      <Stack
        flexDirection="row"
        gap={10}
        overflow="scroll"
        maxWidth="630px"
        width="100vw"
        alignItems="center"
        style={{
          justifyContent: "center",
        }}
      >
        <Stack
          flexDirection="row"
          maxWidth="480px"
          width="100%"
          padding="0px 24px"
          gap="10px"
        >
          {services.map((name) => (
            <Stack gap="16px" key={name} alignItems="center">
              <div
                className="subtitle2_sb"
                id={name}
                style={{
                  color: colors.gray900,
                }}
              >
                {name}
              </div>
              <Stack
                padding="24px 13px"
                borderRadius="20px"
                gap="30px"
                flexGrow={1}
                width="100px"
                style={{ background: colors.gray50 }}
              >
                {items.map((a) => (
                  <Stack key={a} alignItems="center">
                    <div
                      className="body2"
                      style={{
                        color: colors.gray600,
                      }}
                    >
                      {a}
                    </div>
                    <div
                      className="button"
                      style={{
                        color: colors.gray800,
                      }}
                    >
                      {service[name][a]}
                      {suffix[a]}
                    </div>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack padding="22px 20px 40px">
        <FullWidthButton
          text="서비스 상세 내역 보기"
          backgroundColor={mainColor.c400}
          href={GOI_WEB_URL + "/service/"}
          event={
            {
              ...event,
              trigger_and_target: "uv_service_detail",
            } as ClickEventType
          }
        />
      </Stack>
    </>
  );
}
