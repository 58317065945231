import { customEvent } from "./customEvent";
import { isMobile } from "./isMobile";

export const call = (callNumber: string | "1666-9784", isUrgent = false) => {
  const isGoiCall =
    callNumber === "1666-9784" || callNumber === "070-4035-1657";
  customEvent({
    action: isGoiCall ? "goi_call" : "call",
  });

  if (isGoiCall && !isUrgent) {
    const now = new Date().getUTCHours();
    const currentKoreanHour = (now + 9) % 24;

    if (currentKoreanHour >= 2 && currentKoreanHour < 7) {
      alert(
        "고이장례연구소 고객센터: 새벽 2시~6시에는 임종 접수, 긴급 상담만 가능합니다. 다른 문의는 오전 6시 이후 가능합니다.(임종접수/긴급상담: 1666-9784)"
      );
      return;
    }
  }
  if (isMobile()) {
    location.href = `tel:${callNumber}`;
  } else {
    alert("전화번호: " + callNumber);
  }
};
