import React from "react";
import { Box, Link } from "@mui/material";
import { customEvent } from "../../../utils";
import { ClickEventType } from "../../../types";

export function FitWidthSmallButton({
  backgroundColor,
  text,
  href,
  event,
}: {
  backgroundColor: string;
  text: string;
  href: string;
  event?: ClickEventType;
}) {
  const onClickLink = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Link
      href={href}
      style={{
        background: backgroundColor,
        padding: "8px 12px",
        color: "white",
        borderRadius: "8px",
        textDecoration: "none",
        width: "fit-content",
      }}
      onClick={onClickLink}
    >
      <Box className="button_12_sb">{text}</Box>
    </Link>
  );
}
