import React from "react";

import { Box, Stack } from "@mui/material";

export function FuneralServiceTypePriceSummaryCTA({
  name,
  serviceTypePrice,

  backgroundColor,
}: {
  name: string;
  serviceTypePrice: any;
  backgroundColor: string;
}) {
  return (
    <Box>
      <Box mb="12px" component="h4" className="subtitle_18_b orange650">
        <b>{name} 장례식장 무빈소장, 가족장, 일반장 가격 안내</b>
      </Box>
      <Stack
        typography="body2"
        padding="16px"
        style={{
          backgroundColor,
          borderRadius: "5px",
        }}
        gap="8px"
        mb="16px"
      >
        <Stack direction="row" justifyContent="space-between">
          <b>무빈소장 평균 예상 비용</b>
          <div>{serviceTypePrice.무빈소장.toLocaleString()}원</div>
        </Stack>
        <Box width="100%" height="1px" sx={{ background: "white" }} />
        <Stack direction="row" justifyContent="space-between">
          <b>가족장 평균 예상 비용</b>
          <div>{serviceTypePrice.가족장.toLocaleString()}원</div>
        </Stack>
        <Box width="100%" height="1px" sx={{ background: "white" }} />

        <Stack direction="row" justifyContent="space-between">
          <b>일반장 평균 예상 비용</b>
          <div>{serviceTypePrice.일반장.toLocaleString()}원</div>
        </Stack>
      </Stack>
    </Box>
  );
}
