import React from "react";
import { Box } from "@mui/material";
import { colors } from "../../../styles/theme/theme";

export function ATypeNationalMeritDescription() {
  return (
    <Box
      className="body_16_m"
      style={{ background: colors.gray50, padding: "30px 14px", margin: "20px 0" }}
    >
      지역 내 모든 화장 시설에서 화장 비용 전액을 감면 받기 위해선{" "}
      <span className="subtitle_16_b">국가유공자증</span>이 필요합니다.
      <br />
      <br />
      국가유공자증은 주소지 관할 보훈청 보상과에서 발급 가능합니다.
    </Box>
  );
}
