import { createTheme, PaletteOptions } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    head1_b: true;
    head1_sb: true;
    head0: true;
    head0_sb: true;
    newHead: true;
    newTitle: true;
    head2_sb: true;
    head2_b: true;
    head2_m: true;
    subtitle1_m: true;
    subtitle1_sb: true;
    subtitle1_b: true;
    subtitle2_sb: true;
    subtitle2_b: true;
    subtitle3: true;
    subtitle4: true;
    body1: true;
    body2: true;
    body3_m: true;
    body3_r: true;
    button: true;
    button2: true;
    caption1: true;
    head_42_eb: true;
    head_42_b: true;
    head_42_sb: true;
    head_42_m: true;
    head_42_r: true;
    head_42_l: true;
    head_32_eb: true;
    head_32_b: true;
    head_32_sb: true;
    head_32_m: true;
    head_32_r: true;
    head_32_l: true;
    head_28_eb: true;
    head_28_b: true;
    head_28_sb: true;
    head_28_m: true;
    head_28_r: true;
    head_28_l: true;
    head_24_eb: true;
    head_24_b: true;
    head_24_sb: true;
    head_24_m: true;
    head_24_r: true;
    head_24_l: true;
    subtitle_21_eb: true;
    subtitle_21_b: true;
    subtitle_21_sb: true;
    subtitle_21_m: true;
    subtitle_21_r: true;
    subtitle_21_l: true;
    subtitle_18_eb: true;
    subtitle_18_b: true;
    subtitle_18_sb: true;
    subtitle_16_eb: true;
    subtitle_16_b: true;
    subtitle_16_sb: true;
    subtitle_14_eb: true;
    subtitle_14_b: true;
    subtitle_14_sb: true;
    body_18_m: true;
    body_18_r: true;
    body_18_l: true;
    body_16_m: true;
    body_16_r: true;
    body_16_l: true;
    body_14_m: true;
    body_14_r: true;
    body_14_l: true;
    caption_12_b: true;
    caption_12_sb: true;
    caption_12_m: true;
    caption_12_r: true;
    caption_11_b: true;
    caption_11_sb: true;
    caption_11_m: true;
    caption_11_r: true;
    button_16_sb: true;
    button_14_sb: true;
    button_12_sb: true;
  }
}
type ExtendedTypographyOptions = TypographyOptions;

type ExtendedPaletteOptions = PaletteOptions;
export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#F18334",
      black: "#000000",
      white: "#FEFEFF",
      goreen: "#2B8A3E",
      goreen200: "#8CE99A",

      yellow: "#FFED4B",
      kakao: "#FEE500",
      kakaoText: "#191919",
      reviewYellow: "#FFC700",

      gray50: "#f5f5f5",
      gray100: "#e9e9e9",
      gray200: "#dedede",
      gray300: "#cccccc",
      gray400: "#b9b9b9",
      gray500: "#8f8f8f",
      gray600: "#686868",
      gray700: "#535353",
      gray800: "#3d3d3d",
      gray900: "#252525",

      green50: "#EBFBEE",
      green100: "#D3F9D8",
      green150: "#B2F2BB",
      green200: "#8CE99A",
      green250: "#69DB7C",
      green300: "#51CF66",
      green350: "#40C057",
      green400: "#37B24D",
      green450: "#2F9E44",
      green500: "#2B8A3E",

      warmGray50: "#FAFAFA",
      warmGray100: "#F6F4F4",
      warmGray150: "#F1EFEF",
      warmGray200: "#EDEAE9",
      warmGray250: "#E8E5E4",
      warmGray300: "#E3DFDF",
      warmGray350: "#DFDAD9",
      warmGray400: "#DAD5D4",
      warmGray450: "#D6CFCE",
      warmGray500: "#D1CAC9",
      warmGray550: "#BEB4B3",
      warmGray600: "#AB9F9D",
      warmGray650: "#988987",
      warmGray700: "#857471",
      warmGray750: "#6F605E",
      warmGray800: "#594D4B",
      warmGray850: "#423A39",
      warmGray900: "#2C2726",
      warmGray950: "#171313",

      brown50: "#F5EEEA",
      brown100: "#EBDCD6",
      brown150: "#E1CBC1",
      brown200: "#D7B9AC",
      brown250: "#CDA897",
      brown300: "#C39783",
      brown350: "#B9856E",
      brown400: "#AF7459",
      brown450: "#9E654C",
      brown500: "#895842",
      brown550: "#7B4F3B",
      brown600: "#6E4635",
      brown650: "#603E2E",
      brown700: "#523528",
      brown750: "#452C21",
      brown800: "#37231A",
      brown850: "#291A14",
      brown900: "#1B120D",
      brown950: "#0E0907",

      blue50: "#F8FAFD",
      blue100: "#F1F5FB",
      blue150: "#EAEFF8",
      blue200: "#E3EAF6",
      blue250: "#DBE5F4",
      blue300: "#D4E0F2",
      blue350: "#CDDBF0",
      blue400: "#C6D5ED",
      blue450: "#BFD0EB",
      blue500: "#B8CBE9",
      blue550: "#98B4DF",
      blue600: "#789CD5",
      blue650: "#5885CB",
      blue700: "#3B6EBF",
      blue750: "#315C9F",
      blue800: "#274A7F",
      blue850: "#1E3760",
      blue900: "#142540",
      blue950: "#0A1220",

      orange50: "#FEFAF6",
      orange100: "#FEF5EE",
      orange150: "#FDF1E5",
      orange200: "#FDECDD",
      orange250: "#FCE7D4",
      orange300: "#FBE2CB",
      orange350: "#FBDDC3",
      orange400: "#FAD9BA",
      orange450: "#FAD4B2",
      orange500: "#F9CFA9",
      orange550: "#F6B982",
      orange600: "#F4A35B",
      orange650: "#F18334",
      orange700: "#EA7810",
      orange750: "#C3640E",
      orange800: "#9C500B",
      orange850: "#753C08",
      orange900: "#4E2805",
      orange950: "#271403",

      danger: "#E2452B",
      warning: "#FFBD14",
      success: "#2CBA00",

      overlay: "#00000080",
    },
  } as ExtendedPaletteOptions,
  typography: {
    fontFamily: "Pretendard Variable, Apple SD Gothic Neo",
    head1_b: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "42px",
    },
    head1_sb: {
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "42px",
    },

    head0: {
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "40px",
    },

    head0_sb: {
      fontWeight: 400,
      fontSize: "28px",
      lineHeight: "48px",
    },

    newHead: {
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "40px",
    },

    newTitle: {
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "32px",
    },

    head2_sb: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
    },

    head2_b: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
    },

    head2_m: {
      fontWeight: 500,

      fontSize: "24px",
      lineHeight: "32px",
    },

    subtitle1_m: {
      fontWeight: 500,

      fontSize: "21px",
      lineHeight: "30px",
    },

    subtitle1_sb: {
      fontWeight: 600,

      fontSize: "21px",
      lineHeight: "30px",
    },

    subtitle1_b: {
      fontWeight: 700,

      fontSize: "21px",
      lineHeight: "30px",
    },

    subtitle2_sb: {
      fontWeight: 600,

      fontSize: "18px",
      lineHeight: "26px",
    },

    subtitle2_b: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "26px",
    },

    subtitle3: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "26px",
    },

    subtitle4: {
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "18px",
    },

    body1: {
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "28px",
    },

    body2: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },

    body3_m: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px",
    },

    body3_r: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
    },

    button: {
      fontWeight: 600,

      fontSize: "16px",
      lineHeight: "24px",
    },

    button2: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "22px",
    },

    caption1: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px",
    },

    head_42_eb: {
      fontSize: "42px",
      fontWeight: 800,
      lineHeight: "60px",
    },

    head_42_b: {
      fontSize: "42px",
      fontWeight: 700,
      lineHeight: "60px",
    },

    head_42_sb: {
      fontSize: "42px",
      fontWeight: 600,
      lineHeight: "60px",
    },

    head_42_m: {
      fontSize: "42px",
      fontWeight: 500,
      lineHeight: "60px",
    },

    head_42_r: {
      fontSize: "42px",
      fontWeight: 400,
      lineHeight: "60px",
    },

    head_42_l: {
      fontSize: "42px",
      fontWeight: 300,
      lineHeight: "60px",
    },

    head_32_eb: {
      fontSize: "32px",
      fontWeight: 800,
      lineHeight: "42px",
    },

    head_32_b: {
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "42px",
    },

    head_32_sb: {
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: "42px",
    },

    head_32_m: {
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "42px",
    },

    head_32_r: {
      fontSize: "32px",
      fontWeight: 400,
      lineHeight: "42px",
    },

    head_32_l: {
      fontSize: "32px",
      fontWeight: 300,
      lineHeight: "42px",
    },

    head_28_eb: {
      fontSize: "28px",
      fontWeight: 800,
      lineHeight: "40px",
    },

    head_28_b: {
      fontSize: "28px",
      fontWeight: 700,
      lineHeight: "40px",
    },

    head_28_sb: {
      fontSize: "28px",
      fontWeight: 600,
      lineHeight: "40px",
    },

    head_28_m: {
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "40px",
    },

    head_28_r: {
      fontSize: "28px",
      fontWeight: 400,
      lineHeight: "40px",
    },

    head_28_l: {
      fontSize: "28px",
      fontWeight: 300,
      lineHeight: "40px",
    },

    head_24_eb: {
      fontSize: "24px",
      fontWeight: 800,
      lineHeight: "32px",
    },

    head_24_b: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
    },

    head_24_sb: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",
    },

    head_24_m: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "32px",
    },

    head_24_r: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "32px",
    },

    head_24_l: {
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "32px",
    },

    subtitle_21_eb: {
      fontSize: "21px",
      fontWeight: 800,
      lineHeight: "30px",
    },

    subtitle_21_b: {
      fontSize: "21px",
      fontWeight: 700,
      lineHeight: "30px",
    },

    subtitle_21_sb: {
      fontSize: "21px",
      fontWeight: 600,
      lineHeight: "30px",
    },

    subtitle_21_m: {
      fontSize: "21px",
      fontWeight: 500,
      lineHeight: "30px",
    },

    subtitle_21_r: {
      fontSize: "21px",
      fontWeight: 400,
      lineHeight: "30px",
    },

    subtitle_21_l: {
      fontSize: "21px",
      fontWeight: 300,
      lineHeight: "30px",
    },

    subtitle_18_eb: {
      fontSize: "18px",
      fontWeight: 800,
      lineHeight: "26px",
    },

    subtitle_18_b: {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26px",
    },

    subtitle_18_sb: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "26px",
    },

    subtitle_16_eb: {
      fontSize: "16px",
      fontWeight: 800,
      lineHeight: "24px",
    },

    subtitle_16_b: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },

    subtitle_16_sb: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },

    subtitle_14_eb: {
      fontSize: "14px",
      fontWeight: 800,
      lineHeight: "22px",
    },

    subtitle_14_b: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
    },

    subtitle_14_sb: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
    },

    body_18_m: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "28px",
    },

    body_18_r: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "28px",
    },

    body_18_l: {
      fontSize: "18px",
      fontWeight: 300,
      lineHeight: "28px",
    },

    body_16_m: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
    },

    body_16_r: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },

    body_16_l: {
      fontSize: "16px",
      fontWeight: 300,
      lineHeight: "24px",
    },

    body_14_m: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
    },

    body_14_r: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
    },

    body_14_l: {
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "22px",
    },

    caption_12_b: {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
    },

    caption_12_sb: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
    },

    caption_12_m: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "18px",
    },

    caption_12_r: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
    },

    caption_11_b: {
      fontSize: "11px",
      fontWeight: 700,
      lineHeight: "16px",
    },

    caption_11_sb: {
      fontSize: "11px",
      fontWeight: 600,
      lineHeight: "16px",
    },

    caption_11_m: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "16px",
    },

    caption_11_r: {
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "16px",
    },

    button_16_sb: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "16px",
    },

    button_14_sb: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "14px",
    },

    button_12_sb: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "12px",
    },
  } as ExtendedTypographyOptions,
});
