import facilities from "../constants/facilities_detail.json";

interface useFacilitiesOptions {
  sido?: string;
  sigungu?: string;
}

export const useFacilities = ({ sido, sigungu }: useFacilitiesOptions) => {
  const facilityList = sigungu
    ? facilities.filter((p) => String(p.sigungu) === String(sigungu))
    : facilities.filter((p) => String(p.sido) === String(sido));

  const facilityCardPrices = facilityList.map(({ hallrents }, index) => {
    const facilityCardPrice = hallrents.reduce(
      (acc, { charge }) => {
        const { min, max } = acc;
        const newAcc = { ...acc };

        if (charge) {
          if (charge < min) {
            newAcc.min = charge;
          }

          if (charge > max) {
            newAcc.max = charge;
          }
          return newAcc;
        }

        return newAcc;
      },
      {
        min: 0,
        max: 0,
      } as Record<"min" | "max", number>
    );

    return facilityCardPrice;
  });

  return {
    facilityList,
    facilityPrices: facilityCardPrices,
  };
};
