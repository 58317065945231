import React from "react";
import { Box, Stack } from "@mui/material";
import { ClickEventType, DefaultFacility } from "../../../types";
import { GOI_WEB_URL } from "../../../constants";
import { customEvent } from "../../../utils";

interface ATypeFacilityFAQProps {
  defaultFacility: DefaultFacility;
  event: ClickEventType;
}

export function ATypeFacilityFAQ({
  defaultFacility,
  event,
}: ATypeFacilityFAQProps) {
  const { min, max } = defaultFacility.hallrents.reduce(
    (acc, { charge }) => {
      const { min, max } = acc;
      const newAcc = { ...acc };

      if (charge) {
        if (charge < min) {
          newAcc.min = charge;
        }

        if (charge > max) {
          newAcc.max = charge;
        }
        return newAcc;
      }

      return newAcc;
    },
    {
      min: 0,
      max: 0,
    } as Record<"min" | "max", number>
  );

  return (
    <Stack gap="10px">
      <Box className="subtitle_16_b">자주 묻는 질문</Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Box className="subtitle_14_b gray900">
            Q. {defaultFacility.companyname} 시설을 이용 시 얼마 정도의 비용이
            발생하나요?
          </Box>
          <Box className="body_14_m gray700">
            {defaultFacility.companyname} 시설 이용 비용은 고인의 상황에 따라
            최소{" "}
            {`${min.toLocaleString()}원부터 최대 ${max.toLocaleString()}원까지 매우 다양합니다.`}{" "}
            <a
              href={`${GOI_WEB_URL}/match/facility-requests/${defaultFacility.id}/`}
              className="subtitle_14_b"
              style={{ textDecoration: "underline" }}
              onClick={() =>
                customEvent({
                  ...event,
                  trigger_and_target: "A_facil_FAQ_uv_mth_facil_req",
                })
              }
            >
              {defaultFacility.companyname} 상세 비용 알아보기
            </a>{" "}
            를 통해 더 자세한 비용을 확인하실 수 있습니다.
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
