import React, { ReactNode } from "react";

export function ParlorRegionHeadSection({
  breadCrumb,
  title,
  ctaList,
}: {
  breadCrumb?: ReactNode;
  title: ReactNode;
  ctaList?: ReactNode[];
}) {
  return (
    <>
      {breadCrumb && breadCrumb}

      {title}

      {ctaList && ctaList.map((res) => res)}
    </>
  );
}
