import React from "react";
import { Box, Link } from "@mui/material";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";

export function FullWidthTwoLineButton({
  backgroundColor,
  text,
  description,
  href,
  event,
}: {
  backgroundColor: string;
  text: string;
  description: string;
  href: string;
  event?: ClickEventType;
}) {
  const onClickLink = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Link
      href={href}
      style={{
        background: backgroundColor,
        padding: "16px",
        color: "white",
        borderRadius: "8px",
        textDecoration: "none",
        textAlign: "center",
      }}
      onClick={onClickLink}
    >
      <Box className="caption_12_m">{description}</Box>
      <Box className="subtitle_16_b">{text}</Box>
    </Link>
  );
}
