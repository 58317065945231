import React from "react";
import { Box, Link, Stack } from "@mui/material";

export function FullWidthTwinButton({
  backgroundColor,
  firstBtn,
  secondBtn,
}: {
  backgroundColor?: string;
  firstBtn: { text: string; href?: string; onClick?: () => void };
  secondBtn: { text: string; href?: string; onClick?: () => void };
}) {
  return (
    <Stack flexDirection="row" width="100%" gap="12px">
      <Link
        href={firstBtn.href && firstBtn.href}
        onClick={firstBtn.onClick && firstBtn.onClick}
        style={{
          flex: 1,
          background: backgroundColor || "",
          border: backgroundColor ? "" : "1px solid #8f8f8f",
          padding: "16px",
          color: backgroundColor ? "white" : "#252525",
          borderRadius: "8px",
          textDecoration: "none",
          textAlign: "center",
        }}
      >
        <Box className="subtitle_16_b">{firstBtn.text}</Box>
      </Link>
      <Link
        href={secondBtn.href && secondBtn.href}
        onClick={secondBtn.onClick && secondBtn.onClick}
        style={{
          flex: 1,
          background: backgroundColor || "",
          border: backgroundColor ? "" : "1px solid #8f8f8f",
          padding: "16px",
          color: backgroundColor ? "white" : "#252525",
          borderRadius: "8px",
          textDecoration: "none",
          textAlign: "center",
        }}
      >
        <Box className="subtitle_16_b">{secondBtn.text}</Box>
      </Link>
    </Stack>
  );
}
