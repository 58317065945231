import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ParlorDetailProps } from "../../../types";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { numberToLocalString } from "../../../utils";
import { FullWidthTwoLineButton } from "../../common/Buttons/FullWidthTwoLineButton";
import { colors } from "../../../styles";
import { ColorIcon } from "../../common/Icons";
import { Spacing } from "../../common";

export function ATypeBinsoPriceSummary({
  defaultParlor,
  mainColor,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  const binsos = defaultParlor.binsos;
  const binsoLength = binsos.length - 1;
  const firstBinso = binsos[0];
  const lastBinso = binsos[binsoLength];

  const parsePriceToTenThousand = (price: number) => {
    return numberToLocalString(Math.round(price / 10000));
  };

  return (
    <Stack>
      <FullWidthTwoLineButton
        event={{
          domain: "dggb",
          path: "funeral_place_binso",
          trigger_and_target: "CTA_uv_mth_req",
        }}
        backgroundColor={mainColor.c400}
        text="장례 총 비용 알아보기"
        description="장례식장을 포함한 총 비용이 알고싶다면?"
        href={GOI_WEB_URL + "/match/request/"}
      />
      <Spacing gap={30} />

      <Box className="subtitle_16_b">{defaultParlor.companyname} 비용</Box>
      <Box className="subtitle_14_b" padding="20px 0 8px">
        예상 비용 :{" "}
        {parsePriceToTenThousand(defaultParlor.expected_charge_sum.무빈소)} ~{" "}
        {parsePriceToTenThousand(defaultParlor.expected_charge_sum.일반장)}
        만원
      </Box>
      <Box className="body_14_m">
        {defaultParlor.companyname}의 빈소는 {firstBinso.pyeong}평 ~{" "}
        {lastBinso.pyeong}평 으로 구성되어 있고, 1일 기준{" "}
        {parsePriceToTenThousand(firstBinso.charge)}만원에서 최대{" "}
        {parsePriceToTenThousand(lastBinso.charge)}만원까지 비용이 발생할 수
        있습니다.
      </Box>

      <TableContainer
        sx={{
          padding: "20px 0 24px",
          ["td, tr, th"]: {
            border: `1px solid ${colors.gray500}`,
            textAlign: "center",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="subtitle_14_sb">평형</TableCell>
              <TableCell className="subtitle_14_sb">
                비용(24시간 기준)
              </TableCell>
              <TableCell className="subtitle_14_sb">할인가</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {binsos.map((res) => {
              return (
                <TableRow>
                  <TableCell className="subtitle_14_sb">
                    {res.pyeong}평
                  </TableCell>
                  <TableCell className="subtitle_14_sb">
                    {numberToLocalString(res.charge)}원
                  </TableCell>
                  <TableCell className="subtitle_14_sb">
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Link
                        underline="none"
                        href={GOI_WEB_URL + "/match/request/"}
                      >
                        {" "}
                        알아보기
                      </Link>
                      <ColorIcon
                        name="arrow_right_small"
                        size={16}
                        color={mainColor.c300}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
