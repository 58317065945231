import React from "react";
import { Box, Stack } from "@mui/material";

import { ColorSystemProps } from "../../../constants";
import { FullWidthRadiusButton } from "../../common/Buttons/FullWidthRadiusButton";
import { ColorIcon } from "../../common/Icons";
import { ClickEventType, ParlorDetailProps } from "../../../types";

interface NewCTypeCTAProps {
  mainColor: ColorSystemProps;
  href: string;
  primary?: string;
  secondary?: string;
  event?: ClickEventType;
}

export function NewCTypeCTA({
  mainColor,
  href,
  primary = "",
  secondary,
  event,
}: NewCTypeCTAProps) {
  return (
    <Stack gap="16px" padding="0 20px 20px">
      <Stack gap="10px">
        <p
          style={{ textAlign: "center", whiteSpace: "pre-wrap" }}
          className="subtitle_14_sb"
        >
          {secondary}
        </p>
        <FullWidthRadiusButton
          backgroundColor={mainColor.c400}
          text={primary}
          href={href}
          event={event}
        />
      </Stack>

      <Stack flexDirection="row" gap="6px" alignItems="center">
        <ColorIcon name="icon-check-fill-mono" size={14} color="" />
        <Box className="caption_12_m">이 지역 포함해서 찾기</Box>
      </Stack>
    </Stack>
  );
}
