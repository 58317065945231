import {
  Box,
  Button,
  ChakraProvider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import React, { useCallback, useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { customEvent } from "../../utils";
import { chakraTheme, colors } from "../../styles";
import { ColorIcon, HStack, VStack } from "../common";

import emailjs from "@emailjs/browser";
import { ClickEventType } from "../../types";

interface SearchRequestModalProps {
  parlorName: string;
  isOpen: boolean;
  onClose: () => void;
  event: ClickEventType;
}
export default function BinsoReserveModal({
  parlorName,
  isOpen,
  onClose,
  event,
}: SearchRequestModalProps) {
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);

  const handleClick = useCallback(() => {
    customEvent({
      domain: "common",
      path: "",
      trigger_and_target: "send_binso_reserve_email_try",
    });
    customEvent({
      ...event,
      trigger_and_target: "send_binso_reserve_email_try",
    });

    if (!checked) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }

    if (!value) {
      alert("번호를 입력해주세요");
      return;
    }

    if (/^010-\d{4}-\d{4}$/.test(value)) {
      emailjs
        .send(
          "service_agc6alu",
          "template_o1ul2fg",
          {
            question: `독사운드 ${event.domain}에서 요청한 ${parlorName} 빈소 예약 도움 요청`,
            phone: value,
          },
          "ckK66U9O1M16QnFJq"
        )
        .then(() => {
          customEvent({
            domain: "common",
            path: "",
            trigger_and_target: "send_binso_reserve_email",
          });
          customEvent({
            ...event,
            trigger_and_target: "send_binso_reserve_email",
          });
          alert("요청이 완료되었습니다. 30분 이내로 답변드리겠습니다.");
          setValue("");
          onClose();
          setChecked(false);
        });
    } else {
      alert("번호 형식을 확인해주세요. (010-****-****)");
    }
  }, [checked, value]);

  return (
    <ChakraProvider theme={chakraTheme}>
      <Box height="100%" width="100%">
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
          closeOnOverlayClick={false}
          scrollBehavior="inside"
          isCentered={true}
          useInert={false}
        >
          <ModalOverlay width="100%" height="100%" />
          <ModalContent
            maxWidth="380px"
            minWidth="290px"
            backgroundColor={colors.white}
            backgroundSize="cover"
          >
            <ModalBody padding="15px 14px 36px">
              <VStack width="100%" gap={10}>
                <HStack width="100%" justifyContent="end">
                  <div onClick={onClose}>
                    <ColorIcon name="close" width={24} height={24} color={colors.black} />
                  </div>
                </HStack>
                <VStack width="100%" gap={24}>
                  <VStack gap={12} width="100%">
                    <Box className="body_18_m">빈소 예약 도움받기</Box>
                    <Box className="button_16_sb">
                      빠른 빈소 예약을 위해 상주님의 번호를 입력해주세요.
                    </Box>
                  </VStack>
                  <Box
                    className="button_16_sb"
                    background={"#EEF2F6"}
                    margin="20px 0 5px"
                    padding="11px 7px"
                    borderRadius="5px"
                  >
                    휴대폰 번호 입력하기
                  </Box>
                  <Input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    border={`1px solid ${colors.gray400}`}
                    width="100%"
                    placeholder={"010-****-****"}
                  />

                  <HStack alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      label="개인정보 수집 및 이용 동의"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => {
                            setChecked(!checked);
                          }}
                        />
                      }
                    />

                    <a
                      className="caption_11_b"
                      href="https://goifuneral.notion.site/e541b207305a46089ede35271b449b87"
                      target="_blank"
                    >
                      보기
                    </a>
                  </HStack>
                  <Button
                    width="100%"
                    backgroundColor={colors.orange650}
                    color={colors.white}
                    onClick={handleClick}
                  >
                    빈소 예약 도움받기
                  </Button>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
}
