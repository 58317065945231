/* honam, gangwon 에서 CTA 의 클릭율을 테스트하기 위한 컴포넌트입니다. */

import React from "react";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthTwoLineButton } from "../../common/Buttons/FullWidthTwoLineButton";
import { ColorIcon } from "../../common/Icons";
import { ClickEventType, ParlorDetailProps } from "../../../types";

interface DTypeCTAProps {
  mainColor: ColorSystemProps;
  sidoName?: string;
  sigunguName?: string;
  event?: ClickEventType;
  text?: string;
  companyname: string;
  href: string;
}

export function DTypeCTA({
  mainColor,
  sidoName,
  sigunguName,
  companyname,
  event,
  href,
  text = "장례식장 비용 알아보기",
}: DTypeCTAProps) {
  return (
    <Stack gap="12px" padding="0 20px 20px 20px">
      <Box style={{ textAlign: "center" }} className="subtitle_16_b">
        {companyname}의
        <br />
        상세 비용을 안내해드립니다.
      </Box>

      <FullWidthTwoLineButton
        text={text}
        description=""
        href={href}
        backgroundColor={mainColor.c400}
        event={event}
      />

      {sidoName && (
        <Stack flexDirection="row" gap="6px" alignItems="center">
          <ColorIcon name="icon-check-fill-mono" size={14} color="" />
          <Box className="caption_12_m">{`${sidoName}${
            sigunguName ? " " + sigunguName : ""
          } 지역 포함해서 비교`}</Box>
        </Stack>
      )}
    </Stack>
  );
}
