import { VStack, HStack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useOverlay } from "@toss/use-overlay";
import React, { useEffect, useState } from "react";

import SearchRequestModal from "./Modal/SearchRequestModal";

import { EventDomainType } from "../types";
import { customEvent } from "../utils";
import { ColorIcon } from "./common";
import { colors } from "../styles";

interface SearchHeaderProps {
  eventPath: keyof typeof EventDomainType;
}

export const SearchHeader = ({ eventPath }: SearchHeaderProps) => {
  const [keyword, setKeyword] = useState<string>("");
  const overlay = useOverlay();

  const moveToResult = () => {
    customEvent({
      domain: "common",
      path: "header",
      trigger_and_target: "open_question_modal",
    });
    customEvent({
      domain: eventPath,
      path: "header",
      trigger_and_target: "open_question_modal",
    });
    overlay.open(({ isOpen, close: onClose }) => {
      return (
        <SearchRequestModal
          title="빠르게 알아보기"
          keyword={keyword}
          domain={eventPath}
          path="header"
          isOpen={isOpen}
          onClose={onClose}
        />
      );
    });
  };

  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      window.scrollY > 57 ? setFixed(true) : setFixed(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <VStack
      maxWidth="100%"
      padding="15px"
      style={{
        position: fixed ? "fixed" : "static",
        background: `linear-gradient(to bottom, ${colors.warmGray200}, #ffffff)`,

        top: 0,
        left: 0,
        right: 0,
        zIndex: 1002,
      }}
    >
      <HStack width="100%" maxWidth="600px">
        <HStack
          style={{
            background: colors.white,

            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.16)",
            borderRadius: "35px",
            padding: "6px 10px",

            flex: 1,
          }}
          alignItems="center"
        >
          <ColorIcon
            name="search"
            color={colors.gray900}
            width={28}
            height={28}
          />
          <input
            style={{ border: "none", width: "100%", padding: "10px" }}
            placeholder="궁금한 점을 물어보세요"
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && moveToResult()}
          />
        </HStack>

        <SearchButton className="button_16_sb orange700" onClick={moveToResult}>
          알아보기
        </SearchButton>
      </HStack>
    </VStack>
  );
};

const SearchButton = styled.button`
  background: none;
  border: none;
  padding: 0 20px;
  width: "100px !important";
  flex-shrink: 0;
`;
