import React from "react";
import { Box, Link, Stack } from "@mui/material";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import {
  ClickEventType,
  EventTriggerAndTargetType,
  ParlorDetailProps,
} from "../../../types";
import { NormalCssProperties } from "@mui/material/styles/createMixins";
import { colors } from "../../../styles";
import { customEvent } from "../../../utils";

export function CTypeTabs({
  mainColor,
  selectTab,
  href,
  defaultParlor,
  event,
}: {
  mainColor: ColorSystemProps;
  selectTab: string;
  href: string;
  defaultParlor: ParlorDetailProps;
  event?: ClickEventType;
}) {
  const tabList = ["소개", "비용", "실시간 빈소", "화환 보내기", "상조 서비스"];
  const tabName = ["introduce", "price", "binso", "flower", "service"];

  const linkStyle = (tab: string) => {
    return {
      padding: "12px",
      textDecoration: "none",
      borderBottom: selectTab === tab ? `3px solid ${mainColor.c400}` : "none",
      color: selectTab === tab ? mainColor.c400 : colors.gray600,
      textAlign: "center",
    };
  };

  const onClickLink = (name: string) => {
    if (event) {
      customEvent({
        ...event,
        trigger_and_target:
          `${event.trigger_and_target}-${name}` as EventTriggerAndTargetType,
      });
    }
  };

  return (
    <>
      <Stack flexDirection="row" padding="0 16px">
        <Link
          className="subtitle_14_b"
          style={linkStyle(tabList[0]) as NormalCssProperties}
          href={`/${href}/`}
          onClick={() => onClickLink(tabName[0])}
        >
          {tabList[0]}
        </Link>

        <Link
          className="subtitle_14_b"
          style={linkStyle(tabList[1]) as NormalCssProperties}
          href={`/${href}/price/`}
          onClick={() => onClickLink(tabName[1])}
        >
          {tabList[1]}
        </Link>

        <Link
          className="subtitle_14_b"
          style={linkStyle(tabList[2]) as NormalCssProperties}
          href={`/${href}/binso/`}
          onClick={() => onClickLink(tabName[2])}
        >
          {tabList[2]}
        </Link>

        <Link
          className="subtitle_14_b"
          style={linkStyle(tabList[3]) as NormalCssProperties}
          href={`${GOI_WEB_URL}/parlor/${defaultParlor.id}/?showFlowerModal=true`}
          onClick={() => onClickLink(tabName[3])}
        >
          {tabList[3]}
        </Link>

        <Link
          className="subtitle_14_b"
          style={linkStyle(tabList[4]) as NormalCssProperties}
          href={`/${href}/service/`}
          onClick={() => onClickLink(tabName[4])}
        >
          {tabList[4]}
        </Link>
      </Stack>
      <Box width="100%" height="1px" style={{ background: mainColor.c200 }} />
    </>
  );
}
