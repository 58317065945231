import { Link, Stack } from "@mui/material";

import React from "react";
import { GOI_WEB_URL } from "../../../constants";

export function FuneralCostExpensiveCTA() {
  return (
    <Stack mb="32px" style={{ padding: "16px", borderRadius: "5px" }} gap="10px">
      <div className="gray900 subtitle_21_b" style={{ textAlign: "center", marginBottom: "10px" }}>
        장례 비용은 왜 비싼걸까요?
      </div>
      <div className="gray600 subtitle_16_b" style={{ textAlign: "center", marginBottom: "10px" }}>
        사용하지도 않는 장례 용품
        <br />더 이상 비싼 가격에 구매하지 마세요
      </div>
      <Link style={{ textDecoration: "none" }} href={`${GOI_WEB_URL}/guide/about-parlor-funeral/`}>
        <Stack direction="row" justifyContent="center" className="orange650 button">
          자세히 알아보기
        </Stack>
      </Link>
    </Stack>
  );
}
