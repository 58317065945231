import { Stack } from "@mui/material";
import React from "react";

import { getCdnImageSrc } from "../../../utils";

export function DTypeBeneficiaryHead({
  titleSido,
  title = "기초생활수급자 장례 혜택 정보",
}: {
  titleSido: string;
  title?: string;
}) {
  return (
    <Stack padding="0 0px 0px">
      <Stack
        gap="20px"
        alignItems="center"
        height="180px"
        justifyContent="center"
        margin="0 0 0px"
        style={{ background: `url(${getCdnImageSrc("dogsound/honam/benefitbackground.png")})` }}
      >
        <h1 className="subtitle_21_sb white" style={{ textAlign: "center" }}>
          <span className="body_14_r">{titleSido}</span>
          <br />
          {title}
        </h1>
      </Stack>
    </Stack>
  );
}
