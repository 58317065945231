import { default as NextLink, LinkProps as NextLinkProps } from "next/link";
import React, { AnchorHTMLAttributes, PropsWithChildren } from "react";
import { customEvent } from "../../utils";
import { CommonEventProps } from "../../types";

type LinkProps = PropsWithChildren<NextLinkProps> &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
    onClick?: () => void;
    event?: CommonEventProps;
  };

export const Link = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  children,
  event,
  ...rest
}: LinkProps) => {
  const onClickLink = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={false}
      locale={locale}
      onClick={onClickLink}
      {...rest}
    >
      {children}
    </NextLink>
  );
};
