import React from "react";
import { Box, Stack } from "@mui/material";
import { ColorIcon } from "../../common/Icons/ColorIcon";
import { ColorSystemProps, icon } from "../../../constants";

export function ATypeNationalMeritSummary({ mainColor }: { mainColor: ColorSystemProps }) {
  return (
    <>
      <Box className="subtitle_16_b" width="100%" marginTop="24px">
        국가유공자에게 제공되는 혜택
      </Box>
      <Stack
        width="100%"
        borderRadius="5px"
        flexDirection="row"
        padding="20px 30px"
        justifyContent="space-between"
        style={{ backgroundColor: mainColor.c100 }}
      >
        <SummaryItem iconName="hand-heart" mainColor={mainColor} text={"장례식장 별\n 혜택 제공"} />
        <SummaryItem
          iconName="hand-money"
          mainColor={mainColor}
          text={"지역 내  화장\n 시설 비용 무료"}
        />
        <SummaryItem
          iconName="person-check-fill"
          mainColor={mainColor}
          text={"지역 내 공설\n 장지 우선 안치"}
        />
      </Stack>
    </>
  );
}

function SummaryItem({
  iconName,
  text,
  mainColor,
}: {
  iconName: keyof typeof icon;
  text: string;
  mainColor: ColorSystemProps;
}) {
  return (
    <Stack alignItems="center" gap="10px">
      <ColorIcon name={iconName} size={50} color={mainColor.c500} />
      <Box className="subtitle_14_b" textAlign="center" whiteSpace="pre-wrap">
        {text}
      </Box>
    </Stack>
  );
}
