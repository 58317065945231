/* eslint-disable */

declare global {
  interface Window {
    ChannelIO: any;
    ChannelIOInitialized: any;
    attachEvent: any;
  }
}

type channelIO = {
  (): void;
  q: any[];
  c: (args: IArguments) => void;
};

export class ChannelService {
  constructor(needInit?: boolean) {
    if (needInit) {
      this.loadScript();
    }
  }

  loadScript() {
    if (typeof window !== "undefined") {
      const w = window;
      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function () {})(
          "ChannelIO script included twice."
        );
      }

      let ch = <channelIO>(() => {});
      const c = function (args: IArguments) {
        ch.q.push(args);
      };
      ch = <channelIO>function () {
        ch.c(arguments);
      };
      ch.c = c;
      ch.q = [];

      w.ChannelIO = ch;
      const l = () => {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
        s.charset = "UTF-8";
        const x = document.getElementsByTagName("script")[0];
        x.parentNode && x.parentNode.insertBefore(s, x);
      };

      if (document.readyState === "complete") {
        l();
      } else if (window.attachEvent) {
        window.attachEvent("onload", l);
      } else {
        window.addEventListener("DOMContentLoaded", l, false);
        window.addEventListener("load", l, false);
      }
    }
  }

  boot(settings: any) {
    window.ChannelIO("boot", settings);
  }

  shutdown() {
    window.ChannelIO("shutdown");
  }
}

export default new ChannelService();
