import Link from "next/link";
import React, { ReactNode } from "react";
import { customEvent } from "../../utils";
import { ClickEventType } from "../../types";

interface NextLinkProps {
  id?: string;
  children: ReactNode;
  href: string;
  event?: ClickEventType;
  className?: string;
  name?: string;
  style?: any;
  onClick?: () => void;
}

export function NextLink({
  id,
  children,
  href,
  event,
  className,
  name,
  onClick,
  style,
}: NextLinkProps) {
  const handleNextLinkClick = () => {
    if (event) customEvent(event);
    if (onClick) onClick();
  };

  const hasNofollow = href.includes("http") && !href.includes("goifuneral");
  return (
    <Link
      href={href}
      id={id}
      itemProp="item"
      rel={hasNofollow ? "noopener noreferrer nofollow" : ""}
      onClick={handleNextLinkClick}
      className={className}
      style={{
        outline: "none",
        textDecoration: "none",
        display: "flex",
        ...style,
      }}
      target={href.startsWith("https://") || href.startsWith("http://") ? "_blank" : undefined}
    >
      {children}
    </Link>
  );
}
