import { Box, Stack } from "@mui/material";
import React from "react";

import { ParlorDetailProps } from "../../../types";

import ATypeRegionPriceSummaryItem from "./ATypeRegionPriceSummaryItem";
import { ATypeMainHead } from "../MainHead";

export function ATypeRegionHead({
  regionParlors,
  sidoName,
  sigunguName,
  expectedParlorPrice,
}: {
  regionParlors: Array<ParlorDetailProps>;
  sidoName: string;
  sigunguName?: string;
  expectedParlorPrice: any;
}) {
  const name = `${sidoName}${sigunguName ? " " + sigunguName : ""}`;
  return (
    <>
      <ATypeMainHead
        defaultParlor={regionParlors[0]}
        title={`${sidoName}${sigunguName ? " " + sigunguName : ""} 장례식장 추천`}
        sidoName={sidoName}
        sigunguName={sigunguName}
        binsosLength={regionParlors.length}
      />

      <Stack gap="6px" padding="40px 20px 20px">
        <Box className="subtitle_16_b">{name} 장례 요약</Box>

        <ATypeRegionPriceSummaryItem
          text="조문객 50명 기준 평균 빈소 비용"
          price={expectedParlorPrice.무빈소장}
        />
        <ATypeRegionPriceSummaryItem
          text="조문객 100명 기준 평균 빈소 비용"
          price={expectedParlorPrice.가족장}
        />
        <ATypeRegionPriceSummaryItem
          text="조문객 200명 기준 평균 빈소 비용"
          price={expectedParlorPrice.일반장}
        />
      </Stack>
    </>
  );
}
