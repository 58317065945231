import {
  Box,
  Button,
  ChakraProvider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import React, { useCallback, useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { useRouter } from "next/router";
import { Axios, customEvent } from "../../utils";
import { chakraTheme, colors } from "../../styles";
import { ColorIcon, HStack, VStack } from "../common";
import { EventDomainType } from "../../types";

export default function FileRequestModal({
  isOpen,
  onClose,
  path = "parlor_detail",
}: {
  isOpen: boolean;
  onClose: () => void;
  path?: string | keyof typeof EventDomainType;
}) {
  const [type, setType] = useState("이메일");
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);
  const handleTypeChange = useCallback((type: string) => {
    setType(type);
  }, []);

  const router = useRouter();

  const handleClick = useCallback(() => {
    customEvent({
      domain: "common",
      path: "footer",
      trigger_and_target: "file_request_mdl-try_submit",
    });
    if (!checked) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }
    if (type === "이메일") {
      alert("현재 이메일 발송 시스템 점검중으로 번호입력만 가능합니다.");
    } else {
      if (!value) {
        alert("번호를 입력해주세요");
        return;
      } else {
        if (/^010-\d{4}-\d{4}$/.test(value)) {
          Axios.post("/checklist_request/", {
            telephone: value,
            path: router.asPath,
          })
            .then(() => {
              alert("신청이 완료되었습니다.");
              customEvent({
                domain: "common",
                path: "footer",
                trigger_and_target: "file_request_mdl-submit_success",
              });
              onClose();
            })
            .catch(() => {
              alert("번호를 확인해주세요.");
            });
        } else {
          alert("번호 형식을 확인해주세요. (010-****-****)");
          return;
        }
      }
    }
  }, [checked, value, type]);
  return (
    <ChakraProvider theme={chakraTheme}>
      <Box height="100%" width="100%">
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
          closeOnOverlayClick={false}
          scrollBehavior="inside"
          isCentered={true}
          useInert={false}
        >
          <ModalOverlay width="100%" height="100%" />
          <ModalContent
            minWidth="300px"
            backgroundColor={colors.white}
            backgroundSize="cover"
          >
            <ModalBody padding="20px 24px 44px">
              <VStack width="100%" gap={10}>
                <HStack width="100%" justifyContent="end">
                  <div
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <ColorIcon
                      name="close"
                      width={24}
                      height={24}
                      color={colors.black}
                    />
                  </div>
                </HStack>
                <VStack width="100%" gap={24}>
                  <HStack width="100%" alignItems="center" gap={8}>
                    <ColorIcon
                      name="list"
                      color={colors.gray900}
                      width={16}
                      height={16}
                    />
                    <div className="subtitle_21_b gray900">체크리스트 받기</div>
                  </HStack>
                  <VStack gap={12} width="100%" className="body_16_m gray800">
                    <Box className="caption_12_b">
                      장례 비용 예상 견적표, 임종 준비 체크리스트를 받아볼 수
                      있습니다.
                    </Box>
                  </VStack>
                  <HStack width="100%" gap={12}>
                    <Button
                      width="50%"
                      onClick={() => {
                        handleTypeChange("이메일");
                      }}
                      border={`1px solid ${
                        type === "이메일" ? colors.black : "none"
                      }`}
                    >
                      이메일로 받기
                    </Button>
                    <Button
                      width="50%"
                      onClick={() => {
                        handleTypeChange("번호");
                      }}
                      border={`1px solid ${
                        type === "번호" ? colors.black : "none"
                      }`}
                    >
                      번호로 받기
                    </Button>
                  </HStack>
                  <Input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    border={`1px solid ${colors.gray400}`}
                    width="100%"
                    placeholder={
                      type === "이메일"
                        ? "이메일을 입력하세요"
                        : "010-****-****"
                    }
                  />

                  <HStack alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      label="개인정보 수집 및 이용 동의"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => {
                            setChecked(!checked);
                          }}
                        />
                      }
                    />

                    <a
                      className="caption_11_b"
                      href="https://goifuneral.notion.site/e541b207305a46089ede35271b449b87"
                      target="_blank"
                    >
                      보기
                    </a>
                  </HStack>
                  <Button
                    width="100%"
                    backgroundColor={colors.orange650}
                    color={colors.white}
                    onClick={handleClick}
                  >
                    자료 받아보기
                  </Button>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
}
