import React, { ReactNode, useEffect, useRef, useState } from "react";

import Script from "next/script";

import { Box, Stack } from "@mui/material";
import { GraySpinner } from "../../common";
import { DefaultFacility, ParlorDetailProps } from "../../../types";

declare global {
  interface Window {
    naver: any;
  }
}

interface Map {
  children: ReactNode;
}

function openInfoWindow(infowindow: any, map: any, marker: any) {
  infowindow.open(map, marker);
}

function MapLayout({
  parlor,
  latitude,
  longitude,
  companyname,
}: {
  parlor?: ParlorDetailProps | DefaultFacility;
  latitude?: Number;
  longitude?: Number;
  companyname?: String;
}) {
  const [isLoad, setIsLoad] = useState(false);
  const mapRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    if (!isLoad || !mapRef) return;
    let lat;
    let lng;
    let name;
    if (parlor) {
      lat = parlor.latitude;
      lng = parlor.longitude;
      name = parlor.companyname;
    }
    if (latitude && longitude && companyname) {
      lat = latitude;
      lng = longitude;
      name = companyname;
    }

    const mapOptions = {
      center: new window.naver.maps.LatLng(lat, lng),
      zoom: 13,
    };
    const map = new window.naver.maps.Map(mapRef.current, mapOptions);
    const markerOptions = {
      position: new window.naver.maps.LatLng(lat, lng),
      map,
    };

    const marker = new window.naver.maps.Marker(markerOptions);

    const infowindow = new window.naver.maps.InfoWindow({
      content: `<div style='width:100%; padding: 6px 12px; border-radius: 5px' class='body3_m'>${name}</div>`,
      borderWidth: 1,
      borderRadius: 5,
      pixelOffset: new window.naver.maps.Point(0, -5),
      anchorSize: 0,
    });
    openInfoWindow(infowindow, map, marker);

    return () => map.destroy();
  }, [isLoad, parlor]);

  return (
    <>
      <Script
        src={`https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.NEXT_PUBLIC_NAVERMAP_KEY}`}
        onLoad={() => setIsLoad(true)}
      />
      <Box position="relative" height="320px" width="100%" id="mapLayout">
        <Stack
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height="320px"
          width="100%"
          ref={mapRef}
        >
          {!isLoad && <GraySpinner />}
        </Stack>
      </Box>
    </>
  );
}

export { MapLayout };
