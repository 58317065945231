import React from "react";
import { ParlorDetailProps } from "../../../../types";
import { ColorSystemProps, GOI_WEB_URL } from "../../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthButton } from "../../../common/Buttons/FullWidthButton";

export function B_1TypeCTA({
  firstParlor,
  secondParlor,
  mainColor,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      padding="40px 0 46px"
      style={{ background: mainColor.c100 }}
      gap="24px"
    >
      <Box textAlign="center" className="subtitle_18_b">
        장례식장의 자세한 비용 정보는
        <br />
        아래에서 <br />
        1분만에 확인하실 수 있습니다.
      </Box>
      <Stack width="100%" padding="0 34px" gap="12px">
        <FullWidthButton
          text={"1분만에 자세한 비용 확인하기"}
          href={`${GOI_WEB_URL}/parlor/step-guide/parlor-initiation/`}
          backgroundColor={mainColor.c300}
        />
      </Stack>
    </Stack>
  );
}
