import { Box, Stack } from "@mui/material";
import React from "react";

import { ColorIcon, FullWidthButton } from "../../common";
import { colors } from "../../../styles";
import { ClickEventType } from "../../../types";

interface CTypeFacilityFAQProps {
  event: ClickEventType;
}

export function CTypeFacilityFAQ({ event }: CTypeFacilityFAQProps) {
  return (
    <Stack gap="16px" padding="0px 0px 10px">
      <Box className="subtitle_18_b">자주 묻는 질문 FAQ</Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Stack flexDirection="row" gap="5px">
            <ColorIcon
              name="downArrow"
              size={16}
              style={{ marginTop: "2px" }}
              color={colors.gray600}
            />
            <Box className="subtitle_14_b gray900">
              Q. 장지 선택 시 고려사항이 있을까요?
            </Box>
          </Stack>
          <Box component="p" className="body_14_m gray700">
            먼저, 장지의 위치와 접근성을 고려하는 것이 좋습니다. 가족들이 자주
            찾기 편한 곳이어야 합니다. 또한 장지 시설의 규모도 중요합니다. 많은
            사람이 안장될 수 있는 대형 묘지를 선택하는 것이 좋습니다. 그리고
            비용도 중요한 고려 사항입니다. 예산 범위 내에서 최적의 선택을 하는
            것이 필요합니다. 가족의 종교이나 문화적 배경에 따라 선호하는 장지도
            다를 수 있습니다. 이 점도 고려하여 결정하는 것이 가장 좋다고
            생각합니다.
            <br />
            <br />
            마지막으로 이 모든 고려사항을 토대로 장지 전문가의 조언을 듣고
            최적의 장지를 선택하시는 것이 좋습니다.
          </Box>
        </Stack>
        <FullWidthButton
          href="tel:1666-9784"
          event={{ ...event, trigger_and_target: "fac_goi_call" }}
          text="전문가의 조언 받아보기"
        />
      </Stack>
    </Stack>
  );
}
