import { Box, Stack } from "@mui/material";
import React, { ReactNode } from "react";

import parlors from "../../../constants/parlors_detail.json";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";

export function ATypeParlorList({
  title,
  hasParlorsLengthInfo,
  sido,
  sigungu,
  CardViewType,
  event,
}: {
  sido?: string;
  sigungu?: string;
  title: string;
  hasParlorsLengthInfo?: boolean;
  CardViewType: (res: any) => ReactNode;
  event?: ClickEventType;
}) {
  const parlorCardItems = sigungu
    ? parlors.filter((p) => String(p.sigungu) === String(sigungu))
    : parlors.filter((p) => String(p.sido) === String(sido));

  const onClickEvent = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Stack
      sx={{
        padding: "16px",
      }}
      onClick={onClickEvent}
    >
      <Box component="h3" mb="4px" className="subtitle_16_b">
        {title}
      </Box>
      {hasParlorsLengthInfo && (
        <Box component="p" className="caption_12_r" mb="4px">
          {parlorCardItems.length}개의 장례식장이 있습니다.
        </Box>
      )}
      <Box marginTop="6px" />
      <Stack component="ul" gap="16px">
        {parlorCardItems.map((res) => CardViewType(res))}
      </Stack>
    </Stack>
  );
}
