import { 가족장 } from "./가족장";
import { 무빈소장 } from "./무빈소장";
import { 일반장 } from "./일반장";
import { VIP장 } from "./VIP장";

interface Detail {
  key: "인력" | "용품" | "차량";
  contents: {
    src: string;
    title: string;
    price: number;
    discountPrice?: number | string;
    descriptions: string;
  }[];
}
interface Step {
  src: string;
  title: string;
  description: string;
}

export interface ServiceDetails {
  type: "VIP장" | "일반장" | "가족장" | "무빈소장";
  description: string;
  recommendationText: string;
  정상가: number;
  가격: number;
  조문객: string;
  빈소: string;
  접객도우미: string;
  "장례 용품": string;
  "장의 차량": number;
  details: Detail[];
  steps: [Step[], Step[], Step[]];
  deductions: string[];
  extra: string[];
}

export const SERVICE_DETAILS: Record<ServiceDetails["type"], ServiceDetails> = {
  VIP장,
  일반장,
  가족장,
  무빈소장,
};
