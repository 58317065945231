import { Box, Stack } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

import parlors from "../../../constants/parlors_detail.json";

export function BTypeParlorList({
  title,
  binsoAveragePrice,
  sido,
  sigungu,
  CardViewType,
}: {
  sido?: string;
  sigungu?: string;
  title: string;
  binsoAveragePrice?: boolean;
  CardViewType: (res: any) => ReactNode;
}) {
  const parlorCardItems = sigungu
    ? parlors.filter((p) => String(p.sigungu) === String(sigungu))
    : parlors.filter((p) => String(p.sido) === String(sido));

  const [averagePrice, setaveragePrice] = useState(0);

  useEffect(() => {
    let price = 0;
    let binsoFullLength = 0;
    parlorCardItems.map((res) => {
      for (let i = 0; i < res.binsos.length - 1; i++) {
        price = price + Number(res.binsos[i].charge);
        binsoFullLength = binsoFullLength + 1;
      }
    });
    setaveragePrice(price / binsoFullLength);
  }, []);

  return (
    <Stack
      sx={{
        padding: "16px",
      }}
    >
      <Box component="h3" mb="4px" className="subtitle_18_sb" textAlign="center">
        {title}
      </Box>
      {binsoAveragePrice && (
        <Box component="p" className="caption_12_r" mb="4px" textAlign="center">
          전체 빈소 평균 비용(1일) : {Math.round(averagePrice).toLocaleString()}원
        </Box>
      )}
      <Box marginTop="6px" />
      <Stack component="ul" gap="16px">
        {parlorCardItems.map((res) => CardViewType(res))}
      </Stack>
    </Stack>
  );
}
