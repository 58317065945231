import React from "react";
import { ParlorDetailProps } from "../../../types";
import { Stack } from "@mui/material";
import { ColorSystemProps } from "../../../constants";

import { Image } from "../../common/Image";

export function FTypeMainHead({ defaultParlor }: { defaultParlor: ParlorDetailProps }) {
  return (
    <Stack position="relative" height="180px">
      <Stack position="relative" width="100%" height="100%" zIndex={2} padding="60px 20px 0px">
        <Stack alignItems="center">
          <h1 className="body_14_r white">{defaultParlor.companyname}</h1>
          <h2 className="head_24_b white">{defaultParlor.companyname}</h2>
        </Stack>
      </Stack>
      <Stack
        width="100%"
        overflow="hidden"
        position="absolute"
        height="180px"
        zIndex={1}
        style={{
          background: "linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%)",
        }}
      ></Stack>
      <Stack
        width="100%"
        height="180px"
        overflow="hidden"
        position="absolute"
        zIndex={-1}
        style={{}}
      >
        <Image src={defaultParlor.images[0].url} style={{ scale: "110%" }} />
      </Stack>
    </Stack>
  );
}
