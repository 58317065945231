export const breakpoints = {
  sm: "360px",
  md: "768px",
  lg: "1024px",
  xl: "1200px",
};

export const MQ = {
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`,
};
