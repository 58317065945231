import styled from "@emotion/styled";
import { colors } from "../../styles";

export const Spinner = styled.div<{
  spinnerColor?: string;
  backgroundColor?: string;
}>`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 4px solid ${(props) => props.spinnerColor ?? colors.white};
  border-top-color: ${(props) => props.backgroundColor ?? colors.black};
  animation: 1.5s spin infinite linear;
`;

export const GraySpinner = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid var(--gray300);
  border-top-color: var(--white);
  animation: 1.5s spin infinite linear;
`;
