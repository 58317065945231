import React from "react";
import { Box, Stack } from "@mui/material";

export function BTypeFacilityFAQ() {
  return (
    <Stack gap="16px" padding="40px 20px">
      <Box className="subtitle_18_sb" textAlign="center">
        자주 묻는 질문
      </Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Box className="subtitle_14_b gray900">
            Q. 시설을 이용 시 관내/관외에 따라 비용이 다른가요?
          </Box>
          <Box component="p" className="body_14_m gray700">
            공설 장지의 경우 관내/관외 거주 유/무에 따라 비용이 다릅니다. 보통
            관내보다 관외가 비싼 경우가 많습니다. 사설인 경우 기본적으로
            관내/관외에 따른 비용의 차이는 없는 것으로 이해하셔도 좋습니다.
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
