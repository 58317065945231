export const facilityDetailTypeName = (type: string, companyname: string) => {
  if (type === "Cemetery") {
    return "공원묘원";
  }
  if (type === "NaturalBurial") {
    if (companyname.includes("수목")) {
      return "수목장";
    } else {
      return "자연장";
    }
  }
  if (type === "Charnel") {
    return "봉안당";
  }
  return "장지";
};
