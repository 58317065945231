import { Box, Stack } from "@mui/material";
import React from "react";

import { ColorIcon } from "../../common";
import { colors } from "../../../styles";

export function DTypeFacilityFAQ() {
  return (
    <Stack gap="16px" padding="0px 0px 10px">
      <Box className="subtitle_18_b">자주 묻는 질문 FAQ</Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Stack flexDirection="row" gap="5px">
            <ColorIcon
              name="downArrow"
              size={16}
              style={{ marginTop: "2px" }}
              color={colors.gray600}
            />
            <Box className="subtitle_14_b gray900">
              장지 비용은 얼마 정도 발생하나요?
            </Box>
          </Stack>
          <Box component="p" className="body_14_m gray700">
            장지 비용은 고인의 상황과 해당 장지의 위치, 시설의 특정 조건에 따라
            무료 혹은 몇백만 원의 비용이 발생할 수 있습니다. 즉 정확한 가격을
            특정할 수 없습니다.
            <br />
            <br />
            희망하시는 장지와 유가족 분들의 상황을 토대로 장지 컨설팅을 받아보실
            것을 권장 드립니다.
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
