import { Box, Stack } from "@mui/material";
import React from "react";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { colors } from "../../../styles";
import { ColorSystemProps } from "../../../constants";

export function CTypeBeneficiaryCTA({ mainColor }: { mainColor: ColorSystemProps }) {
  return (
    <Stack gap="8px">
      <h2 className="subtitle_18_b" style={{ textAlign: "center" }}>
        기초생활수급자 혜택 4가지
      </h2>
      <Stack padding="20px" style={{ background: mainColor.c100 }} alignItems="center">
        <Stack gap="20px">
          <Stack className="button_16_sb" flexDirection="row" alignItems="center" gap="7px">
            <CircleNumber mainColor={mainColor} num={1} />
            <Box component="p">장제급여 80만원 지원</Box>
          </Stack>
          <Stack className="button_16_sb" flexDirection="row" alignItems="center" gap="7px">
            <CircleNumber mainColor={mainColor} num={2} />
            <Box component="p">전국 화장시설 화장 비용 무료</Box>
          </Stack>
          <Stack className="button_16_sb" flexDirection="row" alignItems="center" gap="7px">
            <CircleNumber mainColor={mainColor} num={3} />
            <Box component="p">공설 장지 이용료 무료</Box>
          </Stack>
          <Stack className="button_16_sb" flexDirection="row" alignItems="center" gap="7px">
            <CircleNumber mainColor={mainColor} num={4} />
            <Box component="p">공설 장례식장 무료</Box>
          </Stack>
        </Stack>
      </Stack>
      <Box component="p" padding="12px 0 0px" className="body_16_r">
        기초생활수급자 분들은 상기한 4가지 혜택을 받을 수 있습니다.{" "}
        <b className="subtitle_16_b">
          이해가 어려운 부분이나 더 자세한 정보를 원하신다면, 즉시 상담을 신청해 주세요.
        </b>
      </Box>
    </Stack>
  );
}

function CircleNumber({ num, mainColor }: { num: number; mainColor: ColorSystemProps }) {
  return (
    <Box
      className="white button_16_sb"
      style={{
        width: "24px",
        height: "24px",
        background: mainColor.c300,
        textAlign: "center",
        lineHeight: "26px",
        borderRadius: "50%",
      }}
    >
      {num}
    </Box>
  );
}
