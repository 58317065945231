import { Box, Stack } from "@mui/material";
import React from "react";
import { customEvent, getCdnImageSrc } from "../../../utils";
import { ClickEventType } from "../../../types";

export function DTypeCardView({
  imgSrc,
  href,
  name,
  address,
  descriptions,
  event,
}: {
  imgSrc: string;
  href: string;
  address: string;
  name: string;
  descriptions: Array<string>;
  event?: ClickEventType;
}) {
  const onClickEvent = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Stack
      component="a"
      href={href}
      flexDirection="row"
      gap="5px"
      height="140px"
      style={{ background: "white" }}
      boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.12)"
      borderRadius="5px"
      onClick={onClickEvent}
    >
      <Stack padding="16px" gap="2px" width="100%">
        <Box component="h3" className="subtitle_16_b gray900">
          {name}
        </Box>

        <Box component="p" className="body_14_m gray700">
          {address}
        </Box>

        {descriptions.map((description: string) => {
          return (
            <Stack
              justifyContent="flex-end"
              height="100%"
              className="button_16_sb"
            >
              {description}
            </Stack>
          );
        })}
      </Stack>
      <Box
        position="relative"
        sx={{
          width: "92px",
          height: "124px",
          margin: "8px 12px",
          overflow: "hidden",
          flexShrink: 0,
          borderRadius: "5px",
        }}
      >
        <img
          src={
            imgSrc
              ? imgSrc
              : getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
          }
          style={{ position: "absolute", height: "100%" }}
          alt={name}
        />
      </Box>
    </Stack>
  );
}
