import { breakpoints } from "../styles";

export const getCdnImageSrc = (
  name: string,
  resize?: { w: number; h: number }
) => {
  const imgSrc = `https://goifuneral.mo.cloudinary.net/${name}`;

  if (typeof window === "undefined") return imgSrc;

  if (window.innerWidth < Number(breakpoints.md.replace("px", ""))) {
    if (resize) {
      return imgSrc + `?tx=c_fit,w_${resize.w},h_${resize.h}`;
    } else {
      return imgSrc;
    }
  } else {
    return imgSrc;
  }
};
