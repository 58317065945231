import { Stack } from "@mui/material";

import React, { ReactNode } from "react";
import { AmenityObject } from "../../../types";
import { ColorIcon } from "../../common";
import { colors } from "../../../styles";

const LabelledIcon = ({ width, children }: { width: string; children: ReactNode }) => {
  return (
    <Stack flexDirection="row" width={width} className="body2" marginBottom="20px">
      {children}
    </Stack>
  );
};

const Amenity = ({ amenities }: { amenities: AmenityObject }) => {
  return (
    <Stack>
      <Stack flexDirection="row">
        <LabelledIcon width="40%">
          <ColorIcon
            name="funeral_restaurant"
            size={24}
            color={amenities.has_mealroom ? colors.warmGray700 : colors.gray100}
          />
          <span className={amenities.has_mealroom ? "gray700" : "gray100"}>식당</span>
        </LabelledIcon>
        <LabelledIcon width="60%">
          <ColorIcon
            name="funeral_waiting_room"
            size={24}
            color={amenities.has_waitroom ? colors.warmGray700 : colors.gray100}
          />
          <span className={amenities.has_waitroom ? "gray700" : "gray100"}>유족대기실</span>
        </LabelledIcon>
      </Stack>
      <Stack flexDirection="row">
        <LabelledIcon width="40%">
          <ColorIcon
            name="funeral_store"
            size={24}
            color={amenities.has_super ? colors.warmGray700 : colors.gray100}
          />
          <span className={amenities.has_super ? "gray700" : "gray100"}>매점</span>
        </LabelledIcon>
        <LabelledIcon width="60%">
          <ColorIcon
            name="funeral_disabled_facility"
            size={24}
            color={amenities.has_facilities_for_impaired ? colors.warmGray700 : colors.gray100}
          />
          <span className={amenities.has_facilities_for_impaired ? "gray700" : "gray100"}>
            장애인편의시설
          </span>
        </LabelledIcon>
      </Stack>
      <LabelledIcon width="100%">
        <ColorIcon
          name="funeral_parking_slot"
          size={24}
          color={amenities.has_parkinglot ? colors.warmGray700 : colors.gray100}
        />
        <span className={amenities.has_parkinglot ? "gray700" : "gray100"}>
          주차가능대수{" "}
          <span className={amenities.has_parkinglot ? "green450" : "gray100"}>
            {amenities.parkcnt}
          </span>
          대
        </span>
      </LabelledIcon>
    </Stack>
  );
};

export default Amenity;
