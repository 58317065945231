import React from "react";
import Amenity from "./Amenity";
import { ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";

export function ATypeAmenity({ defaultParlor }: { defaultParlor: ParlorDetailProps }) {
  return (
    <Stack padding="20px 20px 24px">
      <Box marginBottom="14px" className="button2">
        편의시설
      </Box>

      <Amenity amenities={defaultParlor.amenities} />
    </Stack>
  );
}
