import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { numberToLocalString } from "../../../utils";

import { colors } from "../../../styles";

import { FullWidthButton } from "../../common/Buttons/FullWidthButton";

export function DTypeBinsoPriceSummary({
  defaultParlor,
  mainColor,
  event,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const binsos = defaultParlor.binsos;
  const binsoLength = binsos.length - 1;
  const firstBinso = binsos[0];
  const lastBinso = binsos[binsoLength];

  const parsePriceToTenThousand = (price: number) => {
    return numberToLocalString(Math.round(price / 10000));
  };

  return (
    <Stack gap="6px">
      <Box className="subtitle_18_b" textAlign="center" component="h3">
        {defaultParlor.companyname} 비용 안내
      </Box>
      <Stack>
        <Box className="body_14_m" component="p">
          {defaultParlor.companyname}의 접객실 및 빈소는{" "}
          {binsos.map((res) => res.pyeong + ", ")}
          평으로 구성되어 있습니다. 조문객 수에 따른 적정 빈소는 아래와
          같습니다.
        </Box>
        <Box className="body_16_m" padding="20px 0" textAlign="center">
          조문객 100명 이하 : 40~50평 이하 추천
          <br />
          조문객 200명 이상 : 60~70평 이상 추천
        </Box>
      </Stack>

      <TableContainer
        sx={{
          padding: "20px 0 40px",
          ["th"]: { background: mainColor.c300, color: "white" },
          ["td, tr, th"]: { textAlign: "center", border: "none" },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderRadius: "10px 0 0 0" }}
                className="subtitle_14_b"
              >
                면적
              </TableCell>
              <TableCell
                style={{ borderRadius: "0 10px 0 0" }}
                className="subtitle_14_b"
              >
                빈소 가격(1일)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {binsos.map((res, i) => {
              return (
                <TableRow
                  style={{
                    background: i % 2 === 1 ? colors.gray50 : "",
                  }}
                >
                  <TableCell
                    style={{
                      borderRadius: i === binsos.length - 1 ? "0 0 0 10px" : "",
                    }}
                    className="subtitle_14_sb"
                  >
                    {res.pyeong}평
                  </TableCell>
                  <TableCell
                    style={{
                      borderRadius: i === binsos.length - 1 ? "0 0 10px 0" : "",
                    }}
                    className="subtitle_14_sb"
                  >
                    {numberToLocalString(res.charge)}원
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack gap="8px" padding="20px 0">
        <Box className="subtitle_14_b gray900">
          Q.조문객이 0명인 경우, 빈소를 세우지 않으면 장례식장 이용료는
          무료인가요?
        </Box>

        <Box component="p" className="body_14_m gray700">
          아닙니다.
          <br />
          장례식장 이용료는 빈소나 접객실 외에도 여러 요소로 구성됩니다. 비용에
          대해 더 알고 싶으시다면 전문가와 상담하는 것이 좋습니다. 모든
          장례식장에서 빈소 없이 서비스를 제공하는 것은 아니므로, 구체적인
          상황에 따라 상담이 필요할 수 있습니다.
        </Box>
        <Stack marginTop="20px">
          <FullWidthButton
            backgroundColor={mainColor.c400}
            text="전문가에게 상담 받아보기"
            href={GOI_WEB_URL + "/match/request/"}
            event={event}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
