import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import { ColorIcon } from "../../common/Icons";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { colors } from "../../../styles";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";

export function CTypeMainHead({
  title,
  sidoName,
  sigunguName,
  managed_type,
  binsosLength,
  defaultParlor,
  mainColor,
  isRegion,
  isHwajang,
  isJangji,
  event,
}: {
  title: string;
  sidoName?: string;
  sigunguName?: string;
  binsosLength?: number;
  managed_type?: string;
  defaultParlor: ParlorDetailProps;
  isRegion?: boolean;
  isHwajang?: boolean;
  isJangji?: boolean;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <Stack position="relative" padding="20px">
      <h1 className="subtitle_21_b ">{title}</h1>

      <Stack gap="6px" marginTop="13px">
        <Stack flexDirection="row" gap="6px">
          <ColorIcon
            name="icon-location-fill"
            size={20}
            color={colors.gray300}
          />
          <Box>
            {isRegion
              ? sidoName +
                (sigunguName ? " " + sigunguName : "") +
                "지역 장례식장 추천 및 비용 안내"
              : defaultParlor.address}
          </Box>
        </Stack>
        <Stack flexDirection="row" gap="6px">
          <ColorIcon name="timer" size={20} color={colors.gray300} />
          <Box>00:00 ~ 24:00</Box>
        </Stack>
      </Stack>

      <Box
        width="100%"
        height="1px"
        style={{ background: colors.gray300 }}
        margin="9px 0"
      />

      <FullWidthButton
        backgroundColor={mainColor.c400}
        text="장례 견적 알아보기"
        href={GOI_WEB_URL + "/match/request/"}
        event={event}
      />
    </Stack>
  );
}
