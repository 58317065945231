import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ParlorDetailProps } from "../../../../types";
import { ColorSystemProps } from "../../../../constants";
import { colors } from "../../../../styles";
import { numberToLocalString } from "../../../../utils";

export function ATypePriceSummary({
  firstParlor,
  secondParlor,
  mainColor,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  return (
    <Stack padding="40px 20px 20px">
      <h3 className="subtitle_18_b">비용 (시설 이용료)</h3>
      <Stack>
        <Stack className="body_16_m" padding="12px 0 40px">
          <Box className="subtitle_16_b gray900">들어가기 전에 잠깐!</Box>
          <p>
            장례식장 시설 이용료는 크게 공간 사용료, 음식 비용, 제단꽃 비용으로 구성됩니다. 이
            글에서는 가장 주요한 비용인 공간 사용료 중에서도 빈소 사용료에 대해 알려드린다는 점
            안내드립니다.
          </p>
        </Stack>
        <Stack className="body_16_m">
          <h3 className="subtitle_16_b gray900">{firstParlor.companyname} 비용</h3>
          <TableContainer
            sx={{
              padding: "8px 0 40px",
              ["td, tr, th"]: { textAlign: "center", border: "none" },
              ["th"]: { background: mainColor.c100 },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="subtitle_14_sb">평수</TableCell>
                  <TableCell className="subtitle_14_sb">비용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firstParlor.binsos.map((res, i) => {
                  return (
                    <TableRow
                      key={i + firstParlor.companyname + "row"}
                      sx={{ background: i % 2 === 1 ? colors.gray50 : "" }}
                    >
                      <TableCell className="subtitle_14_sb">{res.pyeong}평</TableCell>
                      <TableCell className="subtitle_14_sb">
                        {numberToLocalString(res.charge)}원
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack className="body_16_m">
          <h3 className="subtitle_16_b gray900">{secondParlor.companyname} 비용</h3>
          <TableContainer
            sx={{
              padding: "8px 0 20px",
              ["td, tr, th"]: { textAlign: "center", border: "none" },
              ["th"]: { background: mainColor.c100 },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="subtitle_14_sb">평수</TableCell>
                  <TableCell className="subtitle_14_sb">비용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {secondParlor.binsos.map((res, i) => {
                  return (
                    <TableRow
                      key={i + secondParlor.companyname + "row"}
                      sx={{ background: i % 2 === 1 ? colors.gray50 : "" }}
                    >
                      <TableCell className="subtitle_14_sb">{res.pyeong}평</TableCell>
                      <TableCell className="subtitle_14_sb">
                        {numberToLocalString(res.charge)}원
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </Stack>
  );
}
