import { Stack } from "@mui/material";
import React from "react";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";

import { ColorSystemProps } from "../../../constants";
import { CTypeBeneficiaryCTA } from "./CTypeBeneficiaryCTA";
import { ClickEventType } from "../../../types";

export function CTypeBeneficiaryHead({
  titleSido,
  mainColor,
  event,
}: {
  titleSido: string;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <>
      <Stack padding="16px" style={{ background: mainColor.c100 }}>
        <Stack gap="6px" marginBottom="10px" alignItems="center">
          <span className="subtitle_18_b" style={{ color: mainColor.c300 }}>
            {titleSido}
          </span>
          <h1 className="subtitle_21_sb" style={{ textAlign: "center" }}>
            기초생활수급자 장례114
          </h1>
          <Stack width="100%" marginTop="10px">
            <FullWidthButton
              text="상담하기"
              href="tel:1666-9784"
              backgroundColor={mainColor.c400}
              event={event}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        margin="20px 12px"
        padding="24px 16px 10px"
        style={{
          background: "white",
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <CTypeBeneficiaryCTA mainColor={mainColor} />
        <Stack padding="20px">
          <FullWidthButton
            text="바로 상담하기"
            href="tel:1666-9784"
            backgroundColor={mainColor.c400}
            event={event}
          />
        </Stack>
      </Stack>
    </>
  );
}
