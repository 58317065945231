import { Box, Stack } from "@mui/material";
import { ParlorDetailProps } from "../../../../types";

import React from "react";

export function ATypeLocation({
  firstParlor,
  secondParlor,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
}) {
  return (
    <Stack gap="8px" padding="20px">
      <h3 className="subtitle_16_b gray900">위치</h3>
      <Box className="subtitle_16_sb">{firstParlor.companyname}</Box>
      <p className="body_16_m gray900">{firstParlor.address}</p>
      <Box marginTop="12px" className="subtitle_16_sb">
        {secondParlor.companyname}
      </Box>
      <p className="body_16_m gray900">{secondParlor.address}</p>
    </Stack>
  );
}
