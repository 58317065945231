import { Box, Stack } from "@mui/material";
import React from "react";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { colors } from "../../../styles";
import { ColorSystemProps } from "../../../constants";
import { ClickEventType } from "../../../types";

export function ATypeBeneficiaryCTA({
  mainColor,
  event,
}: {
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <Stack>
      <h3 className="subtitle_16_sb">기초생활수급자 혜택 4가지</h3>
      <Stack
        padding="20px"
        style={{ background: mainColor.c100 }}
        alignItems="center"
      >
        <Stack component="ul" gap="20px">
          <Stack
            className="button_16_sb"
            component="li"
            flexDirection="row"
            alignItems="center"
            gap="7px"
          >
            <CircleNumber mainColor={mainColor} num={1} />
            공설 장례식장 무료
          </Stack>
          <Stack
            className="button_16_sb"
            component="li"
            flexDirection="row"
            alignItems="center"
            gap="7px"
          >
            <CircleNumber mainColor={mainColor} num={2} />
            전국 화장시설 화장 비용 무료
          </Stack>
          <Stack
            className="button_16_sb"
            component="li"
            flexDirection="row"
            alignItems="center"
            gap="7px"
          >
            <CircleNumber mainColor={mainColor} num={3} />
            공설 장지 안치 비용 무료
          </Stack>
          <Stack
            className="button_16_sb"
            component="li"
            flexDirection="row"
            alignItems="center"
            gap="7px"
          >
            <CircleNumber mainColor={mainColor} num={4} />
            지자체 장제비 신청 시 80만원
          </Stack>
        </Stack>
      </Stack>
      <Box component="p" padding="12px 0 40px" className="body_16_r">
        기초생활수급자(의료, 생계)라면 위의 4가지 혜택을 받을 수 있습니다.{" "}
        <b className="subtitle_16_b">
          만약 이해하기 어려운 점이 있거나 더 자세하게 알고 싶은 것이 있다면,
          바로 상담을 진행하세요.
        </b>
      </Box>
      <FullWidthButton
        text="바로 상담하기"
        href="tel:1666-9784"
        backgroundColor={mainColor.c400}
        event={event}
      />
    </Stack>
  );
}

function CircleNumber({
  num,
  mainColor,
}: {
  num: number;
  mainColor: ColorSystemProps;
}) {
  return (
    <Box
      className="white button_16_sb"
      style={{
        width: "24px",
        height: "24px",
        background: mainColor.c300,
        textAlign: "center",
        lineHeight: "26px",
        borderRadius: "50%",
      }}
    >
      {num}
    </Box>
  );
}
