import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ParlorDetailProps } from "../../../../types";
import { ColorSystemProps } from "../../../../constants";
import { colors } from "../../../../styles";
import { numberToLocalString } from "../../../../utils";
import { TableRestaurant } from "@mui/icons-material";
import { TableCaption } from "@chakra-ui/react";

export function CTypePriceSummary({
  firstParlor,
  secondParlor,
  mainColor,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  return (
    <Stack padding="40px 20px 20px" gap="10px">
      <h3 className="subtitle_18_b">빈소 대여료</h3>

      <Stack>
        <Stack
          className="subtitle_16_b"
          flexDirection="row"
          gap="6px"
          justifyContent="space-around"
        >
          <Box textAlign="center" padding="8px 12px">
            {firstParlor.companyname}
          </Box>
          <Box textAlign="center" padding="8px 12px">
            {secondParlor.companyname}
          </Box>
        </Stack>
        <Stack
          className="subtitle_16_sb white"
          flexDirection="row"
          gap="6px"
          justifyContent="space-around"
          borderRadius="10px 10px 0 0"
          style={{ background: mainColor.c300 }}
        >
          <Box textAlign="center" padding="8px 12px">
            빈소평수
          </Box>
          <Box textAlign="center" padding="8px 12px">
            빈소이용료
          </Box>
          <Box textAlign="center" padding="8px 12px">
            빈소평수
          </Box>
          <Box textAlign="center" padding="8px 12px">
            빈소이용료
          </Box>
        </Stack>
        <Stack className="body_16_m" flexDirection="row" gap="6px" justifyContent="space-around">
          <Stack width="100%">
            {firstParlor.binsos.map((res, i) => {
              return (
                <Stack
                  flexDirection="row"
                  justifyContent="space-around"
                  style={{
                    background: i % 2 === 1 ? colors.gray50 : "",
                  }}
                  key={i + firstParlor.companyname + "row"}
                >
                  <Box textAlign="center" padding="8px 12px" className="subtitle_14_sb">
                    {res.pyeong}평
                  </Box>
                  <Box textAlign="center" padding="8px 12px" className="subtitle_14_sb">
                    {Math.round(res.charge / 10000)}만원
                  </Box>
                </Stack>
              );
            })}
          </Stack>
          <Stack width="100%">
            {secondParlor.binsos.map((res, i) => {
              return (
                <Stack
                  flexDirection="row"
                  justifyContent="space-around"
                  key={i + secondParlor.companyname + "row"}
                  style={{
                    background: i % 2 === 1 ? colors.gray50 : "",
                  }}
                >
                  <Box textAlign="center" padding="8px 12px" className="subtitle_14_sb">
                    {res.pyeong}평
                  </Box>
                  <Box textAlign="center" padding="8px 12px" className="subtitle_14_sb">
                    {Math.round(res.charge / 10000)}만원
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        <Stack className="body_16_m" padding="20px 0 40px">
          <Box className="subtitle_18_sb gray900">빈소 대여료 안내</Box>
          <p>
            {firstParlor.companyname}은{" "}
            <b style={{ color: mainColor.c400 }}>{firstParlor.managed_type} 장례식장</b>으로
            구분되고, {secondParlor.companyname}은{" "}
            <b style={{ color: mainColor.c400 }}>{secondParlor.managed_type} 장례식장</b>으로
            구분됩니다. 장례식장에서 빈소를 대여할 때의 비용은 그 종류에 따라 달라질 수 있으며, 이
            비용은 시설의 수준에도 영향을 줍니다. 대학병원, 전문, 그리고 공설 장례식장 순으로
            가격대가 높아지곤 합니다.
          </p>
        </Stack>
      </Stack>
    </Stack>
  );
}
