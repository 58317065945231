import React, { useMemo } from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import {
  ColorSystemProps,
  FUNERAL_SIDO,
  FUNERAL_SIGUNGU,
  GOI_WEB_URL,
} from "../../../constants";
import { FitWidthSmallButton } from "../../common/Buttons/FitWidthSmallButton";
import { numberToLocalString } from "../../../utils";
import { Image } from "../../common/Image";

export function ETypeMainHead({
  title,
  sidoName,
  sigunguName,
  managed_type,
  parlorsLength,
  binsosLength,
  defaultParlor,
  address,
  isHwajang,
  isJangji,
  mainColor,
  event,
}: {
  title: string;
  sidoName?: string;
  sigunguName?: string;
  binsosLength?: number;
  parlorsLength?: number;
  managed_type?: string;
  defaultParlor: ParlorDetailProps;
  address?: string;
  isHwajang?: boolean;
  isJangji?: boolean;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const facilityName = useMemo(() => {
    if (isHwajang) {
      return "화장 시설";
    } else if (isJangji) {
      return "공설 장지";
    } else {
      return "장례식장";
    }
  }, [isHwajang, isJangji]);
  return (
    <Stack alignItems="center">
      <Box width="100%" height="180px" overflow="hidden">
        {defaultParlor.images[0].url && (
          <Image
            src={defaultParlor.images[0].url}
            width="100%"
            style={{ marginTop: "-50px" }}
          />
        )}
      </Box>

      <Stack gap="6px" padding="16px 0 23px" alignItems="center" width="100%">
        <h1 className="subtitle_21_b" style={{ padding: "0 20px" }}>
          {title}
        </h1>
        <Box component="p">
          {parlorsLength ? parlorsLength + "개의" : ""}{" "}
          {FUNERAL_SIDO.get(Number(defaultParlor.sido))}{" "}
          {FUNERAL_SIGUNGU.get(Number(defaultParlor.sido))?.get(
            Number(defaultParlor.sigungu)
          )}
          의 {managed_type ? managed_type : ""} {facilityName}
        </Box>
        {defaultParlor.binsos && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            padding="16px 20px"
            gap="4px"
            width="100%"
            alignItems="center"
          >
            <Stack flexDirection="row" gap="8px">
              <Box className="subtitle_14_sb" style={{ color: mainColor.c300 }}>
                빈소 {defaultParlor.binsos[0].pyeong}평형
              </Box>
              <Box className="subtitle_14_b">
                {numberToLocalString(defaultParlor.binsos[0].charge)}원 부터~
              </Box>
            </Stack>
            <FitWidthSmallButton
              backgroundColor={mainColor.c300}
              text="장례 견적 알아보기"
              href={GOI_WEB_URL + "/match/request/"}
              event={event}
            />
          </Stack>
        )}
        {(isHwajang || isJangji) && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            padding="16px 20px"
            gap="4px"
            width="100%"
            alignItems="center"
          >
            <Stack flexDirection="row" gap="8px">
              <Box className="subtitle_14_sb" style={{ color: mainColor.c300 }}>
                관내 기초생활 수급자
              </Box>
              <Box className="subtitle_14_b">무료</Box>
            </Stack>
            <FitWidthSmallButton
              backgroundColor={mainColor.c300}
              text="문의하기"
              href={GOI_WEB_URL + "/match/request/"}
              event={
                {
                  ...event,
                  trigger_and_target: "uv_mth_req_btom",
                } as ClickEventType
              }
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
