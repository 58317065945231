import { Global, css } from "@emotion/react";
import { theme, ThemeType } from "../theme/theme";

const makeVariables = (theme: ThemeType) => {
  const themeVariables: string[] = [];
  Object.entries(theme).forEach((section) => {
    const [, sectionValue] = section;

    Object.entries(sectionValue).forEach(([key, value]) => {
      themeVariables.push(`--${key}: ${value}`);
    });
  });

  return themeVariables;
};

const style = css`
  :root {
    ${makeVariables(theme)}
  }

  ::-webkit-scrollbar {
    display: none;
  }

  html,
  body {
    font-family: "Pretendard Variable", -apple-system, BlinkMacSystemFont,
      system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    -webkit-font-smoothing: antialiased;
    ::-webkit-scrollbar {
      display: none;
    }
    [data-aos="fade-up-custom"] {
      opacity: 0;
      transform: translateY(25px);
      transition-property: transform, opacity;

      &.aos-animate {
        opacity: 1;
        transform: translateX(0);
      }
    }

    [data-aos="fade"] {
      opacity: 0;
      transition-property: opacity;

      &.aos-animate {
        opacity: 1;
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  .show-scrollbar {
    ::-webkit-scrollbar {
      display: block;
    }
  }

  -webkit-tap-highlight-color: transparent;

  .stop-dragging {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

export const GlobalStyle = () => {
  return <Global styles={style} />;
};
