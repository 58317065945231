import { Box, Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface ATypeFacilityListProps {
  title: string;
}

export function ATypeFacilityList({
  title,
  children,
}: PropsWithChildren<ATypeFacilityListProps>) {
  return (
    <Stack padding="16px">
      <Box component="h3" mb="4px" className="subtitle_18_sb">
        {title}
      </Box>
      <Box marginTop="6px" />
      <Stack component="ul" gap="16px">
        {children}
      </Stack>
    </Stack>
  );
}
