import React from "react";
import { Box, Stack, VStack } from "@chakra-ui/react";
import FileRequestModal from "./Modal/FileRequestModal";

import { useOverlay } from "@toss/use-overlay";

import { ReactNode, useMemo } from "react";
import { colors } from "../styles";
import { call, customEvent, getCdnImageSrc } from "../utils";
import { ColorIcon, HStack, Image } from "./common";
import { EventDomainType } from "../types";

interface StepGuideFooterProps {
  type?: string;
  text?: ReactNode;
  eventPath?: keyof typeof EventDomainType | string;
  src?: string;
  size?: string;
  maxWidth?: string;
  bottom?: string;
  right?: string;
  iconMarginLeft?: string;
  transform?: string;
}

function StepGuideFooter({
  type = "DEFAULT",
  eventPath,
  size = "44px",
  maxWidth = "600px",
  right = "",
  iconMarginLeft,
  transform = "",
  src = "/step-guide/initiation",
  bottom = "40px",
  text = (
    <>
      장례가 처음이신가요? 합리적인 장례,
      <br />
      장례식장과 상담하기 전 반드시 알아보세요.
    </>
  ),
}: StepGuideFooterProps) {
  const overlay = useOverlay();
  const typeToStyle = useMemo(() => {
    if (type === "DEFAULT") {
      return {
        background: colors.white,
        color: colors.gray800,
      };
    } else if (type === "DARK") {
      return {
        background: colors.gray800,
        color: colors.white,
      };
    } else {
      return {
        background: colors.white,
        color: colors.gray800,
      };
    }
  }, [type]);

  return (
    <Stack alignItems="center" width="100%" flexDirection="column">
      <Box
        style={{
          background: "#00000044",
          width: "100%",
          margin: 0,
          position: "fixed",
          bottom: 0,
          right: right,
          zIndex: 5,
          display: "flex",
          justifyContent: "center",
          transform: transform,
          boxShadow: "2px 10px 12px 10px #00000066",
        }}
      >
        <VStack width="100%" maxWidth={maxWidth} gap={0}>
          <HStack
            width="100%"
            background="#ffffffdd"
            borderBottom="1px solid #00000033"
            padding="0"
          >
            {/* <HStack
              as="button"
              background="none"
              width="50%"
              padding="12px 12px"
              className="body_14_m gray700"
              gap={6}
              alignItems="center"
              justifyContent="center"
              border="none"
              style={{
                borderRight: "1px solid #00000033",
              }}
              onClick={() => {
                customEvent({
                  domain: "common",
                  path: "footer",
                  trigger_and_target: "footer2_discount_chat",
                });
                window.open("http://pf.kakao.com/_xgFDaK/chat");
              }}
            >
              <Box textAlign="center">
                교육, 경찰, 소방
                <br />
                공무원 할인 문의 👨‍💼
              </Box>
              <ColorIcon
                name="arrow_right_small"
                size={24}
                color={typeToStyle.color}
                style={{ marginLeft: iconMarginLeft || "0px" }}
              />
            </HStack> */}

            <HStack
              as="button"
              border="none"
              background="none"
              width="100%"
              padding="12px 6px"
              className="body_14_m gray800"
              gap={12}
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                call("1666-9784");
                customEvent({
                  domain: "common",
                  path: "footer",
                  trigger_and_target: "footer3_call",
                });
              }}
            >
              <Box textAlign="center">
                장례 절차, 빈소 문의
                <br />
                임종 접수(24시간)
              </Box>
              <ColorIcon
                name="icon_call_mono"
                size={24}
                color={typeToStyle.color}
                style={{ marginLeft: iconMarginLeft || "0px" }}
              />
            </HStack>
          </HStack>

          <Stack
            as="button"
            flexDirection="row"
            onClick={() => {
              customEvent({
                domain: "common",
                path: "footer",
                trigger_and_target: "footer1_checklist_modal",
              });
              overlay.open(({ isOpen, close: onClose }) => {
                return (
                  <FileRequestModal
                    path={eventPath as string}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                );
              });
            }}
            width="100%"
            border="none"
            background="#ffffff"
            padding="12px 20px 12px 40px"
            alignItems="center"
            justifyContent="center"
            gap="12px"
          >
            <Image
              src={getCdnImageSrc(
                `platform/home/match-request-version/circle-guidebook.png`
              )}
              alt="step-guide"
              width={size}
              height={size}
            />
            <Box
              className="subtitle_14_b"
              textAlign="left"
              color={typeToStyle.color}
            >
              장례비용 예상 견적표,
              <br />
              임종준비 체크리스트 받아보기
            </Box>
            <ColorIcon
              name="icon-arrow-right-circle-mono"
              size={36}
              color={typeToStyle.color}
              style={{ marginLeft: iconMarginLeft || "0px" }}
            />
          </Stack>
        </VStack>
      </Box>
    </Stack>
  );
}

export default StepGuideFooter;
