import { Box, Stack } from "@mui/material";
import React from "react";

import facilities from "../../../constants/facilities_detail.json";
import { CTypeCardView } from "../CardView";
import { ColorSystemProps } from "../../../constants";
import { ClickEventType } from "../../../types";

interface BTypeFacilityListProps {
  title: string;
  defaultHref: string;
  mainColor: ColorSystemProps;
  event: ClickEventType;
  sido?: string;
  sigungu?: string;
}

export function BTypeFacilityList({
  title,
  sido,
  sigungu,
  mainColor,
  defaultHref,
  event,
}: BTypeFacilityListProps) {
  const facilityCardItems = sigungu
    ? facilities.filter((p) => String(p.sigungu) === String(sigungu))
    : facilities.filter((p) => String(p.sido) === String(sido));

  return (
    <Stack
      sx={{
        padding: "16px",
      }}
    >
      <Box
        component="h3"
        mb="4px"
        className="subtitle_18_sb"
        textAlign="center"
      >
        {title}
      </Box>

      <Box marginTop="6px" />
      <Stack component="ul" gap="16px">
        {facilityCardItems?.map((res) => {
          return (
            <CTypeCardView
              sigungu={res.sigungu}
              mainColor={mainColor}
              key={res.companyname}
              name={res.companyname}
              href={`${defaultHref}/${res.id}/`}
              sido={res.sido}
              imgSrc={res.images[0]?.url || ""}
              event={{ ...event }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
