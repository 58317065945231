import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { numberToLocalString } from "../../../utils";
import { colors } from "../../../styles";
import { BTypeCTA, DTypeCTA } from "../CTA";

export function CTypeBinsoPriceSummary({
  defaultParlor,
  mainColor,
  event,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const binsos = defaultParlor.binsos;
  const binsoLength = binsos.length - 1;
  const firstBinso = binsos[0];
  const lastBinso = binsos[binsoLength];

  const parsePriceToTenThousand = (price: number) => {
    return numberToLocalString(Math.round(price / 10000));
  };

  return (
    <Stack>
      <Box className="subtitle_18_b" textAlign="center" component="h3">
        {defaultParlor.companyname} 비용
      </Box>
      <Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          className="subtitle_14_b"
          padding="20px 0 8px"
        >
          <Box component="h4">빈소 예상 비용</Box>{" "}
          <Box>
            {parsePriceToTenThousand(firstBinso.charge)} ~{" "}
            {parsePriceToTenThousand(lastBinso.charge)}
            만원
          </Box>
        </Stack>
        <Box className="body_14_m" component="p">
          {defaultParlor.companyname}의 빈소는 {parsePriceToTenThousand(firstBinso.charge)}평에서{" "}
          {parsePriceToTenThousand(lastBinso.charge)}평 사이에 있으며, 최소 크기인{" "}
          {firstBinso.pyeong}
          평에서 3일간의 장례를 진행할 경우, 대략 {(firstBinso.charge * 2).toLocaleString()}
          원의 비용이 예상됩니다.
        </Box>
      </Stack>

      <TableContainer
        sx={{
          padding: "20px 0 40px",
          ["th"]: { background: mainColor.c300, color: "white" },
          ["td, tr, th"]: { textAlign: "center", border: "none" },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ borderRadius: "10px 0 0 0" }} className="subtitle_14_b">
                면적
              </TableCell>
              <TableCell style={{ borderRadius: "0 10px 0 0" }} className="subtitle_14_b">
                빈소 가격(1일)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {binsos.map((res, i) => {
              return (
                <TableRow
                  style={{
                    background: i % 2 === 1 ? colors.gray50 : "",
                  }}
                >
                  <TableCell
                    style={{
                      borderRadius: i === binsos.length - 1 ? "0 0 0 10px" : "",
                    }}
                    className="subtitle_14_sb"
                  >
                    {res.pyeong}평
                  </TableCell>
                  <TableCell
                    style={{
                      borderRadius: i === binsos.length - 1 ? "0 0 10px 0" : "",
                    }}
                    className="subtitle_14_sb"
                  >
                    {numberToLocalString(res.charge)}원
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <BTypeCTA mainColor={mainColor} event={event} /> */}
      <DTypeCTA
        mainColor={mainColor}
        event={event}
        companyname={defaultParlor.companyname}
        href={`${GOI_WEB_URL}/match/parlor-requests/${defaultParlor.id}/`}
      />
    </Stack>
  );
}
