import { Box, Stack } from "@mui/material";
import React from "react";
import { ColorSystemProps } from "../../../constants";
import { colors } from "../../../styles";
import { customEvent, getCdnImageSrc } from "../../../utils";
import { ColorIcon } from "../../common";
import { ClickEventType } from "../../../types";

export function BTypeCardView({
  imgSrc,
  href,
  name,
  descriptions,
  mainColor,
  hasOneDayBasis = true,
  arrowText = "비용 더 보기",
  event,
}: {
  imgSrc: string;
  href: string;
  name: string;
  descriptions: Array<string>;
  mainColor: ColorSystemProps;
  hasOneDayBasis?: boolean;
  arrowText?: string;
  event?: ClickEventType;
}) {
  const onClickEvent = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Box
      component="li"
      display="flex"
      flexDirection="column"
      gap="5px"
      sx={{
        listStyle: "none",
        background: "white",
        border: `1px solid ${colors.gray300}`,
      }}
      height="140px"
      onClick={onClickEvent}
    >
      <Stack component="a" href={href} flexDirection="row">
        <Box
          position="relative"
          sx={{
            width: "105px",
            height: "139px",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <img
            src={
              imgSrc
                ? imgSrc
                : getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
            }
            style={{ position: "absolute", height: "100%" }}
            alt={name}
          />
        </Box>
        <Stack padding="16px" gap="2px" width="100%" alignItems="flex-end">
          <Box component="h3" className="subtitle_16_b">
            {name}
          </Box>
          {descriptions.map((description: string) => {
            return (
              <Box component="p" className="body_14_m gray700">
                {description}
              </Box>
            );
          })}

          {hasOneDayBasis && (
            <Box
              style={{ background: mainColor.c100, color: mainColor.c400 }}
              borderRadius="5px"
              padding="2px 10px"
              className="body_14_m"
            >
              1일 기준
            </Box>
          )}

          <Stack
            flexDirection="row"
            gap="3px"
            alignItems="flex-end"
            color={mainColor.c300}
            height="100%"
          >
            <div className="button_16_sb">{arrowText}</div>
            <ColorIcon
              name="arrow_right_small"
              size={16}
              color={mainColor.c300}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
