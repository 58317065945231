import React, { ReactNode } from "react";
import { Box, Link, Stack } from "@mui/material";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { colors } from "../../../styles";
import { ColorIcon } from "../../common/Icons";

export function TotalParlorPriceCTA({
  region,
  mainColor,
  beforeSangjoPrice,
  afterSangjoPrice,
  description,
}: {
  region: any;
  beforeSangjoPrice?: number;
  afterSangjoPrice?: number;
  mainColor: ColorSystemProps;
  description: ReactNode;
}) {
  return (
    <>
      <Box
        mt="16px"
        component="h4"
        className="subtitle_18_b"
        style={{
          color: "#595959",
        }}
      >
        {region.name} 지역 장례식장 장례 총 예상 비용
      </Box>
      <Box mb="32px" style={{ background: colors.gray900, padding: "16px", borderRadius: "5px" }}>
        <Box mb="24px">
          {beforeSangjoPrice && (
            <Box
              mb="6px"
              typography="body2"
              padding="16px"
              textAlign="center"
              className="white"
              style={{ borderRadius: "5px", background: mainColor.c500 }}
            >
              총 비용 예상( <b>선불제 상조</b> 기준 ) : {beforeSangjoPrice.toLocaleString()}원
            </Box>
          )}
          {afterSangjoPrice && (
            <Box
              typography="body2"
              padding="16px"
              textAlign="center"
              style={{ borderRadius: "5px", color: "#ffffff" }}
            >
              총 비용 예상( <b className="orange500">후불제 상조</b> 기준 ) :{" "}
              {afterSangjoPrice.toLocaleString()}원
            </Box>
          )}
        </Box>

        <div className="white subtitle_16_b" style={{ textAlign: "center", marginBottom: "16px" }}>
          {description}
        </div>
        <Link style={{ textDecoration: "none" }} href={GOI_WEB_URL + "/match/request"}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="button"
            color={mainColor.c200}
          >
            <div>더 알아보기</div>
            <ColorIcon name="arrow_right_small" size={16} color={mainColor.c200} />
          </Stack>
        </Link>
      </Box>
    </>
  );
}
