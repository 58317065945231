import { Box, Stack } from "@mui/material";
import { Image, Spacing, colors, getCdnImageSrc } from "goi_common";
import React from "react";

interface BTypeParlorCompareProps {
  firstParlor: any;
  secondParlor: any;
  twiceParlor: any;
  index: number;
}

export const BTypeParlorCompare = ({
  firstParlor,
  secondParlor,
  twiceParlor,
  index,
}: BTypeParlorCompareProps) => {
  return (
    <Stack>
      <Stack>추천 조합 {index + 1}</Stack>
      <Spacing gap={30} />
      <Stack flexDirection="row" gap="10px" justifyContent="space-around">
        <Stack width="45%" gap="4px">
          <Stack>장례식장</Stack>
          <h2 className="subtitle_16_sb" style={{ color: colors.black }}>
            {firstParlor.companyname}
          </h2>
          <Box height="120px" width="100%" overflow="hidden" borderRadius="5px">
            <Image
              src={
                firstParlor.images[0].url ||
                getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
              }
              width="100%"
              height="100%"
              alt={firstParlor.companyname}
            />
          </Box>
          <Spacing gap={3} />
          <Box className="body_14_m gray600">
            {firstParlor.managed_type} 장례식장
          </Box>
          {/* <Box className="body_14_m gray600">
          빈소 유형: {firstParlor.binsos.length}개
        </Box> */}
          {/* <Box className="body_14_m gray600">주소: {firstParlor.address}</Box> */}
          {/* <Box className="body_14_m gray600">
          조문객 50명 기준 비용:{" "}
          {firstParlor.expected_charge_sum.가족장.toLocaleString()}원
        </Box> */}
        </Stack>

        <Stack width="45%" gap="4px">
          <Stack>화장장</Stack>
          <h2 className="subtitle_16_sb" style={{ color: colors.black }}>
            {secondParlor.companyname}
          </h2>
          <Box height="120px" width="100%" overflow="hidden" borderRadius="5px">
            <Image
              src={
                secondParlor.images[0].url ||
                getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
              }
              width="100%"
              height="100%"
              alt={secondParlor.companyname}
            />
          </Box>
          {/* <Box className="body_14_m gray600">
          {secondParlor.managed_type} 장례식장
        </Box> */}
          {/* <Box className="body_14_m gray600">
          빈소 유형: {secondParlor.binsos.length}개
        </Box> */}
          {/* <Box className="body_14_m gray600">주소: {secondParlor.address}</Box> */}
          {/* <Box className="body_14_m gray600">
          조문객 50명 기준 비용:{" "}
          {secondParlor.expected_charge_sum.가족장.toLocaleString()}원
        </Box> */}
        </Stack>

        <Stack width="45%" gap="4px">
          <Stack>장지</Stack>
          <h2 className="subtitle_16_sb" style={{ color: colors.black }}>
            {twiceParlor.companyname}
          </h2>
          <Box height="120px" width="100%" overflow="hidden" borderRadius="5px">
            <Image
              src={
                twiceParlor.images[0].url ||
                getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
              }
              width="100%"
              height="100%"
              alt={twiceParlor.companyname}
            />
          </Box>
          {/* <Box className="body_14_m gray600">
          {secondParlor.managed_type} 장례식장
        </Box> */}
          {/* <Box className="body_14_m gray600">
          빈소 유형: {secondParlor.binsos.length}개
        </Box> */}
          {/* <Box className="body_14_m gray600">주소: {secondParlor.address}</Box> */}
          {/* <Box className="body_14_m gray600">
          조문객 50명 기준 비용:{" "}
          {secondParlor.expected_charge_sum.가족장.toLocaleString()}원
        </Box> */}
        </Stack>
      </Stack>
    </Stack>
  );
};
