import { Box, Stack } from "@mui/material";
import React from "react";
import { MapLayout } from "./MapLayout";
import { DefaultFacility, ParlorDetailProps } from "../../../types";

interface BTypeMapProps {
  defaultParlor: ParlorDetailProps | DefaultFacility;
}

export function BTypeMap({ defaultParlor }: BTypeMapProps) {
  return (
    <Stack gap="8px">
      <Box component="h3" className="subtitle_18_sb" textAlign="center">
        {defaultParlor.companyname} 위치
      </Box>
      <MapLayout parlor={defaultParlor} />
    </Stack>
  );
}
