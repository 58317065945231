import { useEffect } from "react";
import { customEvent } from "../utils";
import { GOI_WEB_URL } from "../constants";

export const useTrackingGoiAnchorTag = () => {
  useEffect(() => {
    const findAnchorElement = (
      element: HTMLElement | null
    ): HTMLAnchorElement | null => {
      if (!element || element === document.body) return null;
      if (element instanceof HTMLAnchorElement) return element;
      return findAnchorElement(element.parentElement);
    };

    const handleClick = (event: MouseEvent) => {
      const anchorElement = findAnchorElement(event.target as HTMLElement);

      if (anchorElement?.href.includes(GOI_WEB_URL)) {
        customEvent({
          action: "move_to_goi",
        });
      }
    };

    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);
};
