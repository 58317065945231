import { Box, Stack } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React from "react";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";

import { ClickEventType, ParlorDetailProps } from "../../../types";

import { NewFitWidthButton } from "../../common/Buttons/NewFitWidthButton";
import { useOverlay } from "@toss/use-overlay";
import BinsoReserveModal from "../../Modal/BinsoReserveModal";

dayjs.extend(utc);
dayjs.extend(timezone);

export function CTypeBinsoRealTimeSummary({
  defaultParlor,
  mainColor,
  event,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event: ClickEventType;
}) {
  dayjs.locale("ko");
  const now = dayjs().tz("Asia/Seoul");

  const overlay = useOverlay();

  return (
    <Stack gap="14px" padding="0 20px">
      <Box className="subtitle_18_sb" textAlign="center">
        {defaultParlor.companyname} 실시간 빈소
      </Box>

      <Stack
        className="subtitle_14_b"
        flexDirection="row"
        justifyContent="space-between"
        padding="12px 40px"
        borderRadius="10px 10px 0 0"
        style={{ background: mainColor.c300, color: "white" }}
      >
        <Box component="h4">호실</Box>
        <Box component="h4">이름</Box>
        <Box component="h4">발인 날짜</Box>
      </Stack>

      <Stack alignItems="center" justifyContent="center" padding="67px 0" width="100%" gap="16px">
        <Box textAlign="center" component="p" className="body_14_m">
          실시간 빈소 상황을 불러오는 도중 <br />
          오류가 발생했습니다.
        </Box>
        <Stack gap="8px">
          <NewFitWidthButton
            text="남은 빈소 문의하기"
            size="medium"
            href="tel:1666-9784"
            backgroundColor={mainColor.c400}
            event={{ ...event, trigger_and_target: "goi_call" } as ClickEventType}
          />
          <NewFitWidthButton
            size="medium"
            backgroundColor={mainColor.c400}
            text="빈소 예약 도움받기"
            event={{ ...event, trigger_and_target: "opn_reserve_mdl" } as ClickEventType}
            onClick={() => {
              overlay.open(({ isOpen, close: onClose }) => {
                return (
                  <BinsoReserveModal
                    event={event}
                    parlorName={defaultParlor.companyname}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                );
              });
            }}
          />
        </Stack>
      </Stack>
      <Box height="2px" style={{ background: mainColor.c400 }} />
    </Stack>
  );
}
