import React from "react";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { ColorIcon } from "../../common/Icons";
import { ClickEventType } from "../../../types";

export function BTypeCTA({
  mainColor,
  sidoName,
  sigunguName,
  event,
}: {
  mainColor: ColorSystemProps;
  sidoName?: string;
  sigunguName?: string;
  event?: ClickEventType;
}) {
  return (
    <Stack gap="16px" padding="0 20px 20px">
      <Box className="subtitle_16_b" textAlign="center">
        전체 장례 비용(장지, 장례 용품 포함)
        <br />
        상세 장례 비용을 더 알아 보세요.
      </Box>

      <FullWidthButton
        backgroundColor={mainColor.c400}
        text="장례 견적 알아보기"
        href={GOI_WEB_URL + "/match/request/"}
        event={event}
      />

      <Stack flexDirection="row" gap="6px" alignItems="center">
        <ColorIcon name="icon-check-fill-mono" size={14} color="" />
        <Box className="caption_12_m">{`이 지역 기반으로 알아보기`}</Box>
      </Stack>
    </Stack>
  );
}
