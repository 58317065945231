import { Box, Stack } from "@mui/material";
import React from "react";

import { ClickEventType, ParlorDetailProps } from "../../../types";

import BTypeRegionPriceSummaryItem from "./BTypeRegionPriceSummaryItem";
import { ETypeMainHead } from "../MainHead";
import { ColorSystemProps } from "../../../constants";
import { colors } from "../../../styles";
import { BTypeCTA } from "../CTA";

export function ETypeRegionHead({
  regionParlors,
  sidoName,
  sigunguName,
  expectedParlorPrice,
  mainColor,
  event,
}: {
  regionParlors: Array<ParlorDetailProps>;
  sidoName: string;
  sigunguName?: string;
  expectedParlorPrice: any;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const name = `${sidoName}${sigunguName ? " " + sigunguName : ""}`;
  return (
    <>
      <ETypeMainHead
        defaultParlor={regionParlors[0]}
        title={`${sidoName}${
          sigunguName ? " " + sigunguName : ""
        } 장례식장 추천`}
        parlorsLength={regionParlors.length}
        sidoName={sidoName}
        sigunguName={sigunguName}
        binsosLength={regionParlors.length}
        mainColor={mainColor}
        event={event}
      />

      <Box padding="0 20px 20px">
        <Box
          width="100%"
          height="1px"
          style={{ background: colors.gray900 }}
          padding="0 20px"
        />
      </Box>

      <BTypeCTA
        mainColor={mainColor}
        sidoName={sidoName}
        sigunguName={sigunguName}
        event={event}
      />

      <Box padding="0 20px">
        <Box
          width="100%"
          height="1px"
          style={{ background: colors.gray900 }}
          margin="0 0 20px"
        />
      </Box>

      <Stack gap="6px" padding="0px 20px">
        <Box className="subtitle_18_b">{name} 평균 비용/금액</Box>

        <BTypeRegionPriceSummaryItem
          text="조문객 50명 기준 평균 빈소 비용"
          price={expectedParlorPrice.무빈소장}
        />
        <BTypeRegionPriceSummaryItem
          text="조문객 100명 기준 평균 빈소 비용"
          price={expectedParlorPrice.가족장}
        />
        <BTypeRegionPriceSummaryItem
          text="조문객 200명 기준 평균 빈소 비용"
          price={expectedParlorPrice.일반장}
        />
      </Stack>
      <Box
        width="100%"
        height="1px"
        style={{ background: colors.gray900 }}
        margin="20px 0"
      />
    </>
  );
}
