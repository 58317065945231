import React, { useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ParlorDetailProps } from "../../../types";
import { ColorIcon } from "../../common/Icons/ColorIcon";
import { colors } from "../../../styles";
import { FUNERAL_SIDO } from "../../../constants";

import parlors from "../../../constants/parlors_detail.json";

export function DTypeFAQ({ defaultParlor }: { defaultParlor: ParlorDetailProps }) {
  const managedTypeEqualParlors = parlors.filter(
    (res) =>
      res.managed_type === defaultParlor.managed_type && res.sigungu === defaultParlor.sigungu
  );

  const currentParlorAverageBinsoPrice = defaultParlor.binso_average_charge;
  const [averageBinsosPrice, setAverageBinsosPrice] = useState(0);

  useEffect(() => {
    let price = 0;

    managedTypeEqualParlors.map((res) => {
      for (let i = 0; i < managedTypeEqualParlors.length - 1; i++) {
        if (typeof res.binso_average_charge === "number")
          price = price + Number(res.binso_average_charge);
      }
    });

    setAverageBinsosPrice(price / managedTypeEqualParlors.length);
  }, []);

  const isCurrentBinsoPriceExpensive = useMemo(() => {
    return currentParlorAverageBinsoPrice > averageBinsosPrice;
  }, [currentParlorAverageBinsoPrice, averageBinsosPrice]);

  const binsoPercent = isCurrentBinsoPriceExpensive
    ? (-(1 - currentParlorAverageBinsoPrice / averageBinsosPrice) * 100).toFixed(0)
    : ((1 - currentParlorAverageBinsoPrice / averageBinsosPrice) * 100).toFixed(0);

  return (
    <Stack gap="16px" padding="0px 0px 10px">
      <Box className="subtitle_18_b">자주 묻는 질문 FAQ</Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Stack flexDirection="row" gap="5px">
            <ColorIcon
              name="downArrow"
              size={16}
              style={{ marginTop: "2px" }}
              color={colors.gray600}
            />
            <Box className="subtitle_14_b gray900">
              조문객이 0명인 경우, 빈소를 세우지 않으면 장례식장 이용료는 무료인가요?
            </Box>
          </Stack>
          <Box component="p" className="body_14_m gray700">
            아닙니다.
            <br />
            장례식장 이용료는 빈소나 접객실 외에도 여러 요소로 구성됩니다. 비용에 대해 더 알고
            싶으시다면 전문가와 상담하는 것이 좋습니다. 모든 장례식장에서 빈소 없이 서비스를
            제공하는 것은 아니므로, 구체적인 상황에 따라 상담이 필요할 수 있습니다.
          </Box>
        </Stack>
        <Stack gap="4px">
          <Stack flexDirection="row" gap="5px">
            <ColorIcon
              name="downArrow"
              size={16}
              style={{ marginTop: "2px" }}
              color={colors.gray600}
            />
            <Box component="p" className="subtitle_14_b gray900">
              Q. {defaultParlor.companyname}의 가격대는 저렴한 편에 속하나요?
            </Box>
          </Stack>
          <Box className="body_14_m gray700">
            {defaultParlor.companyname}은 {FUNERAL_SIDO.get(Number(defaultParlor.sido))}의{" "}
            {defaultParlor.managed_type} 인근 장례식장과 비교했을 때{binsoPercent}% 정도{" "}
            {isCurrentBinsoPriceExpensive ? "비용이 높습니다" : "비용이 저렴합니다"}.
            <br /> 이는 e하늘 장사정보 시스템을 기반으로 안내하며, 장례식장에서 가격정보를 최신화
            하지 않았을 경우 다를 수 있습니다.
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
