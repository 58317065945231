import { ServiceDetails } from "./details";

export const 일반장: ServiceDetails = {
  type: "일반장",
  description: `조문객 200명 내외의 장례에 맞는 상품입니다.`,
  recommendationText: `<b>고이 일반장은,</b>
  200명 내외의 조문객과 함께, 
  조문객 맞이에 불편함이 없는 
  장례를 희망하는 분께 추천드립니다.`,
  정상가: 420,
  가격: 350,
  조문객: "100명 이상",
  빈소: "60평 이상",
  접객도우미: "5명",
  "장례 용품": "고급형",
  "장의 차량": 2,
  details: [
    {
      key: "인력",
      contents: [
        {
          src: "platform/service/person1.png",
          title: "장례지도사",
          descriptions: "1명 (3일)",
          price: 400_000,
        },
        {
          src: "platform/service/person2.png",
          title: "입관상례사",
          descriptions: "1명 (1일)",
          price: 150_000,
        },
        {
          src: "platform/service/person3.png",
          title: "접객 도우미",
          descriptions: "5명 (1인 10시간)",
          price: 750_000,
        },
      ],
    },
    {
      key: "용품",
      contents: [
        {
          src: "platform/service/3.png",
          title: "관",
          descriptions: `오동나무 1단관
          (화장용, 두께 1.0 이하)`,
          price: 250_000,
        },
        {
          src: "platform/service/sui3.png",
          title: "수의",
          descriptions: `고급 저마 100% 가진수의
          (회원 할인 혜택)
          `,
          price: 800_000,
          discountPrice: 250_000,
        },
        {
          src: "platform/service/ugol3.png",
          title: "유골함",
          descriptions: `KCL, KEMTI 인증 
          일반 봉안함
          (회원 할인 혜택)`,
          price: 350_000,
          discountPrice: 200_000,
        },
        {
          src: "platform/service/6.png",
          title: "관 장식용 생화",
          descriptions: `계절별 생화 및 국화, 안개
          
          `,
          price: 100_000,
        },
        {
          src: "platform/service/5.png",
          title: "입관 수시 용품",
          descriptions: "염포, 관보, 명정, 예단 등 20종",
          price: 250_000,
        },
        {
          src: "platform/service/7.png",
          title: "빈소 용품 등",
          descriptions: "초, 향, 부의록 등",
          price: 100_000,
        },
        {
          src: "platform/service/sangbok_male.png",
          title: "남자 상복",
          descriptions: "현대식 상복 세트 4벌",
          price: 160_000,
        },
        {
          src: "platform/service/sangbok_female.png",
          title: "여자 상복",
          descriptions: "현대식 상복 세트 4벌",
          price: 40_000,
        },

        {
          src: "platform/service/sangbok.png",
          title: "상주 용품",
          descriptions: "완장, 상장핀, 리본 등",
          price: 0,
        },
      ],
    },
    {
      key: "차량",
      contents: [
        {
          src: "platform/service/ambulance.png",
          title: "1일차 고인 이송 차량",
          descriptions: `앰뷸런스
          (관외 및 거리 추가 시 
          km당 2,000원 추가)`,
          price: 100_000,
        },
        {
          src: "platform/service/bus.png",
          title: "3일차 고인 이송 차량",
          descriptions: `45인승 버스
          (200km 초과시
          km 당 2,000원 추가)`,
          price: 400_000,
        },
        {
          src: "platform/service/limousine.png",
          title: "3일차 고인 이송 차량",
          descriptions: "리무진",
          price: 350_000,
        },
      ],
    },
  ],
  steps: [
    [
      {
        src: "images/service/first_1.png",
        title: "고인 이송",
        description: "임종 후, 병원이나 장례식장으로 이송합니다.",
      },
      {
        src: "images/service/first_2.png",
        title: "고인 안치",
        description: "고인의 옷과 몸을 바르게 하는 수시 후, 고인을 장례식장에 안치합니다.",
      },
      {
        src: "images/service/first_3.png",
        title: "빈소 설치",
        description: "조문객의 인원과, 가족수에 맞는 빈소를 설치합니다.",
      },
      {
        src: "images/service/first_4.png",
        title: "화장장 예약",
        description: "3일차에 화장이 가능하도록 장례지도사님께서 미리 화장장 예약을 진행합니다.",
      },
      {
        src: "images/service/first_5.png",
        title: "부고 문자 발송",
        description: "꼭 필요한 정보가 누락되지 않도록 부고문자를 작성해드립니다.",
      },
    ],
    [
      {
        src: "images/service/second_1.png",
        title: "염습",
        description: "고인의 몸을 깨끗이 한 후, 수의를 입힙니다. 입관전에 거치는 절차입니다.",
      },
      {
        src: "images/service/second_2.png",
        title: "입관식",
        description:
          "염습 후 고인의 마지막 모습을 보면서 애도할 수 있는 입관식을 진행합니다.\n" +
          "(고인의 종교에 따라 종교지도사 참여 가능합니다.)",
      },
      {
        src: "images/service/second_3.png",
        title: "성복",
        description:
          "정식으로 상복을 입는 절차입니다. 요즘에는 1일차에 바로 상복을 입지만, 입관 후 정식으로 완장/머리핀을 착용하게 됩니다.",
      },
      {
        src: "images/service/second_4.png",
        title: "성복제",
        description: "성복 후 고인께 제례를 드리는 절차로 종교에 따라 생략 혹은 대체가 가능합니다.",
      },
      {
        src: "images/service/second_5.png",
        title: "조문",
        description:
          "일반적으로 2일차에 조문객이 가장 많이 옵니다. 상황에 맞게 인력을 배치해드립니다.",
      },
    ],
    [
      {
        src: "images/service/third_1.png",
        title: "발인",
        description: "관이 장례식장을 떠나는 절차이며, 관을 버스나 리무진까지 옮깁니다.",
      },
      {
        src: "images/service/third_2.png",
        title: "운구",
        description: "고인을 장지까지 옮기는 절차이며, 버스나 리무진으로 이송합니다.",
      },
      {
        src: "images/service/third_3.png",
        title: "화장 / 유골 안치",
        description: "예약한 시간에 화장 후 안치시설로 옮깁니다.",
      },
      {
        src: "images/service/third_4.png",
        title: "사후 행정",
        description: "사망신고, 유족연금, 상속신고 등 법적, 행정적 안내를 도와드립니다.",
      },
    ],
  ],
  deductions: [
    "패키지에 포함되어 있는 품목 미사용시 금액을 100% 공제해 드립니다.",
    "용품/차량이 아닌 인력 서비스(장례지도사, 염습상례사, 접객 도우미)는 장례식 절차에 빠질 수 없는 요소이기 때문에 공제가 불가능합니다.",
  ],
  extra: [
    "4일장, 5일장 등 3일장에서 일정이 길어질 경우 장례지도사 서비스 비용이 1일당 15만원 추가됩니다.",
    "정해진 거리를 초과하여 장의 차량 및 앰뷸런스를 이용할 경우 차량 서비스 비용이 1km당 2,000원 추가됩니다",
    "관외 이동시 도로교통비가 발생할 경우 해당 비용을 청구합니다.",
    "사고사의 경우 염습에 특수처리가 필요하여 20만원의 비용이 추가됩니다.",
    "매장의 경우 매장 전용 관, 수의, 횡대 등의 물품이 필요하여 추가 비용이 발생합니다.",
  ],
};
