import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import { ClickEventType, DefaultFacility } from "../../../types";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { customEvent, numberToLocalString } from "../../../utils";
import { colors } from "../../../styles";
import { FullWidthTwoLineButton } from "../../common/Buttons/FullWidthTwoLineButton";
import { ColorIcon } from "../../common/Icons";
import { Spacing } from "../../common";

interface ATypeFacilityPriceSummaryProps {
  defaultFacility: DefaultFacility;
  mainColor: ColorSystemProps;
  detailButtonEvent: ClickEventType;
}

export function ATypeFacilityPriceSummary({
  defaultFacility,
  mainColor,
  detailButtonEvent,
}: ATypeFacilityPriceSummaryProps) {
  const parsePriceToTenThousand = (price: number) => {
    return numberToLocalString(Math.round(price / 10000));
  };

  const { id, companyname, hallrents } = defaultFacility;

  const 오름차순_hallrents = [...hallrents]?.sort((a, b) => {
    if (a?.charge && b?.charge) {
      return a.charge - b.charge;
    }

    return 0;
  });

  const firstHallrents = 오름차순_hallrents[0];
  const lastHallrents = 오름차순_hallrents.at(-1);

  const minCharge = firstHallrents?.charge || 0;
  const maxCharge = lastHallrents?.charge || 0;

  const parsedMinCharge = parsePriceToTenThousand(minCharge);
  const parsedMaxCharge = parsePriceToTenThousand(maxCharge);

  return (
    <Stack>
      <FullWidthTwoLineButton
        event={{
          ...detailButtonEvent,
          trigger_and_target: "CTA_uv_mth_req",
        }}
        backgroundColor={mainColor.c400}
        text="장례 총 비용 알아보기"
        description="장지를 포함한 총 비용이 알고싶다면?"
        href={GOI_WEB_URL + "/match/request/"}
      />
      <Spacing gap={30} />

      <Box className="subtitle_16_b">{companyname} 비용</Box>
      <Box className="subtitle_14_b" padding="20px 0 8px">
        예상 비용 : {parsedMinCharge} ~ {parsedMaxCharge}
        만원
      </Box>
      <Box className="body_14_m">
        {companyname}의 시설 이용은 {parsedMinCharge} ~ {parsedMaxCharge}원
        정도까지 비용이 발생할 수 있을 것으로 예상됩니다. 아래 표는{" "}
        {companyname}에 대한 상세 비용표입니다.
      </Box>

      <TableContainer
        sx={{
          padding: "20px 0 24px",
          ["td, tr, th"]: {
            border: `1px solid ${colors.gray500}`,
            textAlign: "center",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="subtitle_14_sb">
                항목 및 상세 내역
              </TableCell>
              <TableCell className="subtitle_14_sb">비용</TableCell>
              <TableCell className="subtitle_14_sb">할인가</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {오름차순_hallrents?.map((res) => {
              const item = res.item || "--";
              const detail = res.detail || "--";
              const charge = res.charge || "--";

              return (
                <TableRow>
                  <TableCell className="subtitle_14_sb">
                    {item} / {detail}{" "}
                  </TableCell>
                  <TableCell className="subtitle_14_sb">
                    {charge && charge === "--"
                      ? charge
                      : numberToLocalString(charge) + "원"}
                  </TableCell>
                  <TableCell className="subtitle_14_sb">
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Link
                        underline="none"
                        href={`${GOI_WEB_URL}/match/facility-requests/${id}/`}
                        onClick={() =>
                          customEvent({
                            ...detailButtonEvent,
                            trigger_and_target: "uv_mth_facil_req",
                          })
                        }
                      >
                        알아보기
                      </Link>
                      <ColorIcon
                        name="arrow_right_small"
                        size={16}
                        color={mainColor.c300}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
