import { Box, Stack } from "@mui/material";
import React from "react";
import { useResponsive } from "../../../hooks";
import { colors } from "../../../styles";
import { customEvent, getCdnImageSrc } from "../../../utils";
import { ColorIcon } from "../../common";
import { ClickEventType } from "../../../types";

export function ATypeCardView({
  imgSrc,
  href,
  name,
  descriptions,
  arrowText = "자세히 보기",
  event,
}: {
  imgSrc: string;
  href: string;
  name: string;
  descriptions: Array<string>;
  arrowText?: string;
  event?: ClickEventType;
}) {
  const { isBiggerThanMd } = useResponsive();

  const onClickEvent = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <Box
      component="li"
      display="flex"
      flexDirection="column"
      gap="5px"
      sx={{
        listStyle: "none",
        background: "white",
        border: `1px solid ${colors.gray300}`,
      }}
      onClick={onClickEvent}
    >
      <Stack component="a" href={href}>
        <Box
          position="relative"
          sx={{
            width: "100%",
            height: "140px",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <img
            src={
              imgSrc
                ? imgSrc
                : getCdnImageSrc("platform/parlor/parlor-img-default-2.png")
            }
            style={{
              position: "absolute",
              width: "100%",
              top: isBiggerThanMd ? "-60px" : "0px",
            }}
            alt={name}
          />
        </Box>
        <Stack padding="16px" gap="9px" width="100%" height="140px">
          <Box>
            <Box component="h3" className="subtitle_16_b">
              {name}
            </Box>

            {descriptions.map((description: string) => {
              return (
                <Box component="p" className="body_16_m gray700">
                  {description}
                </Box>
              );
            })}
          </Box>

          <Stack
            component="div"
            height="100%"
            className="button_16_sb"
            justifyContent="flex-end"
          >
            <Stack flexDirection="row" gap="3px" alignItems="center">
              <div>{arrowText}</div>
              {/* <div>은하구공원 상세 비용 알아보기</div> */}
              <ColorIcon
                name="arrow_right_small"
                size={16}
                color={colors.gray900}
                style={{ marginBottom: "2px" }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
