import React from "react";

import { ColorSystemProps, GOI_WEB_URL } from "../../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthButton } from "../../../common/Buttons/FullWidthButton";
import { ClickEventType } from "../../../../types";

export function A_2TypeCTA({
  mainColor,
  event,
}: {
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      padding="40px 20px 46px"
      style={{ background: mainColor.c100 }}
      gap="24px"
    >
      <Box textAlign="center" className="subtitle_16_sb">
        다른 장례식장도 함께 비교하고 추천 받고 싶다면 아래 버튼을 이용해
        주세요.
      </Box>
      <Stack width="100%" padding="0 34px" gap="12px">
        <FullWidthButton
          text={"장례식장 더 비교해보기"}
          href={GOI_WEB_URL + "/match/request/"}
          backgroundColor={mainColor.c300}
          event={
            {
              ...event,
              trigger_and_target: "uv_mth_req",
            } as ClickEventType
          }
        />
      </Stack>
    </Stack>
  );
}
