import { Link, Stack } from "@mui/material";
import React from "react";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import {
  ClickEventType,
  EventTriggerAndTargetType,
  ParlorDetailProps,
} from "../../../types";
import { NormalCssProperties } from "@mui/material/styles/createMixins";
import { useResponsive } from "../../../hooks";
import { colors } from "../../../styles";
import { customEvent } from "../../../utils";

export function ATypeTabs({
  mainColor,
  selectTab,
  href,
  defaultParlor,
  event,
}: {
  mainColor: ColorSystemProps;
  selectTab: string;
  href: string;
  defaultParlor: ParlorDetailProps;
  event?: ClickEventType;
}) {
  const tabList = ["비용", "소개", "실시간 빈소", "화환 보내기", "상조 서비스"];
  const tabName = ["price", "introduce", "binso", "flower", "service"];

  const linkStyle = (tab: string) => {
    return {
      padding: "12px 0px",
      textDecoration: "none",
      borderBottom: selectTab === tab ? "3px solid black" : "none",
      color: selectTab === tab ? "black" : colors.gray600,
      textAlign: "center",
    };
  };

  const onClickLink = (name: string) => {
    if (event) {
      customEvent({
        ...event,
        trigger_and_target:
          `${event.trigger_and_target}-${name}` as EventTriggerAndTargetType,
      });
    }
  };

  return (
    <>
      <Stack padding={"0px 16px"} borderBottom={`1px solid ${colors.gray300}`}>
        <Stack flexDirection="row" gap="16px">
          <Link
            className="caption_12_b"
            style={linkStyle(tabList[0]) as NormalCssProperties}
            href={`/${href}/price/`}
            onClick={() => onClickLink(tabName[0])}
          >
            {tabList[0]}
          </Link>

          <Link
            className="caption_12_b"
            style={linkStyle(tabList[1]) as NormalCssProperties}
            href={`/${href}/`}
            onClick={() => onClickLink(tabName[1])}
          >
            {tabList[1]}
          </Link>

          <Link
            className="caption_12_b"
            style={linkStyle(tabList[2]) as NormalCssProperties}
            href={`/${href}/binso/`}
            onClick={() => onClickLink(tabName[2])}
          >
            {tabList[2]}
          </Link>

          <Link
            className="caption_12_b"
            style={linkStyle(tabList[3]) as NormalCssProperties}
            href={`${GOI_WEB_URL}/parlor/${defaultParlor.id}/?showFlowerModal=true`}
            onClick={() => onClickLink(tabName[3])}
          >
            {tabList[3]}
          </Link>

          <Link
            className="caption_12_b"
            style={linkStyle(tabList[4]) as NormalCssProperties}
            href={`/${href}/service/`}
            onClick={() => onClickLink(tabName[4])}
          >
            {tabList[4]}
          </Link>
        </Stack>
      </Stack>
    </>
  );
}
