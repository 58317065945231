import {
  Box,
  Button,
  ChakraProvider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import React, { useCallback, useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import { customEvent } from "../../utils";
import { chakraTheme, colors } from "../../styles";
import { ColorIcon, HStack, VStack } from "../common";

import emailjs from "@emailjs/browser";
import { EventDomainType, EventPathType } from "../../types";
import { DOMAINS_KR } from "../../constants/dogSound/domainKR";

interface SearchRequestModalProps {
  keyword: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  domain: keyof typeof EventDomainType;
  path: EventPathType;
}
export default function SearchRequestModal({
  keyword,
  title,
  isOpen,
  onClose,
  domain,
  path,
}: SearchRequestModalProps) {
  const [type, setType] = useState("휴대폰");
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);

  const handleClick = useCallback(() => {
    customEvent({
      domain: "common",
      path,
      trigger_and_target: "send_question_email_try",
    });
    customEvent({
      domain,
      path,
      trigger_and_target: "send_question_email_try",
    });

    if (!checked) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }

    if (!value) {
      alert("번호를 입력해주세요");
      return;
    }

    if (/^010-\d{4}-\d{4}$/.test(value)) {
      emailjs
        .send(
          "service_agc6alu",
          "template_o1ul2fg",
          {
            question: `${DOMAINS_KR[domain]}에서 발생한 질문입니다. | ${keyword}`,
            phone: value,
          },
          "ckK66U9O1M16QnFJq"
        )
        .then(() => {
          customEvent({
            domain: "common",
            path,
            trigger_and_target: "send_question_email",
          });
          customEvent({
            domain,
            path,
            trigger_and_target: "send_question_email",
          });
          alert("요청이 완료되었습니다. 30분 이내로 답변드리겠습니다.");
          setValue("");
          onClose();
          setChecked(false);
        });
    } else {
      alert("번호 형식을 확인해주세요. (010-****-****)");
    }
  }, [checked, value, type]);

  return (
    <ChakraProvider theme={chakraTheme}>
      <Box height="100%" width="100%">
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
          closeOnOverlayClick={false}
          scrollBehavior="inside"
          isCentered={true}
          useInert={false}
        >
          <ModalOverlay width="100%" height="100%" />
          <ModalContent
            maxWidth="380px"
            minWidth="290px"
            backgroundColor={colors.white}
            backgroundSize="cover"
          >
            <ModalBody padding="15px 14px 36px">
              <VStack width="100%" gap={10}>
                <HStack width="100%" justifyContent="end">
                  <div onClick={onClose}>
                    <ColorIcon
                      name="close"
                      width={24}
                      height={24}
                      color={colors.black}
                    />
                  </div>
                </HStack>
                <VStack width="100%" gap={24}>
                  <HStack width="100%" alignItems="center" gap={8}>
                    <ColorIcon
                      name="list"
                      color={colors.gray900}
                      width={30}
                      height={30}
                    />
                    <div className="subtitle_21_b gray900">{title}</div>
                  </HStack>
                  <VStack gap={12} width="100%" className="body_16_m gray800">
                    <Box className="caption_14_b">
                      "{keyword}" 가(이) 궁금하신가요? <br />
                      고객님의 전화번호로 빠르게 답변드리겠습니다.
                    </Box>
                  </VStack>
                  {/* <HStack width="100%" gap={12}>
                    <Button
                      width="50%"
                      onClick={() => {
                        handleTypeChange("이메일");
                      }}
                      border={`1px solid ${
                        type === "이메일" ? colors.black : "none"
                      }`}
                    >
                      이메일로 받기
                    </Button>
                    <Button
                      width="50%"
                      onClick={() => {
                        handleTypeChange("번호");
                      }}
                      border={`1px solid ${
                        type === "번호" ? colors.black : "none"
                      }`}
                    >
                      번호로 받기
                    </Button>
                  </HStack> */}
                  <Input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    border={`1px solid ${colors.gray400}`}
                    width="100%"
                    placeholder={"010-****-****"}
                  />

                  <HStack alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      label="개인정보 수집 및 이용 동의"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => {
                            setChecked(!checked);
                          }}
                        />
                      }
                    />

                    <a
                      className="caption_11_b"
                      href="https://goifuneral.notion.site/e541b207305a46089ede35271b449b87"
                      target="_blank"
                    >
                      보기
                    </a>
                  </HStack>
                  <Button
                    width="100%"
                    backgroundColor={colors.gray900}
                    color={colors.white}
                    onClick={handleClick}
                  >
                    확인
                  </Button>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
}
