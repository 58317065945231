import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import {
  ColorSystemProps,
  FUNERAL_SIDO,
  FUNERAL_SIGUNGU,
} from "../../../constants";
import { A_1TypeCTA } from "./CTA";
import { CTypePriceSummary } from "./PriceSummary";
import { CTypeFacility } from "./Facility";

export function DTypeCompareParlor({
  firstParlor,
  secondParlor,
  mainColor,
  event,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <>
      <Stack
        padding="20px"
        height="240px"
        position="relative"
        style={{ background: "black" }}
        alignItems="center"
        justifyContent="center"
        gap="10px"
      >
        <Stack flexDirection="row" alignItems="center" gap="11px">
          <Stack>
            <h2 className="subtitle_21_sb white">{firstParlor.companyname}</h2>
            <h2 className="subtitle_21_sb white">{secondParlor.companyname}</h2>
          </Stack>
          <Box className="subtitle_21_sb white">비교</Box>
        </Stack>
        <Box width="100%" height="1px" style={{ background: "white" }} />
        <Stack width="100%" gap="8px">
          <Stack
            flexDirection="row"
            className="white"
            width="100%"
            gap="16px"
            justifyContent="flex-start"
          >
            <Box className="subtitle_16_sb gray50">비용</Box>
            <Stack>
              <Box className="subtitle_16_sb gray50">
                {firstParlor.expected_charge_sum.가족장.toLocaleString()}원 |{" "}
                {secondParlor.expected_charge_sum.가족장.toLocaleString()}원
              </Box>
              <Box className="caption_12_m gray200">
                조문객 50명 기준 예상 비용
              </Box>
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            className="white"
            width="100%"
            gap="16px"
            justifyContent="flex-start"
          >
            <Box className="subtitle_16_sb gray50">유형</Box>
            <Stack>
              <Box className="subtitle_16_sb gray50">
                {firstParlor.managed_type} 장례식장 |{" "}
                {secondParlor.managed_type} 장례식장
              </Box>
              <Box className="caption_12_m gray200">
                장례식장 유형에 따라 비용의 차이가 있습니다.
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        padding="20px 12px 0"
        className="body_16_m gray900"
        style={{ background: "white" }}
      >
        <CTypePriceSummary
          firstParlor={firstParlor}
          secondParlor={secondParlor}
          mainColor={mainColor}
        />
      </Stack>
      <Stack padding="20px 0 0" className="body_16_m gray900">
        <A_1TypeCTA
          mainColor={mainColor}
          firstParlor={firstParlor}
          secondParlor={secondParlor}
          event={event}
        />
      </Stack>
      <Stack
        padding="30px"
        className="subtitle_16_sb gray900"
        style={{ background: "white" }}
        gap="12px"
      >
        <h3 className="subtitle_16_b">위치</h3>
        <Box>
          각각 {FUNERAL_SIDO.get(Number(firstParlor.sido))}에 위치한
          장례식장으로,
          <br />
          <br />
          {firstParlor.companyname}은 {firstParlor.address},<br />
          <br /> {secondParlor.companyname}은 {secondParlor.address}에
          위치해있습니다.
        </Box>
      </Stack>
      <Stack
        padding="20px"
        className="body_16_m gray900"
        style={{ background: "white" }}
      >
        <CTypeFacility
          firstParlor={firstParlor}
          secondParlor={secondParlor}
          mainColor={mainColor}
          event={event}
        />
      </Stack>
    </>
  );
}
