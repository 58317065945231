import { useMediaQuery } from "@chakra-ui/react";
import { useCallback } from "react";
import { breakpoints } from "../styles/global/breakpoints";

export function useResponsive() {
  const [isBiggerThanMd, isBiggerThanLg, isBiggerThanXl] = useMediaQuery([
    `(min-width: ${breakpoints.md})`,
    `(min-width: ${breakpoints.lg})`,
    `(min-width: ${breakpoints.xl})`,
  ]);
  const setResponsive = useCallback(
    (mobileValue: any, tabletValue: any, desktopValue?: any, maxWidthValue?: any) => {
      return isBiggerThanXl
        ? maxWidthValue || desktopValue || tabletValue
        : isBiggerThanLg
        ? desktopValue || tabletValue
        : isBiggerThanMd
        ? tabletValue
        : mobileValue;
    },
    [isBiggerThanLg, isBiggerThanMd, isBiggerThanXl]
  );

  return {
    isBiggerThanMd,
    isBiggerThanLg,
    isBiggerThanXl,
    setResponsive,
  };
}

export function useMinWidth(width: string) {
  const [isBiggerThanSetWidth] = useMediaQuery(`(min-width: ${width})`);
  return { isBiggerThanSetWidth };
}
