import { Link, Stack } from "@mui/material";
import { NormalCssProperties } from "@mui/material/styles/createMixins";
import React from "react";

import { ColorSystemProps } from "../../../constants";
import { ClickEventType, EventTriggerAndTargetType } from "../../../types";
import { useResponsive } from "../../../hooks";
import { customEvent } from "../../../utils";

interface BTypeTabsFacilityProps {
  mainColor: ColorSystemProps;
  selectTab: string;
  href: string;
  event?: ClickEventType;
}
export function BTypeTabsFacility({
  mainColor,
  selectTab,
  event,
}: BTypeTabsFacilityProps) {
  const tabList = ["소개"];
  const tabName = ["intro"];

  const linkStyle = (tab: string) => {
    return {
      border: `0.5px solid ${mainColor.c300}`,
      width: "100%",
      height: "100%",
      padding: "9px 50px",
      textDecoration: "none",
      color: selectTab === tab ? "white" : mainColor.c300,
      background: selectTab === tab ? mainColor.c300 : "white",
      textAlign: "center",
    };
  };

  const onClickLink = (name: string) => {
    if (event) {
      customEvent({
        ...event,
        trigger_and_target:
          `${event.trigger_and_target}-${name}` as EventTriggerAndTargetType,
      });
    }
  };

  const { isBiggerThanLg } = useResponsive();

  return (
    <Stack padding={isBiggerThanLg ? "20px 0" : "20px"}>
      <Stack style={{ border: `0.1px solid ${mainColor.c300}`, width: "100%" }}>
        <Link
          className="caption_12_b"
          style={linkStyle(tabList[0]) as NormalCssProperties}
          href={""}
          onClick={() => onClickLink(tabName[0])}
        >
          {tabList[0]}
        </Link>
        <Stack flexDirection="row"></Stack>

        {/* <Stack flexDirection="row">
          <Link
            className="caption_12_b"
            style={linkStyle(tabList[1]) as NormalCssProperties}
            href={`/${href}/`}
            onClick={() => onClickLink(tabName[1])}
          >
            {tabList[1]}
          </Link>

          <Link
            className="caption_12_b"
            style={linkStyle(tabList[2]) as NormalCssProperties}
            href={`/${href}/binso/`}
            onClick={() => onClickLink(tabName[2])}
          >
            {tabList[2]}
          </Link>
        </Stack> */}

        {/* <Stack flexDirection="row">
          <Link
            className="caption_12_b"
            style={linkStyle(tabList[3]) as NormalCssProperties}
            href={`${GOI_WEB_URL}/parlor/${defaultParlor.id}/?showFlowerModal=true`}
            onClick={() => onClickLink(tabName[3])}
          >
            {tabList[3]}
          </Link>

          <Link
            className="caption_12_b"
            style={linkStyle(tabList[4]) as NormalCssProperties}
            href={`/${href}/service/`}
            onClick={() => onClickLink(tabName[4])}
          >
            {tabList[4]}
          </Link>
        </Stack> */}
      </Stack>
    </Stack>
  );
}
