import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Stack } from "@mui/material";
import { ColorSystemProps } from "../../../constants";
import { A_2TypeCTA } from "./CTA";
import { ATypePriceSummary } from "./PriceSummary";
import { BTypeFacility } from "./Facility";
import { Image } from "../../common/Image";
import { colors } from "../../../styles";
import { Spacing } from "../../common";
import { Box } from "@chakra-ui/react";

export function ETypeCompareParlor({
  firstParlor,
  secondParlor,
  mainColor,
  event,
  isExclude,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
  isExclude: boolean;
}) {
  if (isExclude) {
    return (
      <Stack
        padding="20px"
        position="relative"
        justifyContent="center"
        gap="10px"
      >
        <h1
          className="subtitle_18_b"
          style={{
            color: mainColor.c400,
            borderBottom: `1px solid ${colors.gray300}`,
          }}
        >
          장례식장 비용 비교 유형 소개
        </h1>
        <Spacing gap={30} />
        <Box
          padding="32px"
          background={colors.gray100}
          className="subtitle_18_m gray600"
          borderRadius="16px"
        >
          <span className="gray900">
            해당 장례식장의 요청에 의해
            <br />
            가격, 비용정보, 후기, 사진 등의 정보를 제공하지 않습니다.
            <br />
            <br />
          </span>
          지역 장례식장 비용 비교, 지역내 장례식장 정보가 궁금하신 경우
          e하늘장사정보시스템(https://15774129.go.kr/intro.do)
          <br />
          혹은 <b className="orange700">고이 24시간 고객센터{"(1666-9784)"}</b>
          로 문의해주세요
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        padding="20px"
        position="relative"
        justifyContent="center"
        gap="10px"
      >
        <h1
          className="subtitle_18_b"
          style={{
            color: mainColor.c400,
            borderBottom: `1px solid ${colors.gray300}`,
          }}
        >
          장례식장 비용 비교 유형 소개
        </h1>

        <Stack flexDirection="row" gap="16px">
          <Stack width="50%" gap="4px">
            <h2 className="subtitle_14_sb">{firstParlor.companyname}</h2>
            <Box
              height="120px"
              width="100%"
              overflow="hidden"
              borderRadius="5px"
            >
              {firstParlor.images[0].url && (
                <Image src={firstParlor.images[0].url} width="100%" />
              )}
            </Box>
            <Box className="body_14_m gray600">
              {firstParlor.managed_type} 장례식장
            </Box>
            <Box className="body_14_m gray600">
              빈소 유형: {firstParlor.binsos.length}개
            </Box>
            <Box className="body_14_m gray600">주소: {firstParlor.address}</Box>
            <Box className="body_14_m gray600">
              조문객 50명 기준 비용:{" "}
              {firstParlor.expected_charge_sum.가족장.toLocaleString()}원
            </Box>
          </Stack>
          <Stack width="50%" gap="4px">
            <h2 className="subtitle_14_sb">{secondParlor.companyname}</h2>
            <Box
              height="120px"
              width="100%"
              overflow="hidden"
              borderRadius="5px"
            >
              {secondParlor.images[0].url && (
                <Image src={secondParlor.images[0].url} width="100%" />
              )}
            </Box>
            <Box className="body_14_m gray600">
              {secondParlor.managed_type} 장례식장
            </Box>
            <Box className="body_14_m gray600">
              빈소 유형: {secondParlor.binsos.length}개
            </Box>
            <Box className="body_14_m gray600">
              주소: {secondParlor.address}
            </Box>
            <Box className="body_14_m gray600">
              조문객 50명 기준 비용:{" "}
              {secondParlor.expected_charge_sum.가족장.toLocaleString()}원
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        padding="20px 12px 0"
        className="body_16_m gray900"
        style={{ background: "white" }}
      >
        <ATypePriceSummary
          firstParlor={firstParlor}
          secondParlor={secondParlor}
          mainColor={mainColor}
        />
      </Stack>
      <Stack padding="20px 0 0" className="body_16_m gray900">
        <A_2TypeCTA mainColor={mainColor} event={event} />
      </Stack>

      <Stack
        padding="20px"
        className="body_16_m gray900"
        style={{ background: "white" }}
      >
        <BTypeFacility
          firstParlor={firstParlor}
          secondParlor={secondParlor}
          mainColor={mainColor}
        />
      </Stack>
      <Stack padding="20px 0 0" className="body_16_m gray900">
        <A_2TypeCTA mainColor={mainColor} event={event} />
      </Stack>
    </>
  );
}
