import { Link, Stack } from "@mui/material";
import React from "react";
import { ColorSystemProps } from "../../../constants";
import { ClickEventType, EventTriggerAndTargetType } from "../../../types";
import { NormalCssProperties } from "@mui/material/styles/createMixins";
import { colors } from "../../../styles";
import { customEvent } from "../../../utils";

interface DTypeTabsFacilityProps {
  mainColor: ColorSystemProps;
  selectTab: string;
  href: string;
  event?: ClickEventType;
}

/** introduce만 현재 구현 */
const TAB_DATA = {
  introduce: "소개",
  price: "비용",
  // binso: "실시간 빈소",
  // estimate: "상세 예상 비용 및 할인",
};

export function DTypeTabsFacility({
  selectTab,
  href,
  event,
}: DTypeTabsFacilityProps) {
  const linkStyle = (tab: string) => {
    return {
      padding: "12px 0px",
      textDecoration: "none",
      borderBottom: selectTab === tab ? "3px solid black" : "none",
      color: selectTab === tab ? "black" : colors.gray600,
      textAlign: "center",
    };
  };

  const tabDataList = Object.entries(TAB_DATA);

  const onClickLink = (name: string) => {
    if (event) {
      customEvent({
        ...event,
        trigger_and_target:
          `${event.trigger_and_target}-${name}` as EventTriggerAndTargetType,
      });
    }
  };

  return (
    <Stack padding={"0px 16px"} borderBottom={`1px solid ${colors.gray300}`}>
      <Stack flexDirection="row" gap="16px">
        {tabDataList.map(([key, value]) => {
          const pathName = key === "introduce" ? "" : key;
          const fullHref = pathName ? `/${href}/${pathName}/` : `/${href}/`;

          return (
            <Link
              key={value}
              className="caption_12_b"
              style={linkStyle(value) as NormalCssProperties}
              href={fullHref}
              onClick={() => onClickLink(key)}
            >
              {value}
            </Link>
          );
        })}
      </Stack>
    </Stack>
  );
}
