import React from "react";
import { Box, Stack } from "@mui/material";
import { ParlorDetailProps } from "../../../types";
export function BTypeFAQ({ defaultParlor }: { defaultParlor: ParlorDetailProps }) {
  return (
    <Stack gap="16px" padding="40px 20px">
      <Box className="subtitle_18_sb" textAlign="center">
        자주 묻는 질문
      </Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Box className="subtitle_14_b gray900">
            Q. 조문객이 없을 것 같은데 빈소를 차리지 않으면 {defaultParlor.companyname} 비용은
            0원인가요?
          </Box>
          <Box component="p" className="body_14_m gray700">
            그렇지 않습니다.
            <br />
            장례식장 비용은 빈소, 접객실 이용료 외에도 다양하게 구성되어 있습니다. 자세한 비용을
            알고 싶다면 전문가와의 상담을 추천드립니다. 또한 모든 장례식장이 빈소를 차리지 않는
            고객을 받아 주는 것은 아니기 때문에 더욱 더 상담이 필요합니다.
          </Box>
        </Stack>
        <Stack gap="4px">
          <Box component="p" className="subtitle_14_b gray900">
            Q. {defaultParlor.companyname}에 음식을 직접 가져 가도 되나요?
          </Box>
          <Box className="body_14_m gray700">외부 음식은 반입 금지입니다.</Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
