import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { DefaultFacility } from "../../../types";
import { ColorSystemProps } from "../../../constants";
import { numberToLocalString, sortHallrentsAsc } from "../../../utils";

import { colors } from "../../../styles";

interface Props {
  defaultFacility: DefaultFacility;
  mainColor: ColorSystemProps;
}

export function DTypeFacilityPriceSummary({
  defaultFacility,
  mainColor,
}: Props) {
  const { id, companyname, hallrents } = defaultFacility;
  const 오름차순_hallrents = sortHallrentsAsc(hallrents);

  return (
    <Stack gap="6px">
      <Box className="subtitle_16_b">{companyname} 납골당 비용</Box>

      <TableContainer
        sx={{
          padding: "20px 0 40px",
          ["th"]: { background: mainColor.c300, color: "white" },
          ["td, tr, th"]: { textAlign: "center", border: "none" },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderRadius: "10px 0 0 0", width: "65%" }}
                className="subtitle_14_b"
              >
                항목
              </TableCell>
              <TableCell
                style={{ borderRadius: "0 10px 0 0", width: "35%" }}
                className="subtitle_14_b"
              >
                사용료
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {오름차순_hallrents?.map((res, index) => {
              const item = res.item || "--";
              const charge = res.charge || "--";
              // const detail = res.detail || "--";

              return (
                <TableRow
                  style={{
                    background: index % 2 === 1 ? colors.gray50 : "",
                  }}
                >
                  <TableCell className="subtitle_14_sb">{item} </TableCell>
                  <TableCell className="subtitle_14_sb">
                    {charge && charge === "--"
                      ? charge
                      : numberToLocalString(charge) + "원"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
