import React from "react";
import { HStack } from "./HStack";
import { VStack } from "./VStack";
import { ColorIcon } from "./Icons";
import { colors } from "../../styles";

interface StarringProps {
  setStar?: (count: number) => void;
  star: number;
  size: number | string;
  gap?: number | string;
}

const Starring = ({ setStar, star, size, gap }: StarringProps) => {
  const colorStar = (count: number) => {
    if (setStar) setStar(count);
  };
  return (
    <HStack gap={gap}>
      {[1, 2, 3, 4, 5].map((starCount) => {
        return (
          <VStack key={starCount} onClick={() => colorStar(starCount)}>
            <ColorIcon
              name="rating_star"
              color={star >= starCount ? colors.reviewYellow : colors.gray100}
              size={size}
            />
          </VStack>
        );
      })}
    </HStack>
  );
};

export { Starring };
