import { colorSystem } from "goi_common";

const title = "서울 인천 경기 장례 정보 안내 포털";

export const parlorRegion = {
  defaultMetaData: {
    pageOgTitle: title,
    pageDescription: `${title} 장례식장 상세 비용, 가장 저렴한 ${title} 장례식장 비용 정보, 주변 장례식장과 비교하기.`,
    pageKeywords:
      "장례, 장례식, 가족장, 무빈소장례, 장례지도사, 장례식장, 장례 비용, 상조, 상조 회사, 상조회, 상조가입, 상조추천, 후불제상조, 장례상조, 상조서비스, 장례식 절차, 장례 절차, 임종, 고인, 빈소, 임종",
    pageOgDescription: `${title} 장례식장 상세 비용, 가장 저렴한 ${title} 장례식장 비용 정보, 주변 장례식장과 비교하기.`,
    pageOgImage: "",
  },

  layout: {
    headTitle: title,
    layoutStyle: {
      background: "white",
      borderBottom: "1px solid #7d7d7d",
      color: "black",
      height: "60px",
    },
    ctaButtonStyle: { border: "none", color: "white", background: colorSystem.blue.c400 },
  },

  targetSidos: ["11", "31", "23"],

  regionColor: colorSystem.blue,

  bestParlorIds: [1, 39, 519, 580, 651],

  baseUrl: "https://www.metropolitan-funeral.co.kr",
};
