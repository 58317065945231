import React, { ReactNode, useEffect, useRef, useState } from "react";
import { GraySpinner } from "goi_common";

import Script from "next/script";
import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";

interface Map {
  children: ReactNode;
}

export function MultipleMarkerMap({ parlor, other }: { parlor: any; other?: any }) {
  const [isLoad, setIsLoad] = useState(false);
  const mapRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    if (!isLoad || !mapRef) return;

    const latitude = parlor.latitude;
    const longitude = parlor.longitude;

    const mapOptions = {
      center: new window.naver.maps.LatLng(latitude, longitude),
      zoom: 10,
    };
    const map = new window.naver.maps.Map(mapRef.current, mapOptions);

    const markers = [] as any;
    const infoWindows = [] as any;

    const markerOptions = {
      position: new window.naver.maps.LatLng(latitude, longitude),
      map,
      title: parlor.companyname,
      icon: {
        content: `<div style='width:30px; height:30px; padding: 6px 12px; border-radius: 30px; background:#F18334; display:flex; align-items:center; justify-content:center;' >
          <div style='width:15px; height:15px; padding: 6px 8px; border-radius: 15px; background:white'></div>
          </div>`,
        size: window.naver.maps.Size(50, 52),
        origin: window.naver.maps.Point(0, 0),
        anchor: window.naver.maps.Point(25, 26),
      },
    };
    const marker = new window.naver.maps.Marker(markerOptions);

    const infoWindow = new window.naver.maps.InfoWindow({
      content: `<div style='width:100%; padding: 6px 12px; border-radius: 5px' class='body3_m'>${parlor.companyname}</div>`,
      borderWidth: 1,
      borderRadius: 5,
      backgroundColor: "rgba(255,255,255,0.7)",
      pixelOffset: new window.naver.maps.Point(0, -5),
      anchorSize: 0,
    });

    markers.push(marker);
    infoWindows.push(infoWindow);
    infoWindow.open(map, marker);

    if (other?.length > 0) {
      other.map((res: any, i: number) => {
        const otherMarkerOptions = {
          position: new window.naver.maps.LatLng(res.latitude, res.longitude),
          map,
          title: parlor.companyname,
          icon: {
            content: `<div style='width:30px; height:30px; padding: 6px 12px; border-radius: 30px; background:${
              i ? "#C3640E" : "#753C08"
            }; display:flex; align-items:center; justify-content:center;' >
          <div style='width:15px; height:15px; padding: 6px 8px; border-radius: 15px; background:white'></div>
          </div>`,
            size: window.naver.maps.Size(50, 52),
            origin: window.naver.maps.Point(0, 0),
            anchor: window.naver.maps.Point(25, 26),
          },
        };

        const otherMarker = new window.naver.maps.Marker(otherMarkerOptions);

        const otherInfoWindow = new window.naver.maps.InfoWindow({
          content: `<div style='width:100%; padding: 6px 12px; border-radius: 5px' class='body3_m'>${res.companyname}</div>`,
          borderWidth: 1,
          borderRadius: 5,
          backgroundColor: "rgba(255,255,255,0.7)",
          pixelOffset: new window.naver.maps.Point(0, -5),
          anchorSize: 0,
        });
        markers.push(otherMarker);
        infoWindows.push(otherInfoWindow);
      });
    }

    const showMarker = (map: any, marker: any) => {
      if (marker.setMap()) return;
      marker.setMap(map);
    };

    const hideMarker = (map: any, marker: any) => {
      if (!marker.setMap()) return;
      marker.setMap(null);
    };

    const getClickHandler = (seq: any) => {
      return (e: any) => {
        const marker = markers[seq];
        const infoWindow = infoWindows[seq];

        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          infoWindow.open(map, marker);
        }
      };
    };

    for (let i = 0, ii = markers.length; i < ii; i++) {
      window.naver.maps.Event.addListener(markers[i], "click", getClickHandler(i));
    }

    window.naver.maps.Event.addListener(map, "idle", () => {
      const mapBounds = map.getBounds();
      let marker, position;
      for (let i = 0; i < markers.length; i++) {
        marker = markers[i];

        position = marker.getPosition();
        if (mapBounds.hasLatLng(position)) {
          showMarker(map, marker);
        } else {
          hideMarker(map, marker);
        }
      }
    });

    return () => map.destroy();
  }, [isLoad, parlor]);

  return (
    <>
      <Script
        src={`https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.NEXT_PUBLIC_NAVERMAP_KEY}`}
        onLoad={() => setIsLoad(true)}
      />
      <Box position="relative" height="320px" width="100%" id="mapLayout">
        <Stack
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height="320px"
          width="100%"
          ref={mapRef}
        >
          {!isLoad && <GraySpinner />}
        </Stack>
      </Box>
    </>
  );
}
