import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { ColorSystemProps } from "../../../constants";
import { numberToLocalString } from "../../../utils";
import { colors } from "../../../styles";
import { BTypeCTA } from "../CTA";

export function BTypeBinsoPriceSummary({
  defaultParlor,
  mainColor,
  event,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const binsos = defaultParlor.binsos;
  const binsoLength = binsos.length - 1;
  const firstBinso = binsos[0];
  const lastBinso = binsos[binsoLength];

  const parsePriceToTenThousand = (price: number) => {
    return numberToLocalString(Math.round(price / 10000));
  };

  if (binsos.length === 0) {
    return (
      <Box textAlign="center">
        해당 장례식장은 빈소 가격정보를 공개하지 않습니다.
      </Box>
    );
  }

  return (
    <Stack>
      <Box className="subtitle_18_sb" textAlign="center" component="h2">
        {defaultParlor.companyname} 비용
      </Box>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        className="subtitle_14_b"
        padding="20px 0 8px"
      >
        <Box component="h3">빈소 예상 비용</Box>{" "}
        <Box>
          {parsePriceToTenThousand(firstBinso.charge)} ~{" "}
          {parsePriceToTenThousand(lastBinso.charge)}
          만원
        </Box>
      </Stack>
      <Box className="body_14_m" component="p">
        {defaultParlor.companyname}의 빈소는{" "}
        {parsePriceToTenThousand(firstBinso.charge)} ~{" "}
        {parsePriceToTenThousand(lastBinso.charge)}으로 구성되어 있고, 가장 작은
        평수인 {firstBinso.pyeong}평 기준으로 3일장을 치른다면{" "}
        {(firstBinso.charge * 2).toLocaleString()}
        원이 빈소 비용이 발생할 것으로 예상합니다.
      </Box>

      <TableContainer
        sx={{
          padding: "20px 0 24px",
          ["td, tr, th"]: {
            border: `1px solid ${colors.gray500}`,
            textAlign: "center",
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="subtitle_14_b">빈소 면적</TableCell>
              <TableCell className="subtitle_14_b">비용 (기준: 1일)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {binsos.map((res) => {
              return (
                <TableRow>
                  <TableCell className="subtitle_14_sb">
                    {res.pyeong}평
                  </TableCell>
                  <TableCell className="subtitle_14_sb">
                    {numberToLocalString(res.charge)}원
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <BTypeCTA mainColor={mainColor} event={event} />
    </Stack>
  );
}
