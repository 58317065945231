import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import facilities from "../../../constants/facilities_detail.json";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";
import { DTypeCardView } from "../CardView";

interface ETypeFacilityListProps {
  title: string;
  sido?: string;
  sigungu?: string;
  defaultHref: string;
  event: ClickEventType;
}

export function ETypeFacilityList({
  title,
  sido,
  sigungu,
  defaultHref,
  event,
}: ETypeFacilityListProps) {
  const facilityCardItems = sigungu
    ? facilities.filter((p) => String(p.sigungu) === String(sigungu))
    : facilities.filter((p) => String(p.sido) === String(sido));

  const [prices, setPrices] = useState([] as { min: number; max: number }[]);

  useEffect(() => {
    const facilityCardPrices = facilityCardItems.map(({ hallrents }, index) => {
      const facilityCardPrice = hallrents.reduce(
        (acc, { charge }) => {
          const { min, max } = acc;
          const newAcc = { ...acc };

          if (charge) {
            if (charge < min) {
              newAcc.min = charge;
            }

            if (charge > max) {
              newAcc.max = charge;
            }
            return newAcc;
          }

          return newAcc;
        },
        {
          min: 0,
          max: 0,
        } as Record<"min" | "max", number>
      );

      return facilityCardPrice;
    });

    setPrices(facilityCardPrices);
  }, []);

  const onClickEvent = () => {
    if (event) {
      customEvent(event);
    }
  };

  return (
    <>
      <Box component="h4" mb="4px" className="subtitle_18_sb">
        {title}
      </Box>
      <Box marginTop="6px" />
      <Stack component="ul" gap="16px" onClick={onClickEvent}>
        {prices.length > 0 &&
          facilityCardItems?.map((res, index) => {
            const price = prices[index];
            const { max, min } = price;
            const averagePrice = (max + min) / 2;

            return (
              <DTypeCardView
                key={res.companyname}
                name={res.companyname}
                href={`${defaultHref}/${res.id}/`}
                descriptions={[`평균 ${averagePrice.toLocaleString()}원`]}
                imgSrc={res.images?.[0].url || ""}
                address={res.address}
                event={event}
              />
            );
          })}
      </Stack>
    </>
  );
}
