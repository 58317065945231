import React from "react";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthRadiusButton } from "../../common/Buttons/FullWidthRadiusButton";
import { ColorIcon } from "../../common/Icons";
import { ClickEventType, ParlorDetailProps } from "../../../types";

interface CTypeCTAProps {
  mainColor: ColorSystemProps;
  // sidoName?: string;
  // sigunguName?: string;
  defaultParlor?: ParlorDetailProps;
  event?: ClickEventType;
}

export function CTypeCTA({
  mainColor,
  // sidoName,
  // sigunguName,
  defaultParlor,
  event,
}: CTypeCTAProps) {
  const companyname = defaultParlor?.companyname || "장례식장";
  const detailHref = defaultParlor?.id
    ? `/match/parlor-requests/${defaultParlor.id}/`
    : "/match/request/";

  return (
    <Stack gap="16px" padding="0 20px 20px">
      <Stack gap="10px">
        <p style={{ textAlign: "center" }} className="subtitle_14_sb">
          {/* 장례식장 비용이 다가 아닙니다. */}
          {companyname}의
          <br />
          상세 비용을 안내해드립니다.
          {/* <br /> */}
          {/* 전체 장례 비용을 알아보세요. */}
        </p>
        <FullWidthRadiusButton
          backgroundColor={mainColor.c400}
          // text="장례 견적 알아보기"
          text="장례식장 비용 알아보기"
          // href={GOI_WEB_URL + "/match/request/"}
          href={GOI_WEB_URL + detailHref}
          event={event}
        />
      </Stack>

      <Stack flexDirection="row" gap="6px" alignItems="center">
        <ColorIcon name="icon-check-fill-mono" size={14} color="" />
        <Box className="caption_12_m">이 지역 포함해서 찾기</Box>
      </Stack>
    </Stack>
  );
}
