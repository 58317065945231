import React from "react";
import { ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import { colors } from "../../../styles";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { SERVICE_DETAILS } from "./standard/details";
import parlors_discount from "../../../constants/parlors_discount_detail.json";

const services = ["무빈소장" as const, "가족장" as const, "일반장" as const, "VIP장" as const];
const items = ["가격" as const, "접객도우미" as const, "장례 용품" as const, "장의 차량" as const];
const suffix: Partial<Record<(typeof items)[number], string>> = {
  가격: "만 원",
  "장의 차량": "대",
};

export function DTypeSangjoService({
  defaultParlor,
  mainColor,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  const service = SERVICE_DETAILS;
  const discountIdList = parlors_discount.map((res) => res.parlor_id);
  const isDiscountParlor = discountIdList.includes(Number(defaultParlor.id));
  return (
    <>
      <Stack
        style={{
          background: mainColor.c100,
          padding: "20px 20px 40px",
        }}
      >
        <Stack flexDirection="row" gap="8px" flexWrap="wrap">
          <Box className="subtitle_18_b">{defaultParlor.companyname}</Box>
          {isDiscountParlor && (
            <Box
              padding="5px 10px"
              className="caption_11_m"
              borderRadius="5px"
              style={{ background: colors.green50, color: colors.green500 }}
            >
              제휴 할인 가능 장례식장
            </Box>
          )}
        </Stack>
        <Box margin="12px 0" className="body_14_m">
          상조를 이용하면 더 저렴합니다.
          <br />
          이것은 영업이 아닙니다. 합리적인 선택입니다.
        </Box>

        <Stack
          flexDirection="row"
          padding="20px 20px 60px"
          justifyContent="center"
          alignItems="flex-end"
          gap="30px"
        >
          <Stack alignItems="center">
            <Box marginBottom="20px" className="subtitle_16_b">
              상조 이용 시
            </Box>
            <Stack
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                background: mainColor.c100,
                border: `1px solid ${mainColor.c400}`,
              }}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className="body_14_m"
            >
              인력 용품 차량
              <br />
              합리적으로
            </Stack>
            <Box className="subtitle_16_b">+</Box>
            <Stack
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                background: mainColor.c100,
                border: `1px solid ${mainColor.c400}`,
              }}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className="body_14_m"
            >
              장례식장 이용료
              <br />
              제휴 할인
            </Stack>
          </Stack>

          <Box height="320px" width="1px" style={{ background: colors.gray300 }} />
          <Stack alignItems="center">
            <Box marginBottom="20px" className="subtitle_16_b">
              장례식장 이용 시
            </Box>
            <Stack
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                background: colors.gray900,
                border: `1px solid ${colors.gray900}`,
              }}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className="body_14_m gray300"
            >
              인력 용품 차량
              <br />
              비싸게
            </Stack>
            <Box className="subtitle_16_b">+</Box>
            <Stack
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                background: colors.gray900,
                border: `1px solid ${colors.gray900}`,
              }}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              className="body_14_m gray300"
            >
              장례식장 이용료
              <br />
              가격 그대로
            </Stack>
          </Stack>
        </Stack>

        <FullWidthButton
          text="전화상담 1666-9784"
          backgroundColor={mainColor.c400}
          href="tel:1666-9784"
        />
      </Stack>

      <Stack
        style={{
          background: "white",
          padding: "40px 0px 20px",
          overflow: "hidden",
        }}
      >
        <Stack
          flexDirection="row"
          gap={10}
          overflow="scroll"
          maxWidth="630px"
          alignItems="center"
          style={{
            justifyContent: "center",
          }}
        >
          <Stack flexDirection="row" maxWidth="480px" width="100%" padding={"0 24px"} gap="10px">
            {services.map((name) => (
              <Stack gap="16px" key={name} alignItems="center">
                <div
                  className="subtitle2_sb"
                  id={name}
                  style={{
                    color: colors.gray900,
                  }}
                >
                  {name}
                </div>
                <Stack
                  padding="24px 13px"
                  borderRadius="20px"
                  gap="30px"
                  flexGrow={1}
                  width="100px"
                  style={{ background: colors.gray50 }}
                >
                  {items.map((a) => (
                    <Stack key={a} alignItems="center">
                      <div
                        className="body2"
                        style={{
                          color: colors.gray600,
                        }}
                      >
                        {a}
                      </div>
                      <div
                        className="button"
                        style={{
                          color: colors.gray800,
                        }}
                      >
                        {service[name][a]}
                        {suffix[a]}
                      </div>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack padding="22px 20px 40px">
          <FullWidthButton
            text="서비스 상세 내역 보기"
            backgroundColor={mainColor.c400}
            href={GOI_WEB_URL + "/service/"}
          />
        </Stack>
      </Stack>
    </>
  );
}
