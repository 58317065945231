import { Box, Breadcrumbs, Link, Typography } from "@mui/material";

import React from "react";
import { ColorIcon } from "../../common/Icons";
import { colors } from "../../../styles";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { ClickEventType } from "../../../types";
import { customEvent } from "../../../utils";

interface BreadCrumbProps {
  href: string;
  text: string;
  event?: ClickEventType;
}

export function DTypeBreadCrumb({
  firstItem,
  secondItem,
  thirdItem,
  mainColor,
  goiHomeEvent,
}: {
  firstItem: BreadCrumbProps;
  secondItem?: BreadCrumbProps;
  thirdItem?: BreadCrumbProps;
  mainColor: ColorSystemProps;
  goiHomeEvent?: ClickEventType;
}) {
  const onClickEvent = (event: ClickEventType) => {
    customEvent(event);
  };

  const onClickGoiHomeEvent = () => {
    if (goiHomeEvent) {
      customEvent(goiHomeEvent);
    }
  };

  return (
    <Breadcrumbs separator={""} style={{ margin: "12px 10px" }}>
      <Box
        borderRadius="50px"
        style={{ background: "white" }}
        boxShadow={"0px 0px 12px 0px rgba(0, 0, 0, 0.12);"}
        padding="5px 28px"
        position="relative"
        zIndex={11}
      >
        <Link
          underline="hover"
          color="inherit"
          href={GOI_WEB_URL}
          onClick={onClickGoiHomeEvent}
        >
          <ColorIcon name="home-fill" size={20} color={colors.gray600} />
        </Link>
      </Box>
      {firstItem && (
        <Box
          borderRadius="50px"
          style={{
            background: secondItem ? "white" : mainColor.c300,
            color: secondItem ? colors.gray600 : "white",
          }}
          boxShadow={"0px 0px 12px 0px rgba(0, 0, 0, 0.12);"}
          padding="5px 28px"
          marginLeft="-40px"
          position="relative"
          zIndex={10}
        >
          <Link
            underline="hover"
            color="inherit"
            href={firstItem.href}
            onClick={() => onClickEvent(firstItem?.event as ClickEventType)}
          >
            <Typography marginLeft="10px" className="body_14_m">
              {firstItem.text}
            </Typography>
          </Link>
        </Box>
      )}
      {secondItem && (
        <Box
          borderRadius="50px"
          style={{
            background: thirdItem ? "white" : mainColor.c300,
            color: thirdItem ? colors.gray600 : "white",
          }}
          boxShadow={"0px 0px 12px 0px rgba(0, 0, 0, 0.12);"}
          padding="5px 28px"
          marginLeft="-40px"
          position="relative"
          zIndex={9}
        >
          <Link
            underline="hover"
            color="inherit"
            href={secondItem.href}
            onClick={() => onClickEvent(secondItem?.event as ClickEventType)}
          >
            <Typography marginLeft="10px" className="body_14_m">
              {secondItem.text}
            </Typography>
          </Link>
        </Box>
      )}
      {thirdItem && (
        <Box
          borderRadius="50px"
          style={{ background: mainColor.c300 }}
          boxShadow={"0px 0px 12px 0px rgba(0, 0, 0, 0.12);"}
          padding="5px 28px"
          marginLeft="-40px"
          position="relative"
          zIndex={8}
        >
          <Link
            underline="hover"
            color="inherit"
            href={thirdItem.href}
            onClick={() => onClickEvent(thirdItem?.event as ClickEventType)}
          >
            <Typography marginLeft="10px" className="body_14_m white">
              {thirdItem.text}
            </Typography>
          </Link>
        </Box>
      )}
    </Breadcrumbs>
  );
}
