import React from "react";
import { ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { Image } from "../../common/Image";
import { getCdnImageSrc } from "../../../utils";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";

export function CTypeFlowerSummary({
  defaultParlor,
  mainColor,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  return (
    <Stack alignItems="center" gap="16px">
      <Box className="subtitle_18_b" component="h3">
        근조화환 보내기
      </Box>
      <Stack component="p" textAlign="center" className="subtitle_14_sb">
        주문 후 2시간 이내에 배송이 이루어지며, <br />
        배송 완료 후에는 요청하신 연락처로 <br />
        확인 사진을 전송해 드립니다.
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack className="body_14_m" alignItems="center" gap="8px">
          <Box style={{ background: "white" }}>
            <Image
              src={getCdnImageSrc("platform/parlor/flower2.png")}
              width="110px"
              alt=""
            />
          </Box>
          <Box className="subtitle_14_b">8만원</Box>
        </Stack>
        <Stack className="body_14_m" alignItems="center" gap="8px">
          <Box style={{ background: "white" }}>
            <Image
              src={getCdnImageSrc("platform/parlor/flower.png")}
              width="110px"
              alt=""
            />
          </Box>
          <Box className="subtitle_14_b">10만원</Box>
        </Stack>
        <Stack className="body_14_m" alignItems="center" gap="8px">
          <Box style={{ background: "white" }}>
            <Image
              src={getCdnImageSrc("platform/parlor/flower5.png")}
              width="110px"
              alt=""
            />
          </Box>
          <Box className="subtitle_14_b">12만원</Box>
        </Stack>
      </Stack>

      <Stack padding="0 20px" width="100%">
        <FullWidthButton
          text="근조화환 주문하러 가기"
          href={`${GOI_WEB_URL}/parlor/${defaultParlor.id}/?showFlowerModal=true`}
          backgroundColor={mainColor.c400}
        />
      </Stack>
    </Stack>
  );
}
