import React from "react";
import { ParlorDetailProps, SidoName } from "../../../types";
import { Box, Stack } from "@mui/material";
import { Image } from "../../common/Image";
import { ColorIcon } from "../../common/Icons";

export function ATypeMainHead({
  title,
  sidoName,
  sigunguName,
  managed_type,
  binsosLength,
  defaultParlor,
  address,
}: {
  title: string;
  sidoName?: string;
  sigunguName?: string;
  binsosLength?: number;
  managed_type?: string;
  defaultParlor: ParlorDetailProps;
  address?: string;
}) {
  const parlorAddressWords = defaultParlor.address.split(" ");
  return (
    <Stack padding="20px">
      <h1 className="subtitle_21_b" style={{ whiteSpace: "pre-line" }}>
        {title}
      </h1>
      <Stack flexDirection="row" padding="4px 0 12px">
        <ColorIcon name="rating_star" color="#FAA87F" size={15} />
        <ColorIcon name="rating_star" color="#FAA87F" size={15} />
        <ColorIcon name="rating_star" color="#FAA87F" size={15} />
        <ColorIcon name="rating_star" color="#FAA87F" size={15} />
        <ColorIcon name="rating_star" color="#FAA87F" size={15} />
      </Stack>
      <p className="subtitle_14_sb">
        {managed_type &&
          `${parlorAddressWords[0]} ${parlorAddressWords[1]}에 위치한 ${defaultParlor.managed_type} 장례식장 `}
        {/* {binsosLength &&
          `${sidoName}${
            sigunguName ? " " + sigunguName : ""
          }에 ${binsosLength}개의 장례식장이 있습니다`} */}
        {/* {address && address} */}
      </p>
      {defaultParlor.images[0].url && (
        <Stack width="100%" padding="4px 0 0">
          {defaultParlor.images.length < 3 ? (
            <Stack overflow="hidden" height="180px">
              <Image
                src={defaultParlor.images[0].url}
                style={{ marginTop: "-45px" }}
              />
            </Stack>
          ) : (
            <Stack flexDirection="row" gap="2px">
              <Stack width="75%" overflow="hidden">
                <Image
                  src={defaultParlor.images[0].url}
                  style={{ scale: "110%" }}
                />
              </Stack>
              <Stack width="25%" overflow="hidden" gap="2px">
                <Box position="relative" height="50%" overflow="hidden">
                  <Image
                    style={{
                      position: "absolute",
                      top: "-20%",
                      left: "-20%",
                      scale: "110%",
                    }}
                    src={defaultParlor.images[1].url}
                  />
                </Box>
                <Box position="relative" height="50%" overflow="hidden">
                  <Image
                    style={{
                      position: "absolute",
                      top: "-20%",
                      left: "-20%",
                      scale: "110%",
                    }}
                    src={defaultParlor.images[2].url}
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}
