import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import { colors } from "../../../styles";

import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import parlors_discount from "../../../constants/parlors_discount_detail.json";

export function BTypeSangjoService({
  defaultParlor,
  mainColor,
  event,
}: {
  defaultParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const discountIdList = parlors_discount.map((res) => res.parlor_id);
  const isDiscountParlor = discountIdList.includes(Number(defaultParlor.id));
  return (
    <>
      <Stack padding="20px">
        <Stack flexDirection="row" gap="8px" flexWrap="wrap">
          <Box className="subtitle_18_b">{defaultParlor.companyname}</Box>
          {isDiscountParlor && (
            <Box
              padding="5px 10px"
              className="caption_11_m"
              borderRadius="5px"
              style={{ background: colors.green50, color: colors.green500 }}
            >
              제휴 할인 가능 장례식장
            </Box>
          )}
        </Stack>
        <Box margin="12px 0" className="body_14_m">
          상조를 이용하면 더 저렴합니다.
          <br />
          이것은 영업이 아닙니다. 합리적인 선택입니다.
        </Box>
      </Stack>
      <Stack
        flexDirection="row"
        padding="20px 20px 60px"
        justifyContent="center"
        alignItems="flex-end"
        gap="30px"
      >
        <Stack alignItems="center">
          <Box marginBottom="20px" className="subtitle_16_b">
            상조 이용 시
          </Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: mainColor.c100,
              border: `1px solid ${mainColor.c400}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m"
          >
            인력 용품 차량
            <br />
            합리적으로
          </Stack>
          <Box className="subtitle_16_b">+</Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: mainColor.c100,
              border: `1px solid ${mainColor.c400}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m"
          >
            장례식장 이용료
            <br />
            제휴 할인
          </Stack>
        </Stack>

        <Box
          height="320px"
          width="1px"
          style={{ background: colors.gray300 }}
        />
        <Stack alignItems="center">
          <Box marginBottom="20px" className="subtitle_16_b">
            장례식장 이용 시
          </Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: colors.gray900,
              border: `1px solid ${colors.gray900}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m gray300"
          >
            인력 용품 차량
            <br />
            비싸게
          </Stack>
          <Box className="subtitle_16_b">+</Box>
          <Stack
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: colors.gray900,
              border: `1px solid ${colors.gray900}`,
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="body_14_m gray300"
          >
            장례식장 이용료
            <br />
            가격 그대로
          </Stack>
        </Stack>
      </Stack>
      <Stack padding="20px">
        <FullWidthButton
          text="전화상담 1666-9784"
          backgroundColor={mainColor.c400}
          href="tel:1666-9784"
          event={event}
        />
      </Stack>
      <Stack>
        <Box pt="30px" textAlign="center" className="subtitle_18_b gray900">
          {defaultParlor.companyname} 예상 비용
          <br />
          <br />
          <Box textAlign="center" padding="0 16px">
            <span className="subtitle_18_b" style={{ color: mainColor.c400 }}>
              {" "}
              {defaultParlor.expected_charge_sum["무빈소"].toLocaleString()}원 ~{" "}
              {defaultParlor.expected_charge_sum["일반장"].toLocaleString()}원
            </span>
            <br />
            <span className="body_14_m gray800">
              위 비용은 장례용품(인력, 관, 수의, 차량)을 제외한,
              <br /> 조문객 수에 따른 <br /> {defaultParlor.companyname}{" "}
              장례식장 예상 비용입니다. <br />
              전체 장례 비용(장지, 장례 용품 포함), 상세 장례비용을 더
              알아보세요
            </span>
            <br />
            <br />
          </Box>
        </Box>
      </Stack>
      <Stack padding="22px 20px 40px">
        <FullWidthButton
          text="장례 견적 받아보기"
          backgroundColor={mainColor.c400}
          href={GOI_WEB_URL + "/match/request/"}
          event={
            {
              ...event,
              trigger_and_target: "uv_mth_req",
            } as ClickEventType
          }
        />
      </Stack>
    </>
  );
}
