import React from "react";
import { ParlorDetailProps } from "../../../../types";
import { ColorSystemProps } from "../../../../constants";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { colors } from "../../../../styles";

export function BTypeFacility({
  firstParlor,
  secondParlor,
  mainColor,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  const {
    has_mealroom: firstMealRoom,
    has_waitroom: firstWaitRoom,
    has_super: firstSuper,
    parkcnt: firstParking,
    has_facilities_for_impaired: firstFacilities,
  } = firstParlor.amenities;
  const {
    has_mealroom: secondMealRoom,
    has_waitroom: secondWaitRoom,
    has_super: secondSuper,
    parkcnt: secondParking,
    has_facilities_for_impaired: secondFacilities,
  } = secondParlor.amenities;

  const parseBooleanToWord = (bol: boolean) => {
    return bol ? "있음" : "없음";
  };

  return (
    <Stack className="body_16_m" padding="20px">
      <h3 className="subtitle_16_b gray900">시설 비교</h3>
      <TableContainer
        sx={{
          padding: "8px 0 0",
          ["td, tr, th"]: { textAlign: "center", borderBottom: `1px solid ${colors.gray600}` },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="subtitle_14_sb">구분</TableCell>
              <TableCell className="subtitle_14_sb">{firstParlor.companyname}</TableCell>
              <TableCell className="subtitle_14_sb">{secondParlor.companyname}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="subtitle_14_sb">식당</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstMealRoom))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondMealRoom))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="subtitle_14_sb">유족대기실</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstWaitRoom))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondWaitRoom))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="subtitle_14_sb">매점</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstSuper))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondSuper))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="subtitle_14_sb">장애인편의시설</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstFacilities))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondFacilities))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="subtitle_14_sb">주차공간</TableCell>
              <TableCell className="subtitle_14_sb">{firstParking}대</TableCell>
              <TableCell className="subtitle_14_sb">{secondParking}대</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
