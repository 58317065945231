import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../../types";
import { ColorSystemProps, GOI_WEB_URL } from "../../../../constants";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { colors } from "../../../../styles";
import { FullWidthButton } from "../../../common/Buttons/FullWidthButton";

export function CTypeFacility({
  firstParlor,
  secondParlor,
  mainColor,
  event,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  const {
    has_mealroom: firstMealRoom,
    has_waitroom: firstWaitRoom,
    has_super: firstSuper,
    parkcnt: firstParking,
    has_facilities_for_impaired: firstFacilities,
  } = firstParlor.amenities;
  const {
    has_mealroom: secondMealRoom,
    has_waitroom: secondWaitRoom,
    has_super: secondSuper,
    parkcnt: secondParking,
    has_facilities_for_impaired: secondFacilities,
  } = secondParlor.amenities;

  const parseBooleanToWord = (bol: boolean) => {
    return bol ? "있음" : "없음";
  };

  return (
    <Stack className="body_16_m" padding="20px">
      <h3 className="subtitle_16_b gray900">시설 비교</h3>
      <TableContainer
        sx={{
          padding: "20px 0 40px",
          ["th"]: { background: mainColor.c300, color: "white" },
          ["td, tr, th"]: { textAlign: "center", border: "none" },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderRadius: "10px 0 0 0" }}
                className="subtitle_14_b"
              >
                구분
              </TableCell>
              <TableCell className="subtitle_14_b">
                {firstParlor.companyname}
              </TableCell>
              <TableCell
                style={{ borderRadius: "0 10px 0 0" }}
                className="subtitle_14_b"
              >
                {secondParlor.companyname}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="subtitle_14_sb">식당</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstMealRoom))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondMealRoom))}
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                background: colors.gray50,
              }}
            >
              <TableCell className="subtitle_14_sb">유족대기실</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstWaitRoom))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondWaitRoom))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="subtitle_14_sb">매점</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstSuper))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondSuper))}
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                background: colors.gray50,
              }}
            >
              <TableCell className="subtitle_14_sb">장애인편의시설</TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(firstFacilities))}
              </TableCell>
              <TableCell className="subtitle_14_sb">
                {parseBooleanToWord(Boolean(secondFacilities))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="subtitle_14_sb">주차공간</TableCell>
              <TableCell className="subtitle_14_sb">{firstParking}대</TableCell>
              <TableCell className="subtitle_14_sb">
                {secondParking}대
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack width="100%">
        <FullWidthButton
          text={"전체 비용 알아보기"}
          href={GOI_WEB_URL + "/match/request/"}
          backgroundColor={mainColor.c300}
          event={
            { ...event, trigger_and_target: "uv_mth_req" } as ClickEventType
          }
        />
      </Stack>
    </Stack>
  );
}
