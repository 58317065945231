import React from "react";

import { ColorSystemProps, GOI_WEB_URL } from "../../../../constants";
import { Box, Stack } from "@mui/material";
import { FullWidthButton } from "../../../common/Buttons/FullWidthButton";
import { ClickEventType } from "../../../../types";

export function B_2TypeCTA({
  mainColor,
  event,
}: {
  mainColor: ColorSystemProps;
  event?: ClickEventType;
}) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      padding="40px 20px 46px"
      style={{ background: mainColor.c100 }}
      gap="24px"
    >
      <Box textAlign="center" className="subtitle_16_b">
        장례식장 총 예상 비용
        <br />
        <b className="subtitle_18_b">1,013,600원 ~ 10,883,600원</b>
      </Box>
      <Box textAlign="center" className="subtitle_14_sb">
        위 비용은 장례용품(인력, 관, 수의, 차량)을 제외한, <br />
        조문객 수에 따른 장례식장 예상 비용입니다. <br />
        전체 장례 비용(장지, 장례 용품 포함), 상세 장례비용을 더 알아보세요
      </Box>
      <Stack width="100%" padding="0 34px" gap="12px">
        <FullWidthButton
          text={"전체 비용 알아보기"}
          href={GOI_WEB_URL + "/match/request/"}
          backgroundColor={mainColor.c300}
          event={
            {
              ...event,
              trigger_and_target: "uv_mth_req",
            } as ClickEventType
          }
        />
      </Stack>
    </Stack>
  );
}
