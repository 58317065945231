import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ParlorDetailProps } from "../../../../types";
import { ColorSystemProps } from "../../../../constants";
import { colors } from "../../../../styles";
import { numberToLocalString } from "../../../../utils";

export function BTypePriceSummary({
  firstParlor,
  secondParlor,
  mainColor,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  return (
    <Stack padding="40px 20px 20px" gap="10px">
      <h3 className="subtitle_18_b">빈소 대여료</h3>
      <Stack>
        <Stack className="body_16_m">
          <h3 className="subtitle_18_sb gray900">{firstParlor.companyname} 비용</h3>
          <TableContainer
            sx={{
              padding: "20px 0 40px",
              ["th"]: { background: mainColor.c300, color: "white" },
              ["td, tr, th"]: { textAlign: "center", border: "none" },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderRadius: "10px 0 0 0" }} className="subtitle_14_sb">
                    평수
                  </TableCell>
                  <TableCell style={{ borderRadius: "0 10px 0 0" }} className="subtitle_14_sb">
                    비용
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firstParlor.binsos.map((res, i) => {
                  return (
                    <TableRow
                      style={{
                        background: i % 2 === 1 ? colors.gray50 : "",
                      }}
                      key={i + firstParlor.companyname + "row"}
                    >
                      <TableCell className="subtitle_14_sb">{res.pyeong}평</TableCell>
                      <TableCell className="subtitle_14_sb">
                        약 {numberToLocalString(res.charge)}원
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack className="body_16_m">
          <h3 className="subtitle_18_sb gray900">{secondParlor.companyname} 비용</h3>
          <TableContainer
            sx={{
              padding: "20px 0 40px",
              ["th"]: { background: mainColor.c300, color: "white" },
              ["td, tr, th"]: { textAlign: "center", border: "none" },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderRadius: "10px 0 0 0" }} className="subtitle_14_sb">
                    평수
                  </TableCell>
                  <TableCell style={{ borderRadius: "0 10px 0 0" }} className="subtitle_14_sb">
                    비용
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {secondParlor.binsos.map((res, i) => {
                  return (
                    <TableRow
                      key={i + secondParlor.companyname + "row"}
                      style={{
                        background: i % 2 === 1 ? colors.gray50 : "",
                      }}
                    >
                      <TableCell className="subtitle_14_sb">{res.pyeong}평</TableCell>
                      <TableCell className="subtitle_14_sb">
                        약 {numberToLocalString(res.charge)}원
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack className="body_16_m" padding="12px 0 40px">
          <Box className="subtitle_18_sb gray900">빈소 대여료 안내</Box>
          <p>
            장례식장의 빈소 대여 비용은 그 유형에 따라 다르게 책정되며, 해당 비용에 따라 시설의 품질
            또한 변동됩니다. 대학병원, 전문, 공설 장례식장의 순서로 요금이 상승하는 편이며,{" "}
            {firstParlor.companyname}은 {firstParlor.managed_type} 장례식장으로 분류되고,{" "}
            {secondParlor.companyname}은 {secondParlor.managed_type} 장례식장으로 분류됩니다.
          </p>
        </Stack>
      </Stack>
    </Stack>
  );
}
