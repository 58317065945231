import React from "react";
import { ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";
import { ColorSystemProps, FUNERAL_SIDO } from "../../../constants";
import { B_1TypeCTA, B_2TypeCTA } from "./CTA";
import { BTypePriceSummary } from "./PriceSummary";
import { BTypeFacility } from "./Facility";

import { Image } from "../../common/Image";

export function BTypeCompareParlor({
  firstParlor,
  secondParlor,
  mainColor,
}: {
  firstParlor: ParlorDetailProps;
  secondParlor: ParlorDetailProps;
  mainColor: ColorSystemProps;
}) {
  const firstParlorType = firstParlor.managed_type;
  const secondParlorType = secondParlor.managed_type;

  return (
    <>
      <Stack
        padding="20px"
        height="220px"
        alignItems="center"
        justifyContent="center"
        style={{ background: "#7d7d7d" }}
        position="relative"
        className="white"
        overflow="hidden"
      >
        <Stack position="relative" zIndex={3} alignItems="center" width="100%">
          <h1
            className="subtitle_21_sb"
            style={{ padding: "4px 0 16px", textAlign: "center", width: "100%" }}
          >
            {firstParlor.companyname}
            <Box
              width="100%"
              padding="0 20px"
              margin="6px 0"
              height="1px"
              style={{ background: "white" }}
            />
            {secondParlor.companyname}
          </h1>
          <Box className="body_14_m">장례식장 비용 시설 비교</Box>
          <p className="caption_12_m">2023.09.20</p>
        </Stack>
        <Stack
          position="absolute"
          zIndex={2}
          width="100%"
          height="100%"
          style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
        ></Stack>
        <Stack flexDirection="row" position="absolute" zIndex={1}>
          <Stack width="50%">
            {firstParlor.images[0].url && (
              <Image
                src={firstParlor.images[0].url}
                style={{ objectPosition: "center", objectFit: "cover" }}
              />
            )}
          </Stack>
          <Stack width="50%">
            {secondParlor.images[0].url && (
              <Image
                src={secondParlor.images[0].url}
                style={{ objectPosition: "center", objectFit: "cover" }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack gap="20px" padding="20px" className="body_16_m gray900">
        <Box>
          장례식장들의 여러 대안을 준비해두는 것은 중요합니다. 본 글에서는
          <b style={{ color: mainColor.c400 }}>
            {" "}
            {firstParlor.companyname}과 {secondParlor.companyname}을 비교
          </b>
          해보겠습니다.
        </Box>
        <Box>
          각각 {FUNERAL_SIDO.get(Number(firstParlor.sido))}에 위치한 장례식장으로,
          {firstParlor.companyname}은 {firstParlor.address}, {secondParlor.companyname}은{" "}
          {secondParlor.address}에 위치해있습니다.
        </Box>
        <Box>
          장례식장 유형은
          <b style={{ color: mainColor.c400 }}>
            {firstParlor.companyname} : {firstParlorType} 장례식장, {secondParlor.companyname} :{" "}
            {secondParlorType} 장례식장
          </b>
          으로 나뉘며, 더 자세한 정보를 아래에서 설명해보겠습니다.
        </Box>
      </Stack>

      <B_1TypeCTA firstParlor={firstParlor} secondParlor={secondParlor} mainColor={mainColor} />

      <BTypeFacility firstParlor={firstParlor} secondParlor={secondParlor} mainColor={mainColor} />

      <BTypePriceSummary
        firstParlor={firstParlor}
        secondParlor={secondParlor}
        mainColor={mainColor}
      />

      <B_2TypeCTA mainColor={mainColor} />
    </>
  );
}
