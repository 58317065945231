import { CSSPixelValue } from "../../types/CSSPixelValue";
import { coerceCssPixelValue } from "../../utils/coerceCssPixelValue";
import { CSSProperties } from "react";

interface BaseProps {
  gap?: CSSPixelValue;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  flex?: CSSProperties["flex"];
  flexGrow?: CSSProperties["flexGrow"];
  position?: CSSProperties["position"];
  top?: CSSPixelValue;
  border?: CSSProperties["border"];
  borderRadius?: CSSPixelValue;
  borderBottom?: CSSProperties["borderBottom"];
  margin?: CSSPixelValue;
  padding?: CSSPixelValue;
  width?: CSSPixelValue;
  height?: CSSPixelValue;
  maxWidth?: CSSPixelValue;
  maxHeight?: CSSPixelValue;
  background?: string;
  backgroundSize?: CSSProperties["backgroundSize"];
  backgroundPosition?: CSSProperties["backgroundPosition"];
  backgroundRepeat?: CSSProperties["backgroundRepeat"];
  backgroundColor?: CSSProperties["backgroundColor"];
  zIndex?: number;
  overflow?: CSSProperties["overflow"];
  boxShadow?: CSSProperties["boxShadow"];
  // aspectRatio?: CSSProperties["aspectRatio"];
}

type Props<Element extends keyof JSX.IntrinsicElements = "div"> = BaseProps & {
  as?: Element;
} & Omit<JSX.IntrinsicElements[Element], keyof BaseProps | "as">;

function HStack<Element extends keyof JSX.IntrinsicElements = "div">(
  props: Props<Element>
) {
  const {
    as: component = "div",
    gap = 0,
    justifyContent,
    alignItems,
    flex,
    flexGrow,
    position,
    top,
    border,
    borderRadius,
    borderBottom,
    margin,
    padding,
    width,
    height,
    maxWidth,
    maxHeight,
    backgroundPosition,
    backgroundRepeat,
    backgroundColor,
    background,
    backgroundSize,
    zIndex,
    overflow,
    boxShadow,

    children,
    style,
    ...rest
  } = props;

  const Component = component as any;

  return (
    <Component
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent,
        alignItems,
        flex,
        flexGrow,
        gap: coerceCssPixelValue(gap),
        position,
        top: coerceCssPixelValue(top),
        border,
        borderRadius: coerceCssPixelValue(borderRadius),
        borderBottom,
        margin: coerceCssPixelValue(margin),
        height: coerceCssPixelValue(height),
        maxWidth: coerceCssPixelValue(maxWidth),
        maxHeight: coerceCssPixelValue(maxHeight),
        padding: coerceCssPixelValue(padding),
        width: coerceCssPixelValue(width),
        backgroundRepeat,
        backgroundColor,
        background,
        backgroundSize,
        backgroundPosition,
        zIndex,
        overflow,
        boxShadow,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Component>
  );
}

export { HStack };
