import React from "react";
import { Stack } from "@mui/material";
import { ColorSystemProps } from "../../../constants";
export function ATypeNationalMeritHead({
  title = "국가유공자 장례정보포털",
  titleSido,
  mainColor,
  isAlignCenter = false,
}: {
  title?: string;
  titleSido: string;
  mainColor: ColorSystemProps;
  isAlignCenter?: boolean;
}) {
  return (
    <Stack padding="0 20px 20px" alignItems={isAlignCenter ? "center" : "flex-start"}>
      <Stack gap="20px">
        <h1
          className="subtitle_21_sb"
          style={{ whiteSpace: "pre-wrap", textAlign: isAlignCenter ? "center" : "left" }}
        >
          <span className="subtitle_18_b" style={{ color: mainColor.c300 }}>
            {titleSido}
          </span>
          <br />
          {title}
        </h1>
      </Stack>
    </Stack>
  );
}
