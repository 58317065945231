import { Box, Stack } from "@mui/material";
import React from "react";
import { MapLayout } from "./MapLayout";
import { DefaultFacility, ParlorDetailProps } from "../../../types";

export function CTypeMap({
  defaultParlor,
  address,
}: {
  defaultParlor: ParlorDetailProps | DefaultFacility;
  address?: string;
}) {
  return (
    <Stack gap="8px">
      <Box component="h3" className="subtitle_18_sb">
        {defaultParlor.companyname} 위치
      </Box>
      {address && <Box className="body_14_m">{address}</Box>}
      <MapLayout parlor={defaultParlor} />
    </Stack>
  );
}
