import { Box, Stack } from "@mui/material";
import React from "react";
import { MapLayout } from "./MapLayout";
import { ParlorDetailProps } from "../../../types";

export function ATypeMap({
  defaultParlor,
  latitude,
  longitude,
  companyname,
}: {
  defaultParlor?: ParlorDetailProps;
  latitude?: Number;
  longitude?: Number;
  companyname?: String;
}) {
  return (
    <Stack gap="8px">
      <Box className="subtitle_16_b">위치</Box>
      <MapLayout
        parlor={defaultParlor}
        latitude={latitude}
        longitude={longitude}
        companyname={companyname}
      />
    </Stack>
  );
}
