import React, { useMemo } from "react";
import { Box, Stack } from "@mui/material";

import { DefaultFacility } from "../../../types";
import { ColorSystemProps } from "../../../constants";

interface DTypeFacilityMainHeadProps {
  title: string;
  sidoName?: string;
  sigunguName?: string;
  defaultFacility: DefaultFacility;
  mainColor: ColorSystemProps;
  status: "hwajang" | "jangji";
}

export function DTypeFacilityMainHead({
  title,
  sidoName,
  defaultFacility,
  status,
}: DTypeFacilityMainHeadProps) {
  const facilityName = useMemo(() => {
    if (status === "jangji") return "장지";
    if (status === "hwajang") return "화장장";

    return "";
  }, [status]);

  return (
    <Stack position="relative" padding="20px">
      <Stack className="subtitle_21_b" flexDirection="row" gap="3px">
        <Box>[{sidoName}]</Box>
        <h1 className="subtitle_21_b ">{title}</h1>
      </Stack>

      <Stack flexDirection="row" className="gray600 caption_12_sb" gap="3px">
        <Box>{defaultFacility.address.split(" ")[0]}</Box>
        <Box>·</Box>
        {<Box>{facilityName}</Box>}
      </Stack>
    </Stack>
  );
}
