import { Box, Stack } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React from "react";
import { ColorSystemProps, GOI_WEB_URL } from "../../../constants";
import { FullWidthButton } from "../../common/Buttons/FullWidthButton";
import { ClickEventType, EventDomainType, EventPathType } from "../../../types";
import { useOverlay } from "@toss/use-overlay";
import BinsoReserveModal from "../../Modal/BinsoReserveModal";

dayjs.extend(utc);
dayjs.extend(timezone);

export function ATypeBinsoRealTimeSummary({
  companyname,
  mainColor,
  event,
}: {
  companyname: string;
  mainColor: ColorSystemProps;
  event: { path: EventPathType; domain: keyof typeof EventDomainType };
}) {
  dayjs.locale("ko");
  const now = dayjs().tz("Asia/Seoul");

  const overlay = useOverlay();

  return (
    <Stack>
      <Box className="subtitle_16_b">
        {now.year()}년 {now.month() + 1}월 {now.date()}일 {now.hour()}:
        {String(Math.floor(now.minute() / 10) * 10).padStart(2, "0")}
      </Box>
      <Box height="2px" style={{ background: "black" }} />

      <Stack
        className="subtitle_14_b"
        flexDirection="row"
        justifyContent="space-between"
        padding="12px 40px"
      >
        <Box>호실</Box>
        <Box>고인명</Box>
        <Box>발인 시각</Box>
      </Stack>
      <Box height="1px" style={{ background: "black" }} />
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{ background: mainColor.c100 }}
        padding="67px 0"
        width="100%"
        gap="16px"
      >
        <Box>실시간 빈소 현황을 가져 오지 못했습니다.</Box>
        <Stack width="184px" gap="8px">
          <FullWidthButton
            text="빈소 현황 문의하기"
            href="tel:1666-9784"
            event={{ ...event, trigger_and_target: "goi_call" } as ClickEventType}
          />
          <FullWidthButton
            text="빈소 예약 도움받기"
            onClick={() => {
              overlay.open(({ isOpen, close: onClose }) => {
                return (
                  <BinsoReserveModal
                    event={event}
                    parlorName={companyname}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                );
              });
            }}
            event={{ ...event, trigger_and_target: "opn_reserve_mdl" } as ClickEventType}
          />
        </Stack>
      </Stack>
      <Box height="2px" style={{ background: "black" }} />
    </Stack>
  );
}
