export function parseFuneralTypeToDetailPrice(
  type: string,
  price: { least: number | string; most: number | string },
  facilityType: string,
  isPublicFacility: boolean,
  companyname: string
) {
  let least;
  let most;
  if (type === "장례식장") {
    least = price.least;
    most = price.most;
  }
  if (type === "장지") {
    if (facilityType === "Cemetery") {
      if (isPublicFacility) {
        least = 1_000_000;
        most = 4_000_000;
      } else {
        least = 5_000_000;
        most = 10_000_000;
      }
    }
    if (facilityType === "NaturalBurial") {
      if (companyname.includes("수목")) {
        if (isPublicFacility) {
          least = 800_000;
          most = 3_000_000;
        } else {
          least = 3_000_000;
          most = 30_000_000;
        }
      } else {
        if (isPublicFacility) {
          least = 200_000;
          most = 1_500_000;
        } else {
          least = 1_000_000;
          most = 2_000_000;
        }
      }
    }
    if (facilityType === "Charnel") {
      if (isPublicFacility) {
        least = 200_000;
        most = 1_600_000;
      } else {
        least = 2_000_000;
        most = 6_000_000;
      }
    }
  }
  if (type === "화장장") {
    if (typeof price.least === "string" && price.least.includes("만원")) {
      least = Number(price.least.replace("만원", "")) * 10000;
    }

    if (typeof price.most === "string" && price.most.includes("만원")) {
      most = Number(price.most.replace("만원", "")) * 10000;
    }
    if (price.least === "-") {
      least = 100_000;
    }
    if (price.most === "-") {
      most = 1_000_000;
    }
  }

  if (!least) return "";
  if (!most) return "";

  return `${least.toLocaleString()}원 ~ ${most.toLocaleString()}원`;
}
