import { FUNERAL_SIDO } from "./sidoList";

const FUNERAL_SIDO_KEYS = Array.from(FUNERAL_SIDO.keys());
const FUNERAL_SIDO_VALUES = Array.from(FUNERAL_SIDO.values());

const FUNERAL_SIGUNGU: Map<number, Map<number, string>> = new Map([
  [
    FUNERAL_SIDO_KEYS[0],
    new Map([
      [11230, "강남구"],
      [11250, "강동구"],
      [11090, "강북구"],
      [11160, "강서구"],
      [11210, "관악구"],
      [11050, "광진구"],
      [11170, "구로구"],
      [11180, "금천구"],
      [11110, "노원구"],
      [11100, "도봉구"],
      [11060, "동대문구"],
      [11200, "동작구"],
      [11140, "마포구"],
      [11130, "서대문구"],
      [11220, "서초구"],
      [11040, "성동구"],
      [11080, "성북구"],
      [11240, "송파구"],
      [11150, "양천구"],
      [11190, "영등포구"],
      [11030, "용산구"],
      [11120, "은평구"],
      [11010, "종로구"],
      [11020, "중구"],
      [11070, "중랑구"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[1],
    new Map([
      [31370, "가평군"],
      [31100, "고양시"],
      [31110, "과천시"],
      [31060, "광명시"],
      [31250, "광주시"],
      [31120, "구리시"],
      [31160, "군포시"],
      [31230, "김포시"],
      [31130, "남양주시"],
      [31080, "동두천시"],
      [31050, "부천시"],
      [31020, "성남시"],
      [31010, "수원시"],
      [31150, "시흥시"],
      [31090, "안산시"],
      [31220, "안성시"],
      [31040, "안양시"],
      [31260, "양주시"],
      [31380, "양평군"],
      [31280, "여주시"],
      [31350, "연천군"],
      [31140, "오산시"],
      [31190, "용인시"],
      [31170, "의왕시"],
      [31030, "의정부시"],
      [31210, "이천시"],
      [31200, "파주시"],
      [31070, "평택시"],
      [31270, "포천시"],
      [31180, "하남시"],
      [31240, "화성시"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[2],
    new Map([
      [21120, "강서구"],
      [21110, "금정구"],
      [21310, "기장군"],
      [21070, "남구"],
      [21030, "동구"],
      [21060, "동래구"],
      [21050, "부산진구"],
      [21080, "북구"],
      [21150, "사상구"],
      [21100, "사하구"],
      [21020, "서구"],
      [21140, "수영구"],
      [21130, "연제구"],
      [21040, "영도구"],
      [21010, "중구"],
      [21090, "해운대구"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[3],
    new Map([
      [22040, "남구"],
      [22070, "달서구"],
      [22310, "달성군"],
      [22020, "동구"],
      [22050, "북구"],
      [22030, "서구"],
      [22060, "수성구"],
      [22010, "중구"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[4],
    new Map([
      [23310, "강화군"],
      [23070, "계양구"],
      [23050, "남동구"],
      [23020, "동구"],
      [23090, "미추홀구"],
      [23060, "부평구"],
      [23080, "서구"],
      [23040, "연수구"],
      [23320, "옹진군"],
      [23010, "중구"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[5],
    new Map([
      [24050, "광산구"],
      [24030, "남구"],
      [24010, "동구"],
      [24040, "북구"],
      [24020, "서구"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[6],
    new Map([
      [25050, "대덕구"],
      [25010, "동구"],
      [25030, "서구"],
      [25040, "유성구"],
      [25020, "중구"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[7],
    new Map([
      [26020, "남구"],
      [26030, "동구"],
      [26040, "북구"],
      [26310, "울주군"],
      [26010, "중구"],
    ]),
  ],
  [FUNERAL_SIDO_KEYS[8], new Map([[29010, "세종특별자치시"]])],
  [
    FUNERAL_SIDO_KEYS[9],
    new Map([
      [32030, "강릉시"],
      [32400, "고성군"],
      [32040, "동해시"],
      [32070, "삼척시"],
      [32060, "속초시"],
      [32380, "양구군"],
      [32410, "양양군"],
      [32330, "영월군"],
      [32020, "원주시"],
      [32390, "인제군"],
      [32350, "정선군"],
      [32360, "철원군"],
      [32010, "춘천시"],
      [32050, "태백시"],
      [32340, "평창군"],
      [32310, "홍천군"],
      [32370, "화천군"],
      [32320, "횡성군"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[10],
    new Map([
      [33360, "괴산군"],
      [33380, "단양군"],
      [33320, "보은군"],
      [33340, "영동군"],
      [33330, "옥천군"],
      [33370, "음성군"],
      [33030, "제천시"],
      [33390, "증평군"],
      [33350, "진천군"],
      [33040, "청주시"],
      [33020, "충주시"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[11],
    new Map([
      [34070, "계룡시"],
      [34020, "공주시"],
      [34310, "금산군"],
      [34060, "논산시"],
      [34080, "당진시"],
      [34030, "보령시"],
      [34330, "부여군"],
      [34050, "서산시"],
      [34340, "서천군"],
      [34040, "아산시"],
      [34370, "예산군"],
      [34010, "천안시"],
      [34350, "청양군"],
      [34380, "태안군"],
      [34360, "홍성군"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[12],
    new Map([
      [35370, "고창군"],
      [35020, "군산시"],
      [35060, "김제시"],
      [35050, "남원시"],
      [35330, "무주군"],
      [35380, "부안군"],
      [35360, "순창군"],
      [35310, "완주군"],
      [35030, "익산시"],
      [35350, "임실군"],
      [35340, "장수군"],
      [35010, "전주시"],
      [35040, "정읍시"],
      [35320, "진안군"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[13],
    new Map([
      [36390, "강진군"],
      [36350, "고흥군"],
      [36320, "곡성군"],
      [36060, "광양시"],
      [36330, "구례군"],
      [36040, "나주시"],
      [36310, "담양군"],
      [36010, "목포시"],
      [36420, "무안군"],
      [36360, "보성군"],
      [36030, "순천시"],
      [36480, "신안군"],
      [36020, "여수시"],
      [36440, "영광군"],
      [36410, "영암군"],
      [36460, "완도군"],
      [36450, "장성군"],
      [36380, "장흥군"],
      [36470, "진도군"],
      [36430, "함평군"],
      [36400, "해남군"],
      [36370, "화순군"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[14],
    new Map([
      [37100, "경산시"],
      [37020, "경주시"],
      [37370, "고령군"],
      [37050, "구미시"],
      [37310, "군위군"],
      [37030, "김천시"],
      [37090, "문경시"],
      [37410, "봉화군"],
      [37080, "상주시"],
      [37380, "성주군"],
      [37040, "안동시"],
      [37350, "영덕군"],
      [37340, "영양군"],
      [37060, "영주시"],
      [37070, "영천시"],
      [37400, "예천군"],
      [37430, "울릉군"],
      [37420, "울진군"],
      [37320, "의성군"],
      [37360, "청도군"],
      [37330, "청송군"],
      [37390, "칠곡군"],
      [37010, "포항시"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[15],
    new Map([
      [38090, "거제시"],
      [38390, "거창군"],
      [38340, "고성군"],
      [38070, "김해시"],
      [38350, "남해군"],
      [38080, "밀양시"],
      [38060, "사천시"],
      [38370, "산청군"],
      [38100, "양산시"],
      [38310, "의령군"],
      [38030, "진주시"],
      [38330, "창녕군"],
      [38110, "창원시"],
      [38050, "통영시"],
      [38360, "하동군"],
      [38320, "함안군"],
      [38380, "함양군"],
      [38400, "합천군"],
    ]),
  ],
  [
    FUNERAL_SIDO_KEYS[16],
    new Map([
      [39020, "서귀포시"],
      [39010, "제주시"],
    ]),
  ],
  [0, new Map([[0, ""]])],
]);

export { FUNERAL_SIDO_KEYS, FUNERAL_SIDO_VALUES, FUNERAL_SIGUNGU };
