import { Box, Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { ColorSystemProps } from "../../../constants";

interface DTypeFacilityListProps {
  title: string;
  mainColor: ColorSystemProps;
}

export function DTypeFacilityList({
  title,
  children,
  mainColor,
}: PropsWithChildren<DTypeFacilityListProps>) {
  return (
    <Stack
      padding="16px"
      style={{
        background: mainColor.c100,
        padding: "40px 20px 20px",
      }}
    >
      <Box component="h4" mb="4px" className="subtitle_18_sb">
        {title}
      </Box>
      <Box marginTop="6px" />
      {children}
    </Stack>
  );
}
