import React from "react";
import { ClickEventType, ParlorDetailProps } from "../../../types";
import { Box, Stack } from "@mui/material";

import { colors } from "../../../styles";
import { FullWidthTwoLineButton } from "../../common/Buttons/FullWidthTwoLineButton";
import { Image } from "../../common/Image";
import { getCdnImageSrc } from "../../../utils";
import { GOI_WEB_URL } from "../../../constants";

export function ATypeFlowerSummary({
  defaultParlor,
  event,
}: {
  defaultParlor: ParlorDetailProps;
  event?: ClickEventType;
}) {
  return (
    <Stack alignItems="center" gap="16px">
      <Box className="subtitle_16_b">{defaultParlor.companyname} 주소</Box>
      <Box className="body_14_r">{defaultParlor.address}</Box>

      <Stack padding="20px 0" alignItems="center" gap="10px">
        <Box className="subtitle_16_b">
          {defaultParlor.companyname} 근조화환 주문
        </Box>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack className="body_14_m" alignItems="center" gap="8px">
            <Box style={{ background: "white" }}>
              <Image
                src={getCdnImageSrc("platform/parlor/flower2.png")}
                width="110px"
                alt=""
              />
            </Box>
            <Box className="subtitle_18_b">8만원</Box>
          </Stack>
          <Stack className="body_14_m" alignItems="center" gap="8px">
            <Box style={{ background: "white" }}>
              <Image
                src={getCdnImageSrc("platform/parlor/flower.png")}
                width="110px"
                alt=""
              />
            </Box>
            <Box className="subtitle_18_b">10만원</Box>
          </Stack>
          <Stack className="body_14_m" alignItems="center" gap="8px">
            <Box style={{ background: "white" }}>
              <Image
                src={getCdnImageSrc("platform/parlor/flower5.png")}
                width="110px"
                alt=""
              />
            </Box>
            <Box className="subtitle_18_b">12만원</Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack padding="0 34px" width="100%">
        <FullWidthTwoLineButton
          description="주문 시 2시간 이내 배송"
          text="근조화환 고르고 주문하기"
          href={`${GOI_WEB_URL}/parlor/${defaultParlor.id}/?showFlowerModal=true`}
          backgroundColor={colors.gray900}
          event={event}
        />
      </Stack>
    </Stack>
  );
}
